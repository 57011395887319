import { Popover, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  ArrowLongDownIcon,
  ArrowLongUpIcon,
} from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";

const list = [
  {
    name: "Low to High (Price)",
    icon: <ArrowLongDownIcon className="w-5 h-5 text-neutral-700" />,
  },
  {
    name: "High to Low (Price)",
    icon: <ArrowLongUpIcon className="w-5 h-5 text-neutral-700" />,
  },
];

const SortingComponent = ({ filterCopy, sortFunction }) => {
  const [currentCommodity, setCurrentCommodity] = useState("");

  useEffect(() => {
    setCurrentCommodity(list[0].name);
  }, [filterCopy]);

  useEffect(() => {
    sortFunction(currentCommodity);
  }, [currentCommodity]);

  return (
    <div className=" w-full px-4">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md relative w-full sm:w-52  px-3 py-2 border-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="">{currentCommodity}</span>
              <ChevronDownIcon
                className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-orange-300 transition absolute right-2 duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-50 w-screen max-w-sm px-4 mt-3  transform -translate-x-1/2  left-[12rem] sm:px-0 lg:max-w-1xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-4 bg-white p-7 lg:grid-cols-1">
                    {list.map((item) => (
                      <div
                        key={item.name}
                        className="-m-3 flex items-center rounded-lg p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-100 mb-1 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        onClick={(e) => {
                          close();
                          setCurrentCommodity(item.name);
                        }}
                      >
                        <div className="flex h-5 w-5 shrink-0 items-center justify-center text-white sm:h-6 sm:w-6">
                          {item.icon}
                        </div>
                        <div className="ml-4">
                          <p className="text-sm font-medium text-gray-900">
                            {item.name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default SortingComponent;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Thu Mar 02 2023
 * Created at 12:11:20 PM
 * File Content:
 * *================*
 * *================*
 * Rate Card Skeleton Component
 * *================*
 * *================*
 */



import { StarIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";

export interface RateCardSkeletonProps {
    className?: string;

}

const RateCardSkeleton: FC<RateCardSkeletonProps> = ({
    className = "",
}) => {
    return (
        <div
            className={`nc-RateCardgroup p-4 sm:p-4 relative bg-white dark:bg-neutral-900 border border-neutral-100
dark:border-neutral-800 rounded-2xl  overflow-hidden hover:shadow-lg transition-shadow space-y-6 `}
            data-nc-id="RateCard"
        >
            <div role="status" className="animate-pulse">

                <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0 sm:space-x-10">
                    <div className="w-12 lg:w-32 flex-shrink-0">
                        <svg className="w-20 h-20 text-gray-200 dark:text-gray-700" 
                             aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" 
                             ><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0  " clip-rule="evenodd"></path></svg>

                    </div>
                    <div className="hidden lg:block flex-[3] min-w-[100px] whitespace-nowrap">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div className="hidden lg:block min-w-[100px] flex-[3] whitespace-nowrap">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div className="flex-col whitespace-nowrap sm:text-right  min-w-[150px] flex-[3]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div className="flex-col whitespace-nowrap sm:text-right  min-w-[150px] flex-[3]">
                        <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                    </div>
                    <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
                        <div className="h-8 bg-gray-300 rounded-full dark:bg-gray-700 w-18"></div>
                    </div>
                </div>

            </div>

        </div>

    );
};

export default RateCardSkeleton;

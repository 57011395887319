/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 6:00:48 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Mar 06 2023
 * Updated at 1:04:59 PM
 *
 */

import React, { useState, useEffect, FC } from "react";
import LocationInput from "./LocationInput";
import { default as PortLocationInput } from "../LocationInput";
import { FocusedInputShape } from "react-dates";
import ShippingDetails from "./ShippingDetails";
import { regularAPI, secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { useHistory } from "react-router-dom";
import websitePaths from "utils/paths/websitePaths";
import { IPorts } from "../LocationInput";
import { getRequest } from "utils/helpers/apiVerbs";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useRedirectContext } from "utils/contexts/redirectContext";
export interface Icity {
  id: string;
  city: string;
  state: string;
}

export interface TransportationSearchFormProps {
  haveDefaultValue?: boolean;
  autoFill: any;
}

export interface ITransportParams {
  transportation_type: string;
  loading_port: string;
  factory_city: string;
  size: string;
  cargo_weight: string;
}

const TransportationSearchForm: FC<TransportationSearchFormProps> = ({
  autoFill,
}) => {
  const [pickUpInputValue, setPickUpInputValue] = useState(
    autoFill.page === "home" ? "" : autoFill.feilds.loading_port
  );
  const [dropOffInputValue, setDropOffInputValue] = useState(
    autoFill.page === "home" ? "" : autoFill.feilds.factory_city
  );
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | null
  >(null);
  const [pickUpsearchList, setPickUpSearchList] = useState<IPorts[]>([]);
  const [dropOffSearchList, setDropOffSearchList] = useState<Icity[]>([]);
  const [transportType, setTransportType] = useState<"Export" | "Import">(
    autoFill.page === "home" ? "Export" : autoFill.feilds.transportation_type
  );
  const [contDetails, setContDetails] = useState({
    size: autoFill.page === "home" ? "20" : autoFill.feilds.size,
    cargo_weight: autoFill.page === "home" ? "1" : autoFill.feilds.cargo_weight,
  });

  const { checkKyc } = useUserDetails();
  const { userState } = useUserDetails();

  const { makeRedirect } = useRedirectContext();

  const [transportParams, setTransportParams] = useState<ITransportParams>({
    transportation_type: "",
    loading_port: "",
    factory_city: "",
    size: "20",
    cargo_weight: "1",
  });

  const [showSelectLoading, setShowSelectLoading] = useState(false);

  const history = useHistory();
  const submitHandler = () => {
    const {
      transportation_type,
      loading_port,
      factory_city,
      size,
      cargo_weight,
    } = transportParams;

    if (!checkKyc()) {
      // makeRedirect({
      //   isRedirect: true,
      //   redirectUrl: websitePaths.transportaiton.ratesListing,
      //   queryParams: "?transportation_type=" +
      //     transportation_type +
      //     "&loading_port=" +
      //     decodeURI(loading_port) +
      //     "&factory_city=" +
      //     factory_city +
      //     "&size=" +
      //     size +
      //     "&cargo_weight=" +
      //     cargo_weight,
      // })
      history.push({
        pathname: "/update",
        search:
          "?email=" +
          userState?.customer.email +
          "&name=" +
          userState?.customer.contact_person +
          "&event=updateKyc",
      });
    } else {
      history.push({
        pathname: websitePaths.transportaiton.ratesListing,
        search:
          "?transportation_type=" +
          transportation_type +
          "&loading_port=" +
          decodeURI(loading_port) +
          "&factory_city=" +
          factory_city +
          "&size=" +
          size +
          "&cargo_weight=" +
          cargo_weight,
      });
    }
  };

  const selectedType = (weightRange: string, contDetails: string) => {
    setTransportParams({
      ...transportParams,
      size: contDetails.substring(0, 2),
      cargo_weight: weightRange,
    });
  };

  const fetchPickUpList = async (InputValue: string) => {
    setShowSelectLoading(true);
    // Pick up port
    try {
      const autoCompleteResult = await getRequest(
        apiPaths.transport.pickUp.ajax_autocomplete,
        { q: InputValue }
      );
      setPickUpSearchList(autoCompleteResult.data);
      setShowSelectLoading(false);
    } catch (error: any) {
      console.log("ERROR", error);
    }
  };

  const fetchDropofflist = async (InputValue: string) => {
    setShowSelectLoading(true);
    try {
      const resp = await regularAPI.get(
        apiPaths.transport.dropOff.ajax_autocomplete,
        {
          params: {
            q: InputValue,
          },
        }
      );

      setShowSelectLoading(false);
      setDropOffSearchList(resp.data);
    } catch (error: any) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (pickUpInputValue?.length !== 0) {
      fetchPickUpList(pickUpInputValue);
    }
  }, [pickUpInputValue]);

  useEffect(() => {
    if (dropOffInputValue?.length !== 0) {
      fetchDropofflist(dropOffInputValue);
    }
  }, [dropOffInputValue]);

  useEffect(() => {
    setTransportParams({
      ...transportParams,
      transportation_type: transportType,
      loading_port: pickUpInputValue,
      factory_city: dropOffInputValue,
    });
  }, [transportType, pickUpInputValue, dropOffInputValue]);

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            transportType === "Export"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setTransportType("Export")}
        >
          Export
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            transportType === "Import"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setTransportType("Import")}
        >
          Import
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="w-full">
        <form className="w-full relative mt-2 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
          {renderRadioBtn()}
          <div className=" flex flex-col md:flex-row w-full rounded-full [ nc-divide-field ] ">
            <div className="relative flex flex-col md:flex-row flex-grow [ nc-divide-field ] ">
              <PortLocationInput
                defaultValue={pickUpInputValue}
                onChange={(e: any) => {
                  setPickUpInputValue(e);
                }}
                onInputDone={() => setFieldFocused("dropOffInput")}
                placeHolder="Load Port"
                desc="From"
                searchList={pickUpsearchList}
                showLoading={showSelectLoading}
              />
              <LocationInput
                defaultValue={dropOffInputValue}
                onChange={(e: any) => {
                  setDropOffInputValue(e);
                }}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="Factory Address"
                desc="To"
                autoFocus={fieldFocused === "dropOffInput"}
                searchList={dropOffSearchList}
                showLoading={showSelectLoading}
              />

              {/* shipping details - new component */}
              <ShippingDetails selectedType={selectedType} />
              {/* BUTTON SUBMIT OF FORM */}
            </div>
            <div className="px-4 py-3 flex items-center justify-center">
              {/* <ButtonSubmit  /> */}
              <button
                onClick={submitHandler}
                type="button"
                className="h-14  md:h-16 w-full md:w-16 rounded-full bg-[#cd512f] hover:bg-[#218778] flex items-center justify-center text-neutral-50 focus:outline-none"
              >
                <span className="mr-3 md:hidden">Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderForm();
};

export default TransportationSearchForm;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Mar 14 2023
 * Updated on Tue Mar 14 2023
 * File Content:
 * *================*
 * *================*
 * Custom Clearance Booking Page
 * *================*
 * *================*
 */

import StartRating from "components/StartRating/StartRating";
import React, { FC, useEffect, useState, ReactElement } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { useLocation, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faAnchorCircleCheck,
  faTruck,
} from "@fortawesome/free-solid-svg-icons";

export interface BookingsProps {
  bId: string;
  bookedData: {
    booking_no: string;
  };
  data: any;
}

const CustomClearanceBookingPage = () => {
  const [bookingsData, setBookingsData] = useState<
    RouteComponentProps | null | {} | string
  >("");

  const location = useLocation<BookingsProps>();
  const { state } = location;

  useEffect(() => {
    setBookingsData(state);

    console.log("STATE", state);
  }, []);

  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          We have received your booking. We will get back to you with the
          updates.
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold"> Booking Details</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            {/* <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src="https://images.pexels.com/photos/6373478/pexels-photo-6373478.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
              </div>
            </div> */}
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div className="flex flex-col space-y-4">
                <span className="text-sm text-neutral-500 font-medium dark:text-neutral-400 line-clamp-1" />
                <span className="text-base sm:text-lg font-medium  block">
                  Stuffing Type: {state.data.stuffing_type}
                </span>
                <span className="text-base sm:text-lg font-medium  block">
                  Transportation Type: {state.data.transport_type}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400"></span>
              <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              {/* <StartRating /> */}
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  Date of Booking
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {moment().format("MMM Do, YYYY")}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <FontAwesomeIcon
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                icon={faTruck}
              />
              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">
                  Container Detail
                </span>
                <span className="mt-1.5 text-lg font-semibold">
                  {state.data.container_type}
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <div className="flex flex-col space-y-4">
            <div className="flex h-4 text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Reference Number</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {state.bookedData.booking_no}{" "}
                <FontAwesomeIcon
                  className="h-full text-[#218778]"
                  icon={faTruck}
                />
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href="/custom-clearance">
            Explore more rates
          </ButtonPrimary>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default CustomClearanceBookingPage;

export const CONTAINER_TYPES = [
    {
      id: "1",
      name: "20 GP",
    },
    {
      id: "2",
      name: "40 GP ",
    },
    {
      id: "3",
      name: "20 FR",
    },
    {
      id: "4",
      name: "40 FR",
    },
    {
      id: "5",
      name: "20 OT",
    },
    {
      id: "6",
      name: "40 OT",
    },
  ];
  
  export const STUFFING_TYPE = [
    {
      id: "1",
      name: "Dock Stuffing",
    },
    {
      id: "2",
      name: "Factory Stuffing",
    },
    {
      id: "3",
      name: "On Wheel",
    },
    {
      id: "4",
      name: "New Machine",
    },
  ];
  
  export const COMMODITY_LIST = [
    {
      id: "1",
      name: "AGRO PRODUCTS",
    },
    {
      id: "2",
      name: "PHARMA",
    },
    {
      id: "3",
      name: "GARMENTS",
    },
    {
      id: "4",
      name: "MACHINERY",
    },
    {
      id: "5",
      name: "FOOTWEAR",
    },
    {
      id: "6",
      name: "OTHERS",
    },
  ];
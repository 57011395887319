/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Tue Feb 21 2023
 * Created at 1:13:36 PM
 * File Content:
 * *================*
 * *================*
 * API Headers File
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:56 PM
 *
 */

module.exports = {
  secureLocalBaseUrl: "http://172.16.100.65:8000/api/v1/web/secure",
  locaBaselUrl: `http://172.16.100.65:8000/api/v1/web`,
  baseUrl: "https://apis.transpost.co/api/v1/web",
  secureBaseUrl: "https://apis.transpost.co/api/v1/web/secure",
  developmentBaseUrl: "https://dazzlingapis.transpost.co/api/v1/web",
  developmentSecureBaseUrl: "https://dazzlingapis.transpost.co/api/v1/web/secure",
  
};

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface SearchesLeftCardProps {
    searchesLeft?: number;
}

const SearchesLeftCard: FC<SearchesLeftCardProps> = ({
    searchesLeft
}) => {
    return (<div className=" bg-[#fff] w-full h-full border p-3 rounded-lg drop-shadow-md hover:drop-shadow-xl cursor-pointer dark:border-neutral-500 dark:bg-neutral-700 " >
        <div>
            <p className="text-md">Searches Left</p>
            <p className="text-2xl font-bold">{searchesLeft} / 50</p>
            {searchesLeft! >= 50 && <p className="text-sm text-[#ff0000]">You have exceeded your daily search limit for today.</p>}

        </div>
    </div>);
}

export default SearchesLeftCard;
/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Feb 28 2023
 * Updated on Tue Feb 28 2023
 * File Content:
 * *================*
 * *================*
 * Transportation Rates List
 * *================*
 * *================*
 */
/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 5:59:54 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Mar 03 2023
 * Updated at 4:10:46 PM
 *
 */


import RateCardSkeleton from "components/SkeletonComponents/RateCardSkeleton";
import { FC, useState } from "react";
import TransportationRateCard from "./TransportationRateCard";
import { TransportationRateType } from "./transportationTypes";

export interface TransportationRateListProps {
  className?: string;
  rateList: TransportationRateType[];
  showLoading?: boolean;
  apiCalled?: boolean
}

const TransportationRateList: FC<TransportationRateListProps> = ({
  className = "",
  rateList,
  showLoading,
  apiCalled
}) => {
  console.log("RATE LIST", rateList);

  return (
    <div
      className={`nc-SectionGridFilterCard`}
      data-nc-id="SectionGridFilterCard"
    >
      <div
        className={`nc-SectionGridFilterCard ${className}`}
        data-nc-id="SectionGridFilterCard"
      >
        <div
          className={`lg:p-14 lg:mt-14 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl
        `}
        >
          {!showLoading && Array.isArray(rateList) &&
            rateList.length > 0 &&
            rateList.map((item, index) => {
              return <TransportationRateCard key={index} data={item} />;
            })}

          {showLoading && <RateCardSkeleton />}

          {apiCalled && Array.isArray(rateList) && rateList.length == 0 && !showLoading && (
            <h1
              style={{
                position: "absolute",
                top: "75%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: "0",
              }}
            >
              Rates Not Available
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export { TransportationRateList };

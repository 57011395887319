import React, { useEffect, useState } from "react";

import { Timeline, TimelineEvent } from "react-event-timeline";
import moment from "moment";
import axios from "axios";
import DOMPurify from "dompurify";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import bookingRcvd from "../../images/transpost images/booking-details/booking-recieved.png";

import { useLocation, RouteComponentProps, Link } from "react-router-dom";
import apiPaths from "utils/paths/apiPaths";
import { secureGetRequest, securePostRequest } from "utils/helpers/apiVerbs";
import { useEventContext } from "utils/contexts/eventContext";
import {
  BookingsTimelineProps,
  IBookingData,
  ITimelineData,
} from "./bookingTimelineTypes";
import BookingDetailsSkeleton from "components/SkeletonComponents/BookingDetailsSkeleton";
import backIcon from "../../images/icons/back-button.png";
import useWindowSize from "hooks/useWindowResize";
import { } from "@fortawesome/free-solid-svg-icons";

import ApprovalIcon from "../../images/status/approval.png";
import ApprovedIcon from "../../images/status/approved.png";
import RejectIcon from "../../images/status/rejected.png";
import DownloadIcon from "../../images/status/download.png";
import ConfirmationIcon from "../../images/status/confirmation.png";
import ContainerPickUpIcon from "../../images/status/container-picked.png";
import SOBIcon from "../../images/status/sob.png";
import SubmitIcon from "../../images/status/submit.png";
import UnderreviewIcon from "../../images/status/underreview.png";
import WaitingContainerPickUpIcon from "../../images/status/waiting-container-pickup.png";
import NcModal from "shared/NcModal/NcModal";
import UploadFiles from "./UploadFiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faCheck,
  faCross,
  faUpload,
  faEye,
  faRefresh,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import UserBookingDetails from "./UserBookingDetails";
import UserBookingPaymentDetails from "./UserBookingPaymentDetails";

const BookingDetails = () => {
  const [timelineData, setTimelineData] = useState<ITimelineData | null>(null);

  const [bookingDetails, setBookingDetails] = useState<IBookingData | null>(
    null
  );
  const [isSelected, setIsSelected] = useState(false);
  const [fileUpload, setFileUpload] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsOpenModal] = useState(false);
  const { width } = useWindowSize();

  const location = useLocation<BookingsTimelineProps>();
  const [userComments, setUserComments] = useState("");
  const { setError } = useEventContext();
  const { state } = location;

  const onCloseModal = () => {
    setIsOpenModal(false);
  };

  const getBookingsTimeline = async () => {
    try {
      const bookingTimeLine = await secureGetRequest(
        apiPaths.bookings.timeline,
        {
          bookingID: state.ID,
        }
      );

      setTimelineData(bookingTimeLine.data);
    } catch (err: any) {
      setError({
        ...err.response.data,
        show: true,
      });
    }
  };

  const getBookingDetails = async () => {
    try {
      const bookingDetails = await secureGetRequest(
        apiPaths.bookings.select + "/" + state.ID
      );

      setBookingDetails(bookingDetails.data);
    } catch (err: any) {
      setError({
        ...err.response.data,
        show: true,
      });
    }
  };

  const updateStatus = async (data: {} = {}) => {
    try {
      const updateBookingStage = await securePostRequest(
        apiPaths.user.downloadFile,
        { ...data }
      );
      getBookingsTimeline();
      // TODO: add event
    } catch (err: any) {
      setError({
        ...err.response.data,
        show: true,
      });
    }
  };

  const renderBookingDetails = () => {
    return (
      <>

        <UserBookingDetails 
          bookingDetails={bookingDetails!} 
          getBookingDetails={() => getBookingDetails()} 
          getBookingsTimeline={() => getBookingsTimeline()} 
        />


      </>
    );
  };

  const statusIcon = (status: any) => {
    switch (status) {
      case 1:
        return UnderreviewIcon;
      case 2:
        return WaitingContainerPickUpIcon;
      case 3:
        return ContainerPickUpIcon;
      case 4:
        return SubmitIcon;
      case 5:
        return ApprovalIcon;
      case 6:
        return ApprovedIcon;
      case 7:
        return ApprovedIcon;
      case 8:
        return ApprovedIcon;
      case 9:
        return SOBIcon;
      case 10:
        return RejectIcon;
      case 11:
        return ApprovedIcon;
      default:
        return ApprovedIcon;
    }
  };

  const renderTimelineEvent = (
    action: any,
    data: any,
    text: any = "",
    description: any = ""
  ) => {
    switch (action) {
      // sharing files
      case 3:
        return data.download ? (
          <div className="flex">
            <p
              className="flex mr-3"
              style={{
                color: "green",
              }}
            >
              <span>
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Downloaded
              </span>
            </p>
            <p
              className="flex "
              onClick={() => {
                let alink = document.createElement("a");
                alink.href = data.file_url;
                alink.target = "_blank";
                alink.download = "document.pdf";
                alink.click();
              }}
              style={{
                color: "grey",
              }}
            >
              <span className="underline cursor-pointer">
                <FontAwesomeIcon icon={faEye} className="mr-2" />
                View
              </span>
            </p>
          </div>
        ) : (
          <p
            className="flex cursor-pointer text-neutral-900"
            // style={{ display: "flex", cursor: "pointer", color: "grey" }}
            onClick={async () => {
              await updateStatus({
                download: 1,
                approve: -1,
                booking_stage_id: data.ID,
              });

              let alink = document.createElement("a");
              alink.href = data.file_url;
              alink.target = "_blank";
              alink.download = "document.pdf";
              alink.click();
              getBookingsTimeline();
            }}
          >
            <span style={{ textDecoration: "underline" }}>
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Download
            </span>
          </p>
        );
      // request file upload from user
      case 2:
        return data.file_url?.length > 0 ? (
          <div className="flex">
            <p
              className="flex  mr-3"
              style={{
                color: "green",
              }}
            >
              <span>
                <FontAwesomeIcon icon={faCheck} className="mr-2" />
                Uploaded
              </span>
            </p>
            <p
              className="flex "
              onClick={() => {
                let alink = document.createElement("a");
                alink.href = data.file_url;
                alink.target = "_blank";
                alink.download = "document.pdf";
                alink.click();
              }}
              style={{
                color: "grey",
              }}
            >
              <span className="underline cursor-pointer">
                <FontAwesomeIcon icon={faEye} className="mr-2" />
                View
              </span>
            </p>
          </div>
        ) : (
          <NcModal
            contentExtraClass="md:w-1/2 "
            modalTitle={data.status[0].template}
            isOpenProp={isModalOpen}
            renderTrigger={(openModal) => (
              <p
                className="flex cursor-pointer text-grey"
                style={{ display: "flex", cursor: "pointer", color: "grey" }}
              >
                <span
                  onClick={() => {
                    setIsOpenModal(true);
                    openModal();
                  }}
                  className="underline "
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  Upload
                </span>
              </p>
            )}
            renderContent={() => (
              <UploadFiles onCloseModal={onCloseModal} stageId={data.ID} />
            )}
          />
        );
      // approve or reject by user
      case 1:
        return (
          <div className="flex flex-col space-y-4">
            {data.approve === -1 && data.download !== 0 && (
              <textarea
                name="user-comments"
                id=""
                placeholder="Enter any comments(optional)"
                onChange={(e) => setUserComments(e.target.value)}
                className=" text-sm"
              />
            )}
            <div className="flex space-x-3">
              {data.download ? (
                <>
                  {data.approve === 1 && (
                    <p className="flex cursor-pointer text-green-600 space-x-2">
                      <span>
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                      <span>Approved</span>
                    </p>
                  )}

                  {data.approve === 0 && (
                    <p className="flex cursor-pointer text-red-600 space-x-2">
                      <span>
                        <FontAwesomeIcon icon={faX} />
                      </span>
                      <span>Rejected</span>
                    </p>
                  )}
                  {data.approve === -1 && (
                    <>
                      <p
                        className="flex cursor-pointer text-green-600"
                        onClick={async () => {
                          await updateStatus({
                            download: 1,
                            approve: 1,
                            booking_stage_id: data.ID,
                            description: userComments,
                          });
                        }}
                      >
                        <span className="space-x-2">
                          <FontAwesomeIcon icon={faCheck} />
                          <span>Approve</span>
                        </span>
                      </p>
                      <p
                        className="flex cursor-pointer text-red-600"
                        onClick={async () => {
                          await updateStatus({
                            download: 1,
                            approve: 0,
                            booking_stage_id: data.ID,
                            description: userComments,
                          });
                        }}
                      >
                        <span className="space-x-2">
                          <FontAwesomeIcon icon={faX} />
                          <span>Reject</span>
                        </span>
                      </p>
                    </>
                  )}

                  <p
                    className="flex cursor-pointer text-grey-600"
                    onClick={() => {
                      let alink = document.createElement("a");
                      alink.href = data.file_url;
                      alink.target = "_blank";
                      alink.download = "document.pdf";
                      alink.click();
                    }}
                  >
                    <span className="underline cursor-pointer  text-neutral-900 space-x-2">
                      <FontAwesomeIcon icon={faEye} />
                      <span>View</span>
                    </span>
                  </p>
                </>
              ) : (
                <p
                  className="flex cursor-pointer text-grey-600 "
                  onClick={async () => {
                    await updateStatus({
                      download: 1,
                      approve: -1,
                      booking_stage_id: data.ID,
                    });

                    let alink = document.createElement("a");
                    alink.href = data.file_url;
                    alink.target = "_blank";
                    alink.download = "document.pdf";
                    alink.click();
                    getBookingsTimeline();
                  }}
                >
                  <span className="space-x-2">
                    <FontAwesomeIcon icon={faDownload} />
                    <span>Download</span>
                  </span>
                </p>
              )}
            </div>

            {data.approve === 0 && <p>We will update you soon</p>}
          </div>
        );
    }
  };

  const renderTimeLine = () => {
    return (
      <>
        <div className="w-full  flex flex-col overflow-y-auto lg:h-[70vh] scrollbar-thin sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 px-0 sm:p-6 xl:p-8 ">
          {timelineData?.data?.map((item: any) => {
            return (
              <Timeline key={item.ID}>
                <TimelineEvent
                  // iconColor={"blue"}
                  className="font-semibold "
                  title={
                    <h1 className="text-2xl font-semibold">
                      {item.status[0].name}
                    </h1>
                  }
                  subtitle={item.status[0].template}
                  createdAt={moment(item.stage_time).format("LL")}
                  bubbleStyle={{
                    border: "0px",
                  }}
                  icon={
                    <img
                      style={{ width: "2rem" }}
                      src={statusIcon(item.status[0].ID)}
                    ></img>
                  }
                >
                  {renderTimelineEvent(item.action, item)}
                </TimelineEvent>
              </Timeline>
            );
          })}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (bookingDetails?.data && timelineData?.data) {
      setLoading(false);
    }
  }, [bookingDetails, timelineData]);

  useEffect(() => {
    getBookingsTimeline();
    getBookingDetails();
  }, []);

  return loading ? (
    <BookingDetailsSkeleton />
  ) : (
    <div className="flex flex-col xl:pl-5 xl:ml-8 mb-4 justify-center ">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {renderBookingDetails()}
        </div>
        <div className="block sticky top-0 lg:pt-0 sm:pt-8 flex-1">{renderTimeLine()}</div>
      </main>
    </div>
  );
};

export default BookingDetails;

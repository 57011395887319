import React, { useEffect, useState } from "react";
import maleAvatar from "../../images/maleAvatar.svg";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import Avatar from "shared/Avatar/Avatar";
import { secureUploadRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";

const ProfileCard = () => {
  const [show, setShow] = useState(false);
  const { userState, setUserState } = useUserDetails();
  const { setError } = useEventContext();

  const changeHandler = async (e: any) => {
    const formData = new FormData();
    formData.append("profile_picture", e.target.files[0]);
    formData.append("email", userState?.customer.email || "");
    try {
      const res = await secureUploadRequest(
        apiPaths.user.file.profile,
        formData
      );

      console.log("REsposne", res.data.user.profile_picture);

      userState &&
        setUserState({
          ...userState,
          customer: {
            ...userState.customer,
            profile_picture:
              res.data.user.profile_picture ||
              userState.customer.profile_picture,
          },
        });
    } catch (error: any) {
      setError({ ...error.response.data, show: true });
    }
  };

  return (
    <>
      <div className="bg-white flex flex-col lg:pb-[15rem] p-8 rounded-2xl border border-neutral-200">
        <div className="space-y-4">
          <div className="relative flex items-center justify-center ">
            <div
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              className="relative rounded-full overflow-hidden inset-0 "
            >
              <Avatar
                sizeClass="w-32 h-32 "
                imgUrl={userState?.customer.profile_picture || ""}
              />
              <div
                className={`absolute inset-0 bg-black bg-opacity-60 overflow-hidden transition-[max-height] duration-200 ease-in-out 
                    ${
                      show ? "max-h-full" : "max-h-0"
                    } flex flex-col items-center justify-center text-neutral-50 cursor-pointer`}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                    stroke="currentColor"
                    strokeWidth={1.5}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="mt-1  text-xs">Change Image</span>
              </div>
              <input
                type="file"
                className="absolute inset-0 opacity-0 cursor-pointer"
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className="space-y-3 text-center flex flex-col items-center">
            <h2 className="text-3xl font-semibold">
              {userState?.customer.name}
            </h2>
          </div>
          <div className="space-y-4">
            <div className="flex items-center space-x-4 justify-center">
              {/* <FontAwesomeIcon/> */}
              <span className="text-neutral-6000 dark:text-neutral-300">
                {userState?.customer.email}
              </span>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex items-center space-x-4 justify-center">
              {/* <FontAwesomeIcon/> */}
              <span className="text-neutral-6000 dark:text-neutral-300">
                {userState?.customer.phone}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileCard;

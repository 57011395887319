import CommonLayout from "containers/AccountPage/CommonLayout";
import { Helmet } from "react-helmet";
import React, { FC, useEffect, useRef, useState } from "react";
import Sidebar from "../components/Sidebar";
import useWindowSize from "hooks/useWindowResize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnchor } from "@fortawesome/free-solid-svg-icons";
import OceanRateSearchForm from "./components/OceanRateSearchForm";
import apiPaths from "utils/paths/apiPaths";
import { useTransition, animated } from "@react-spring/web";
import { OceanRateList } from "containers/OceanSearchPage/OceanRateList";
import { secureGetRequest } from "utils/helpers/apiVerbs";
import { useEventContext } from "utils/contexts/eventContext";
import { OceanRateSearchList } from "./components/OceanRateSearchList";

export type SearchTab =
  | "Cargo Tracker"
  | "Ocean"
  | "Transportation"
  | "Air"
  | "Rent Warehouse";

export interface newTab {
  name: SearchTab;
  icon: React.ReactNode;
}
export interface RateSearchProps {
  className?: string;
  currentTab?: SearchTab;
  autoFillForm?: {
    assign?: any;
    page?: string;
    feilds: {};
  };
}
const isObjectEmpty = (objectName: any) => {
  return objectName && Object.keys(objectName).length === 0;
};

const RateSearch2: FC<RateSearchProps> = ({
  className = "",
  currentTab = "Ocean",
  autoFillForm = {
    assign: "",
    page: "rate-search",
    feilds: {},
  },
}) => {
  ///////////////////////////////////////
  const [rates, setRates] = useState<any[]>([]);
  const [filteredRates, setFilteredRates] = useState<any[]>([]);
  const [tabList, setTabList] = useState<string[] | null>(null);
  const [ratesString, setRatesString] = useState("");
  const [cargo, setCargo] = useState<string | null>(null);
  const [commodidtyList, setCommodityList] = useState<string[]>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<string>("");
  const [filterCopy, setFilterCopy] = useState<any[]>([]);

  const [apiCalled, setApiCalled] = useState(false);

  const [showLoading, setShowLoading] = useState(false);
  const [rate, setRate] = useState<any[]>([]);
  const { setIsLoading, setError } = useEventContext();
  ///////////////////////////////////////
  const [formInfo, setFormInfo] = useState<any>({});
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);
  const { width } = useWindowSize();
  const myRef = useRef<null | HTMLDivElement>(null);
  const onclickHandler = () => {
    if (window.screen.width > 540) {
      myRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };
  const newTab: newTab[] = [
    {
      name: "Ocean",
      icon: <FontAwesomeIcon icon={faAnchor} />,
    },
    // {
    //   name: "Transportation",
    //   icon: <FontAwesomeIcon icon={faTruck} />,
    // },
    // {
    //   name: "Air",
    //   icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
    // }
  ];
  const renderTab = () => {
    return (
      <ul className=" sm:ml-6 flex space-x-3 text-xs sm:space-x-8 lg:space-x-11 overflow-x-auto w-fit px-4 py-2 rounded-full  hiddenScrollbar dark:bg-neutral-800">
        {newTab.map((tab) => {
          const active = tab.name;
          return (
            <li
              onClick={() => {
                setTabActive(tab.name);
              }}
              className={`flex-shrink-0 flex items-center  cursor-pointer lg:text-base font-medium  ${
                active
                  ? ""
                  : "text-neutral-400 hover:text-neutral-800 dark:hover:text-neutral-300"
              } `}
              key={tab.name}
            >
              {active && (
                <span className="block  bg-neutral-800 dark:bg-[#2AA996] mr-2" />
                // <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-[#2AA996] mr-2" />
              )}

              <span className=" ">{tab.icon}</span>
              <span className="px-1">{tab.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const getOceanRates = async () => {
    setShowLoading(true);
    setApiCalled(true);
    try {
      const oceanRates: any = await secureGetRequest(
        apiPaths.ocean.rates.ocean_rates,
        {
          to_port: formInfo.to_port,
          from_port: formInfo.from_port,
          Validity: formInfo.sl_date,
        }
      );
      setRates(oceanRates.data);
      //   setTabList(Object.keys(oceanRates.data));

      setShowLoading(false);
    } catch (error: any) {
      setError({ ...error.response.data, show: true });
      setRates([]);
      setIsLoading(false);
      setShowLoading(false);
      setTabList([]);
      setFilteredRates([]);
    }
  };
  // selecting Cargo type

  const transitions = useTransition(cargo, {
    from: { transform: "translateX(100%)" },
    enter: { transform: "translateX(-3%)" },
    leave: { transform: "translateX(-10%)" },
  });

  useEffect(() => {
    if (formInfo.to_port && formInfo.from_port) {
      getOceanRates();
    }
  }, [formInfo.to_port, formInfo.from_port, formInfo.sl_date]);

  useEffect(() => {
    if (!isObjectEmpty(rates)) {
      setFilteredRates(Object.values(rates)[0]);
    }
  }, [rates]);

  // useEffect(() => {
  //   const list = filterCopy.map((item) => item.cargo_type);
  //   const uniqueList = [...new Set(list)];
  //   setCommodityList(["ALL", ...uniqueList]);
  // }, [filterCopy]);

  const renderForm = () => {
    const isArchivePage = !!currentTab;
    switch (tabActive) {
      case "Ocean":
        return (
          <OceanRateSearchForm
            haveDefaultValue={isArchivePage}
            autoFill={autoFillForm}
            dataOfForm={receiveFormInfo}
          />
        );
      default:
        return null;
    }
  };
  const receiveFormInfo = (data: any) => {
    setFormInfo(data);
  };

  return (
    <CommonLayout>
      <Helmet>
        <title>Tranpost | Rate-Search</title>
      </Helmet>
      <div className="flex flex-col  lg:flex-row -mt-25 lg:items-center">
        <div className="mt-1 mb-24 lg:mb-20 flex flex-col lg:flex-row">
          {width > 996 && (
            <div className="block flex-grow mb-24 lg:mb-0">
              <div className="lg:sticky lg:top-24">
                <Sidebar />
              </div>
            </div>
          )}
          <div className="w-auto md:w-[80%] space-y-5 lg:pl-5 flex-shrink-0">
            <div
              ref={myRef}
              onClick={onclickHandler}
              className={
                "lg:shadow-lg lg:px-10 lg:pb-10 lg:backdrop-blur-[5px]  rounded-3xl  w-full "
              }
            >
              <div
                className={`nc-HeroSearchForm w-full 
                 max-w-6xl z-10
                 py lg:py-2`}
                data-nc-id="HeroSearchForm"
              >
                {renderTab()}
                {renderForm()}
              </div>
            </div>

            {transitions((styles) => (
              <OceanRateSearchList
                rateList={filteredRates!}
                showLoading={showLoading}
                apiCalled={apiCalled}
              />
            ))}
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};
export default RateSearch2;

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, useEffect, useState } from "react";
import { bookingsAnalyticsType } from "../BookingAnalytics";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


interface BookingsDoneCardProps {
    bookings?: bookingsAnalyticsType;
}

const BookingsDoneCard: FC<BookingsDoneCardProps> = ({
    bookings
}) => {
    const data = {
        labels: ['Ocean', 'Transportation', 'Custom Clearance'],
        datasets: [
            {
                label: '# of Bookings',
           
                data: [bookings?.total_ocean_bookings, bookings?.total_transportation_bookings, bookings?.total_custom_clearance_bookings],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',

                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',

                ],
                borderWidth: 1,
            },
      
        ],
        
    };
    const [numberOfBooking, setNumberOfBooking] = useState(0)
    useEffect(() => {

        let total = 0;
        bookings! && Object.values(bookings).forEach(value => {
            total += value
        })
        setNumberOfBooking(total)
    }, [bookings])
    return (<div className=" bg-[#fff] w-full h-full flex border p-3 rounded-lg drop-shadow-md hover:drop-shadow-xl cursor-pointer dark:border-neutral-500 dark:bg-neutral-700 " >
        <div>
            <p className="text-md">Total Bookings</p>
            <p className="text-2xl font-bold">{numberOfBooking} Bookings</p>

        </div>
       

    </div>);
}

export default BookingsDoneCard;
import React, { useState } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { securePostRequest, secureUploadRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";
import Loader from "new_component/Loader";

const UploadFiles = ({ stageId, onCloseModal }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [isSelected, setIsSelected] = useState(false);

  const { setError } = useEventContext();

  const submitHandler = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append("booking_stage_id", stageId);
    formData.append("description", description);
    if (isSelected) {
      formData.append("SI_Doc", selectedFile);
    }

    try {
      const res = await secureUploadRequest(apiPaths.user.uploadFile, formData);
      console.log(res);
      setIsLoading(false);
      onCloseModal();
    } catch (error: any) {
      console.log(error);
      setIsLoading(false);

      setError({ ...error.response.data, show: true });
      onCloseModal();
    }
  };

  return (
    <form onSubmit={submitHandler} className="flex flex-col space-y-4">
      <label className="block  ">
        <span className="text-neutral-800 dark:text-neutral-200">
          Comments (Optional).
        </span>
        <textarea
          placeholder="Comments"
          className="block mt-1 w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-[1rem] "
          onChange={(e: any) => setDescription(e.target.value)}
        />
      </label>
      <label className="block ">
        <span className="text-neutral-800 dark:text-neutral-200">
          Upload file *
        </span>
        <Input
          className="mt-2"
          type="file"
          onChange={(e: any) => {
            setIsSelected(true);
            setSelectedFile(e.target.files[0]);
          }}
        />
      </label>
      <ButtonPrimary className="md:w-1/2  md:ml-auto w-full">
        {isLoading ? <Loader className="h-6 w-6" /> : "Proceed"}
      </ButtonPrimary>
    </form>
  );
};

export default UploadFiles;

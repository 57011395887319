import React, { FC, useState, useRef, useEffect } from "react";
import CustomClearanceSearchForm from "./CustomClearanceSearchForm";

export type SearchTab =
  | "Stays"
  | "Cargo Tracker"
  | "Cars"
  | "Ocean"
  | "Land"
  | "Air"
  | "Warehousing & Disitribution";

export interface newTab {
  name: SearchTab;
  icon: React.ReactNode;
}

export interface HeroSearchFormProps {
  autoFillForm?: {
    assign?: any;
    page?: string;
    feilds: {};
  };
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  autoFillForm = {
    assign: "",
    page: "home",
    feilds: {},
  },
}) => {
  const myRef = useRef<null | HTMLDivElement>(null);

  const onclickHandler = () => {
    myRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <div
      onClick={onclickHandler}
      ref={myRef}
      className={`nc-HeroSearchForm w-full max-w-6xl mx-auto  item-center py lg:py-2 `}
      data-nc-id="HeroSearchForm"
    >
      <CustomClearanceSearchForm autoFill={autoFillForm} />
    </div>
  );
};

export default HeroSearchForm;

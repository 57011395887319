/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Wed Feb 15 2023
 * Created at 11:20:03 AM
 * File Content:
 * *================*
 * *================*
 * Ocean Search Page Rate Listing
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 6:01:10 PM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:05:19 AM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Mon Feb 27 2023
 * Updated at 4:32:43 PM
 *
 */

import { FC, useState, useEffect } from "react";
import { OceanRatesType, oceanRateType } from "./OceanRates";
import OceanRateCard from "./OceanRateCard";
import RateCardSkeleton from "components/SkeletonComponents/RateCardSkeleton";
import useWindowSize from "hooks/useWindowResize";
import { InlineShareButtons } from "sharethis-reactjs";

export interface OceanRateListProps {
  className?: string;
  rateList: oceanRateType[];
  showLoading?: boolean;
  queryParams?: [];
  apiCalled: boolean;
}

const OceanRateList: FC<OceanRateListProps> = ({
  className = "",
  rateList,
  showLoading,
  apiCalled,
}) => {
  const window = useWindowSize();

  return (
    <div
      className={`nc-SectionGridFilterCard`}
      data-nc-id="SectionGridFilterCard"
    >
      <div
        className={`nc-SectionGridFilterCard ${className}`}
        data-nc-id="SectionGridFilterCard"
      >
        <div
          className={`relative lg:p-14 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 transform translate-x-10 duration-200 gap-6 rounded-3xl ${!apiCalled?"hidden":""}
        `}
        >
          {Array.isArray(rateList) &&
            rateList.length > 0 &&
            rateList.map((item: any) => {
              return <OceanRateCard key={item.id} data={item} />;
            })}

          {showLoading && <RateCardSkeleton />}

          {apiCalled &&
            Array.isArray(rateList) &&
            rateList.length == 0 &&
            window.width > 1024 &&
            !showLoading && (
              <h1 className="">
                <p className="text-3xl font-semibold pb-7">We apologize,</p>
                <p>
                  We are currently not able to propose online offer for your
                  request. Our team is consistently proposing new solutions to
                  meet your needs.
                </p>
                <p className="pb-8">
                  Please modify your search or contact us for an alternative
                  solution.
                </p>

                <a
                  href="/contact"
                  className="text-white bg-[#cd512f] hover:bg-[#218778] rounded-2xl bg- px-5 py-3 cursor-pointer"
                >
                  Contact us
                </a>
              </h1>
            )}
        </div>
      </div>
    </div>
  );
};

export { OceanRateList };

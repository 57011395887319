import React, { FC, Fragment, useState, useEffect } from "react";

import { Tab } from "@headlessui/react";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import UserDetailsCard from "./UserDetailsCard";
import CompanyDetailsCard from "./CompanyDetailsCard";
import KycDetailsCard from "./KycDetailsCard";
import EditUser from "./edit/EditUser";
import EditCompany from "./edit/EditCompany";
import EditKyc from "./edit/EditKyc";
import { IProfileDetails } from "./profiledetails";

const defaultValue = {
  email: "",
  user_details: {
    name: "",
    email: "",
    phone: "",
  },
  company_details: {
    companyName: "",
    customer_type: "",
    enterprise_type: "",
  },
  kyc_details: {
    gst_number: "",
    pan_number: "",
    tan_number: "",
  },
};

const ProfileDetailsCard = () => {
  let [categories] = useState([
    "General Info",
    "Company Details",
    "KYC Details",
  ]);
  const [edit, setEdit] = useState({
    userDetails: false,
    companyDetails: false,
    kycDetails: false,
  });
  const { userState } = useUserDetails();
  const [customer, setCustomer] = useState(userState?.customer);

  const [profileDetails, setProfileDetails] =
    useState<IProfileDetails>(defaultValue);

  const updateUser = (userObj: any) => {
    setProfileDetails({
      ...profileDetails,
      user_details: {
        ...userObj,
        email: userState?.customer.email,
      },
    });
  };
  const updateCompany = (companyObj: any) => {
    setProfileDetails({
      ...profileDetails,
      company_details: companyObj,
    });
  };
  const updateKyc = (kycObj: any) => {
    setProfileDetails({
      ...profileDetails,
      kyc_details: kycObj,
    });
  };
  // Test for update
  useEffect(() => {
    console.log("profileDetails", profileDetails);
  }, [profileDetails]);
  useEffect(() => {
    if (userState) {
      const { email, name, companyName, phone, pan_number, gst_number } =
        userState.customer;
      setProfileDetails({
        email: email || "",
        user_details: {
          name: name || "",
          email: email || "",
          phone: phone || "",
        },
        company_details: {
          companyName: companyName || "",
          customer_type: "",
          enterprise_type: "",
        },
        kyc_details: {
          gst_number: gst_number || "",
          pan_number: pan_number || "",
          tan_number: "",
        },
      });
    }
    setCustomer(userState?.customer);
    console.log("STATE UPDATED");
    
  }, [userState]);

  const onEditChange = (item: string, payload: boolean) => {
    setEdit({
      ...edit,
      [item]: payload,
    });
  };

  return (
    <>
      <div className="bg-white w-full h-full flex flex-col mb-4 sticky rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800 space-y-6 sm:space-y-7 px-4 sm:p-6 xl:p-8">
        <Tab.Group>
          <Tab.List className="flex space-x-1 overflow-x-auto pb-4 sm:pb-0 border-b border-neutral-200 dark:border-neutral-700  dark:bg-transparent">
            {categories.map((item) => (
              <Tab key={item} as={Fragment}>
                {({ selected }) => (
                  <span
                    className={`flex-shrink-0 block  cursor-pointer !leading-none font-medium px-5 mx-3 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize  focus:outline-none ${
                      selected
                        ? " border-b-2 border-[#2aa996] "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 transform ease-in-out duration-300 hover:scale-x-110 hover:text-neutral-900  dark:hover:bg-neutral-800"
                    } `}
                  >
                    {item}
                  </span>
                )}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel className="">
              {edit.userDetails ? (
                <EditUser
                  updateUser={updateUser}
                  profileDetails={profileDetails}
                  onEditChange={onEditChange}
                />
              ) : (
                <UserDetailsCard
                  onEditChange={onEditChange}
                  customer={customer}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="">
              {edit.companyDetails ? (
                <EditCompany
                  updateCompany={updateCompany}
                  profileDetails={profileDetails}
                  onEditChange={onEditChange}
                />
              ) : (
                <CompanyDetailsCard
                  onEditChange={onEditChange}
                  customer={customer}
                />
              )}
            </Tab.Panel>
            <Tab.Panel className="">
              {edit.kycDetails ? (
                <EditKyc
                  updateKyc={updateKyc}
                  profileDetails={profileDetails}
                  onEditChange={onEditChange}
                />
              ) : (
                <KycDetailsCard
                  onEditChange={onEditChange}
                  customer={customer}
                />
              )}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default ProfileDetailsCard;

import React, { useState } from "react";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useHistory } from "react-router-dom";

const ImageCard = ({ item }: any) => {
  const [show, setShow] = useState(false);
  const [token, setToken] = useState("");

  const { userState } = useUserDetails();
const location = useHistory();
  const onClickHandler = () => {
    if (userState) {
      window
        .open(
          `https://www.storemart.ai/transpostcall?key=${userState.storemart_key?.encryptpass}&hash=${userState.storemart_token?.encryptpass}`,
          "_blank"
        )
        ?.focus();
   
    } else {
      location.push("./login")
    }
  };

  return (
    <div onClick={onClickHandler} className="grid h-full ">
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        className="relative  h-full self-center  rounded-lg cursor-pointer hover:scale-105  duration-300 ease-in-out"
      >
        <img
          className="object-cover w-full h-[200px] rounded-lg"
          src={item.image}
        />
        <div className="absolute px-3 w-full flex bottom-8">
          <div className="font-semibold text-2xl text-white">{item.city}</div>
          <div
            className={`flex bg-white ml-auto rounded-full w-9 h-9 ${
              show
                ? "   bg-[#218778] ease-in-out"
                : "   duration-300 ease-in-out"
            }`}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              className={`rounded-full w-6 h-6 m-auto
               ${
                 show
                   ? " rotate-0 duration-300   ease-in-out"
                   : "-rotate-45 text-[#218778]  duration-300 ease-in-out"
               } `}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;

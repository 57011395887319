import React, { useEffect, useState } from "react";
import CommonLayout from "containers/AccountPage/CommonLayout";
import Sidebar from "../components/Sidebar";
import useWindowSize from "hooks/useWindowResize";
import { securePostRequest } from "utils/helpers/apiVerbs";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useEventContext } from "utils/contexts/eventContext";
import apiPaths from "utils/paths/apiPaths";
import SearchesLeftCard from "./Cards/SearchesLeftCard";
import BookingsDoneCard from "./Cards/BookingsDoneCard";
import BookingsGraph from "./Cards/BookingsGraph";

export interface bookingsAnalyticsType {
    total_ocean_bookings?: number,
    total_transportation_bookings?: number,
    total_custom_clearance_bookings?: number
}

export interface analyticsType {
    booking_count?: bookingsAnalyticsType
    no_of_searches?: number
    status?: string
}

const BookingAnalytics = () => {

    const [analytics, setAnalytics] = useState<analyticsType>();

    const { width } = useWindowSize();
    const { userState } = useUserDetails();
    const { setError } = useEventContext();


    const getDashboardAnalytics = async () => {
        try {
            const analytics = await securePostRequest(apiPaths.dashboard.analytics, {
                customer_id: userState?.customer.ID
            })
            setAnalytics(analytics?.data);
        } catch (err: any) {
            setError({ ...err.response.data, show: true })
        }
    }

    useEffect(() => {
        getDashboardAnalytics();
    }, [])

    return (

        <div>

            
            <div className="w-full grid lg:grid-cols-3 relative sm:grid-cols-2 grid-cols-1 p-5 gap-6 bg-white dark:text-neutral-300  dark:bg-neutral-800 ">


                <SearchesLeftCard searchesLeft={analytics?.no_of_searches} />
                <BookingsDoneCard bookings={analytics?.booking_count} />
                <BookingsGraph bookings={analytics?.booking_count} />

            </div>

        </div>

    );
}

export default BookingAnalytics;
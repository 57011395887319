/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Wed Feb 15 2023
 * Updated on Wed Feb 15 2023
 * File Content:
 * *================*
 * *================*
 * Billing address form Component
 * *================*
 * *================*
 */

import React from "react";
import Input from "shared/Input/Input";
import { UseFormRegister } from "react-hook-form/dist/types";
import { ICargoInsuranceForm } from "./CargoInsuranceForm";

interface BillingAddressFormProps {
  register: UseFormRegister<ICargoInsuranceForm>;
  showBillingAddress: boolean;
}

const BillingAddressForm = ({
  showBillingAddress,
  register,
}: BillingAddressFormProps) => {
  return (
    <div
      className={`overflow-hidden transition-[max-height] duration-600 ease-in-out ${
        showBillingAddress ? "max-h-full" : "max-h-0"
      } grid grid-cols-1 col-span-3 gap-4 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 `}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Billing Address
        </span>
        <Input
          type="text"
          placeholder="Billing Address"
          className="mt-1"
          {...register("billing_address")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Billing City
        </span>
        <Input
          type="text"
          placeholder="Billing City"
          className="mt-1"
          {...register("billing_city")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Billing State
        </span>
        <Input
          type="text"
          placeholder="Billing State"
          className="mt-1"
          {...register("billing_state")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Billing Pincode
        </span>
        <Input
          type="text"
          placeholder="Billing Pincode"
          className="mt-1"
          {...register("billing_pincode")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          GST Detail
        </span>
        <Input
          type="text"
          placeholder="Enter GST number"
          className="mt-1"
          {...register("gstin")}
        />
      </label>
    </div>
  );
};

export default BillingAddressForm;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Tue Feb 21 2023
 * Created at 1:14:27 PM
 * File Content:
 * *================*
 * *================*
 * API request generator file
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Wed Feb 22 2023
 * Updated at 12:25:20 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:50 PM
 *
 */

import axios from "axios";
// import apiHeaders from "utils/paths/apiHeaders";
import apiPaths from "utils/paths/apiPaths";
import getApiHeader from "./apiEnv";

// Change it to production || development 
const apiHeaders = getApiHeader("development");

let activityArray = JSON.parse(
  window.sessionStorage.getItem("activity_ids") || "[]"
);
function setActivtyFunc(activity) {
  activityArray.push(activity);
  window.sessionStorage.setItem("activity_ids", JSON.stringify(activityArray));
}
const secureAPI = axios.create({
  baseURL: apiHeaders.secure,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const secureUploadFiles = axios.create({
  baseURL: apiHeaders.secure,
  responseType: "json",
  headers: {
    "content-type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  },
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

const regularAPI = axios.create({
  baseURL: apiHeaders.base,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

regularAPI.interceptors.request.use((config) => {
  if (!config.url.includes("/tracking")) {
    axios
      .post(apiHeaders.base + apiPaths.user.searchActivity, {
        params: config.params,
        type: config.method,
        url: config.baseURL + config.url,
        users_id: null,
        users_email: null,
      })
      .then((response) => {
        setActivtyFunc(response.data.useractivity_id);
      });
  } else {
    activityArray = [];
    window.sessionStorage.setItem("activity_ids", activityArray);
  }

  return config;
});

secureAPI.interceptors.request.use(
  function (config) {
    const authDetails = JSON.parse(window.localStorage.getItem("auth_details"));
    const userDetails = JSON.parse(window.localStorage.getItem("user_details"));

    const headers = {
      ...config.headers,
      key: authDetails.public_key,
      token: authDetails.token,
    };

    config.headers = headers;

    switch (config.method) {
      case "get":
        config.params = {
          ...config.params,
          customer_id: userDetails.customer.ID,
        };
        break;

      case "post":
        config.data = { ...config.data, customer_id: userDetails.customer.ID };
        break;

      default:
        break;
    }

    if (!config.url.includes("/tracking")) {
      axios
        .post(apiHeaders.base + apiPaths.user.searchActivity, {
          params: config.params,
          type: config.method,
          url: config.baseURL + config.url,
          users_id: userDetails ? userDetails.customer.ID : null,
          users_email: userDetails.customer ? userDetails.customer.email : null,
        })
        .then((response) => {
          setActivtyFunc(response.data.useractivity_id);

        });
    } else {
      activityArray = [];
      window.sessionStorage.setItem("activity_ids", activityArray);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
  { synchronous: true }
);

// Interceptor for upload files
secureUploadFiles.interceptors.request.use(
  function (config) {
    const authDetails = JSON.parse(window.localStorage.getItem("auth_details"));
    const userDetails = JSON.parse(window.localStorage.getItem("user_details"));

    const headers = {
      ...config.headers,
      key: authDetails.public_key,
      token: authDetails.token,
    };

    config.headers = headers;

    axios
      .post(apiHeaders.base + apiPaths.user.searchActivity, {
        params: config.params,
        type: config.method,
        url: config.developmentBaseUrl + config.url,
        users_id: userDetails ? userDetails.customer.ID : null,
        users_email: userDetails.customer ? userDetails.customer.email : null,
      })
      .then((response) => {
        setActivtyFunc(response.data.useractivity_id);

      });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
  { synchronous: true }
);
// .................
secureAPI.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const originalConfig = error.config;

    if (error.response) {
      if (
        error.response.status === 401 &&
        !originalConfig._retry &&
        error.response.data.message !== "Unauthorized Token"
      ) {
        originalConfig._retry = true;

        window.localStorage.setItem(
          "auth_details",
          JSON.stringify(error.response.data.new_auth_details)
        );

        originalConfig.headers.token =
          error.response.data.new_auth_details.token;
        originalConfig.headers.key =
          error.response.data.new_auth_details.public_key;

        const delayRetryRequest = new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, originalConfig.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => axios(originalConfig));
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "Unauthorized Token"
      ) {
        const custom_message = {
          ...error.response.data,
          message: "Please Login Again!",
        };
        error.response.data = custom_message;
        // window.location.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);

// upload files
secureUploadFiles.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    const originalConfig = error.config;

    if (error.response) {
      if (
        error.response.status === 401 &&
        !originalConfig._retry &&
        error.response.data.message !== "Unauthorized Token"
      ) {
        originalConfig._retry = true;

        window.localStorage.setItem(
          "auth_details",
          JSON.stringify(error.response.data.new_auth_details)
        );

        originalConfig.headers.token =
          error.response.data.new_auth_details.token;
        originalConfig.headers.key =
          error.response.data.new_auth_details.public_key;

        const delayRetryRequest = new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, originalConfig.retryDelay || 1000);
        });
        return delayRetryRequest.then(() => axios(originalConfig));
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "Unauthorized Token"
      ) {
        const custom_message = {
          ...error.response.data,
          message: "Please Login Again!",
        };
        error.response.data = custom_message;
        // window.location.replace("/login");
      }
    }
    return Promise.reject(error);
  }
);

export { secureAPI, regularAPI, secureUploadFiles };

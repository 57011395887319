import React, { FC, useEffect, useRef, useState } from "react";
import RateCard from "./RateCard";
import RateSkeleton from "./RateSkeleton";

export interface SectionGridFilterCardProps {
  className?: string;
  rateList: {};
  showLoading: boolean;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  rateList,
  showLoading,
}) => {
  const myRef = useRef<null | HTMLDivElement>(null);

  return (
    <div
      ref={myRef}
      className={`nc-SectionGridFilterCard max-w-6xl mx-auto ${Object.keys(rateList).length !== 0 || showLoading ? "" : "hidden" }`}
      data-nc-id="SectionGridFilterCard"
    >
      <div
        className={`lg:p-14 lg:mt-14 mt-14 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6 rounded-3xl
        `}
      >
        {Object.keys(rateList).length > 0 && <RateCard data={rateList} />}

        { showLoading && <RateSkeleton />}

        {Array.isArray(rateList) && rateList.length == 0 && !showLoading && (
          <h1
            style={{
              position: "absolute",
              top: "75%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              margin: "0",
            }}
          >
            Rates Not Available
          </h1>
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;

import React from 'react';

const DashboardCardSekelton = () => {
    return (<>
        <div role="status" className="animate-pulse">
            <div
                className=" bg-[#fff] w-full h-full border p-3 rounded-lg drop-shadow-md hover:drop-shadow-xl cursor-pointer dark:border-neutral-500 dark:bg-neutral-700 "
            >
                <div className="flex font-bold text-sm p-2 content-center border-zinc-400 m-0" style={{ color: "#2aa996" }}>
                    
                    <div className="w-16 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div className="font-bold text-sm p-2" style={{
                    color: "grey"
                }}>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div className="font-bold text-sm p-2 w-18 content-center border-zinc-400">
                    
                    <div className="flex-shrink-0 flex" style={{ width: "70%" }}>
                        <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>

                    </div>
                </div>


            </div>
        </div>

    </>)
}

export default DashboardCardSekelton;
import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faQuoteLeftAlt,
  faComment,
  faSearch,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";

interface SidebarProps {
  page?: string;
}

const Sidebar: FC<SidebarProps> = ({ page }) => {
  return (
    <div className="bg-white w-full flex flex-col sticky  sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 dark:bg-neutral-800 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8 xl:h-screen">
      <div className="space-y-4">
        <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 active"
          to={"/dashboard"}
          className="block py-1 md:py-2 pl-2 border-l-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 active">
            <FontAwesomeIcon className="h-5 w-5" icon={faBriefcase} />

            <span className="text-neutral-6000 dark:text-neutral-300">
              Dashboard
            </span>
          </div>
        </NavLink>
        {/* <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 active"
          to={"/rate-search"}
          className="block py-1 md:py-2 pl-2 border-b-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 ">
            <FontAwesomeIcon className="h-5 w-5" icon={faArrowTrendUp} />
            <span className="text-neutral-6000 dark:text-neutral-300 ">
              Rate Search
            </span>
          </div>
        </NavLink> */}
        <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 active"
          to={"/all-bookings"}
          className="block py-1 md:py-2 pl-2 border-l-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 active">
            <FontAwesomeIcon className="h-5 w-5" icon={faBriefcase} />

            <span className="text-neutral-6000 dark:text-neutral-300">
              Bookings
            </span>
          </div>
        </NavLink>

        <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 active"
          to={"/all-quotes"}
          className="block py-1 md:py-2 pl-2 border-b-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 ">
            <FontAwesomeIcon className="h-5 w-5" icon={faQuoteLeftAlt} />

            <span className="text-neutral-6000 dark:text-neutral-300">
              Quotes
            </span>
          </div>
        </NavLink>
        <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 active"
          to={"/recent-search"}
          className="block py-1 md:py-2 pl-2 border-b-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 ">
            <FontAwesomeIcon className="h-5 w-5" icon={faSearch} />
            <span className="text-neutral-6000 dark:text-neutral-300 ">
              Recent Searches
            </span>
          </div>
        </NavLink>
        <NavLink
          activeClassName="bg-secondary-200 rounded-lg dark:bg-neutral-700 "
          to={"/help-center"}
          className="block py-1 md:py-2 pl-2 border-b-2 border-transparent flex-shrink-0"
        >
          <div className="flex items-center space-x-4 ">
            <FontAwesomeIcon className="h-5 w-5" icon={faComment} />
            <span className="text-neutral-6000 dark:text-neutral-300 ">
              Help
            </span>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Feb 28 2023
 * Updated on Tue Feb 28 2023
 * File Content:
 * *================*
 * *================*
 * Transportation Rate Card
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Mar 03 2023
 * Updated at 7:48:48 PM
 *
 */


// React Default imports
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Custom Components
import NcModal from "shared/NcModal/NcModal";

// Libraries, Contexts & Helpers
import { useUserAuth } from "utils/contexts/userContext";
import moment from "moment";
import { useQuoteList } from "utils/contexts/quoteListContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import CommodityAddressPage from "./CommodityAddressPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

const TransportationRateCard = ({ data }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { isLogin } = useUserAuth();
  const { userState } = useUserDetails();

  useEffect(() => {
    console.log("Rate Data", data);
  }, [data]);

  return (
    <div
      className={`nc-RateCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl  overflow-hidden hover:shadow-lg transition-shadow space-y-6 `}
      data-nc-id="RateCard"
    >
      <div className={` sm:pr-20 relative  `} data-nc-id="RateCard">
        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          {/* <div className="w-12 lg:w-32 flex-shrink-0">
            <img src={data.sl_logo} className="w-[80%] h-[80%]" alt="" />
          </div> */}
          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {data.loading_port}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                {data.factory_city}
              </span>
            </div>

            <div className="flex flex-col  text-sm text-neutral-500 font-normal mt-0.5">
              <span className="mx-2">
                Transportation Type : {data.transportation_type}
              </span>
              <span className="mx-2">Cargo Type: FCL-{data.type}</span>
              <span className="mx-2">Cargo Weight: {data.cargo_weight}</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">
              {data.loading_port}{" "}
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-down"></i>
              </span>{" "}
              {data.factory_city}
            </div>
          </div>

          {/* TIME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg"> Cargo Weight:</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.transportation_type} */}
              {data.cargo_weight}
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">Cargo Type: </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              FCL - {data.type}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-col whitespace-nowrap sm:text-right">
            <span className="text-xl font-semibold text-secondary-6000">
              {" "}
              <div className="flex flex-col gap-[0.2rem] align-self">
                

                <span>
                  {isLogin.login && userState ? `INR ${data.rates}` : "****"}
                </span>
                <span className="text-[0.7rem] leading-3 text-normal font-normal text-black">
                <FontAwesomeIcon className="text-[#eed202]" icon={faWarning}/>
                 {" "} Rate is subject to
                  <br /> exact factory address
                </span>
              </div>{" "}
              {isLogin.login && userState ? (
                <div className="mt-5 ">
                  <NcModal
                    renderTrigger={(openModal) => (
                      <button
                        className="p-2 border border-black rounded-2xl"
                        onClick={() => openModal()}
                      >
                        Book Now
                      </button>
                    )}
                    renderContent={() => <CommodityAddressPage data={data} />}
                  />
                </div>
              ) : (
                <div className=" mt-5 font-medium underline underline-offset-1">
                  <Link to="/signup">Sign up to know the rates</Link>
                </div>
              )}
            </span>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {/* {renderDetail()} */}
    </div>
  );
};
export default TransportationRateCard;

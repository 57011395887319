import React, { useEffect, ComponentType } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useRedirectContext } from "utils/contexts/redirectContext";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";

export interface RouteProps {
  path: string;
  component: ComponentType<Object>;
  exact: boolean;
}

const PrivateRoute = (props: RouteProps) => {
  const location = useLocation();

  const { isLogin } = useUserAuth();
  const { setRedirect } = useRedirectContext();

  

  useEffect(() => {

    if (!isLogin.login) {
      setRedirect({
        isRedirect: true,
        redirectUrl: location.pathname,
        queryParams: location.search,
      });
    }
  });

  return isLogin.login ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: location },
      }}
    />
  );
};

export default PrivateRoute;

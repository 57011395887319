module.exports = {
  ocean: {
    ratesListing: "/ocean/rates-listing",
  },
  transportaiton: {
    ratesListing: "/transportation/rates-listing",
  },
  customClearance: {
    ratesListing: "/custom-clearance/rates-listing",
  },
};

import { useEffect, useState } from "react";

const renderTab = (type) => {
  switch (type) {
    case "20gp":
      return "20' Standard";
    case "40gp":
      return "40' Standard";
    case "40hc":
      return "40' High Cube";
    case "20rf":
      return "20' Reefer";
    case "40rf":
      return "40' Reefer";
  }
};

const CargoTypeTabs = ({ rates, tabList, selectCargo }) => {
  const [activeTab, setActiveTab] = useState(tabList[0]);

  useEffect(() => {
    selectCargo(activeTab);
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(tabList[0]);
  }, [tabList]);

  if (Object.keys(rates).length === 0) {
    return null;
  }

  return (
    <div className="border-b border-neutral-200   dark:border-neutral-700  dark:bg-transparent">
        <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
          {Object.keys(rates).map((item, index) => {
            return (
              <button
                key={index}
                onClick={() => setActiveTab(item)}
                className={`block py-1 mx-2 relative md:py-2 border-b-2 text-sm md:text-base  flex-shrink-0 ${
                  activeTab === item ? "border-b-2 border-primary-500" : "border-transparent"
                }`}
              >
                {renderTab(item)}
                <span className="absolute top-0 ml-1 w-5 h-5 text-sm bg-neutral-400 text-white lg:w-6 lg:h-6  rounded-full">
                  {rates[item].length}
                </span>
              </button>
            );
          })}
        </div>
    </div>
  );
};

export default CargoTypeTabs;

import { secureAPI, regularAPI, secureUploadFiles } from "./apiGenerator";

export const secureGetRequest = (URL, params = {}) => {
    return secureAPI.get(URL, {
        params: params
    }).then(response => response);
}

export function securePostRequest(URL, payload) {
    return secureAPI.post(URL, payload).then(response => response);
}
export function secureUploadRequest(URL, payload) {
    return secureUploadFiles.post(URL, payload).then(response => response);
}

export function securePatchRequest(URL, payload) {
    return secureAPI.patch(URL, payload).then(response => response);
}

export function secureDeleteRequest(URL) {
    return secureAPI.delete(URL).then(response => response);
}

export const getRequest = (URL, params = {}) => {
    return regularAPI.get(URL, {
        params: params
    }).then(response => response);
}

export function postRequest(URL, payload) {
    return regularAPI.post(URL, payload).then(response => response);
}

export function patchRequest(URL, payload) {
    return regularAPI.patch(URL, payload).then(response => response);
}

export function deleteRequest(URL) {
    return regularAPI.delete(URL).then(response => response);
}
import { MegamenuItem, NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";
import __megamenu from "./jsons/__megamenu.json";
import { auth } from "utils/firebase/firebase-config";
import { getAuth, onAuthStateChanged } from "firebase/auth";

let authLogin;

onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    authLogin = true;
  } else {
    authLogin = false;
  }
});

const megaMenuDemo: MegamenuItem[] = [
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Company",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Company,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/2662116/pexels-photo-2662116.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "App Name",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.AppName,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5059013/pexels-photo-5059013.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "City",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.City,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/5159141/pexels-photo-5159141.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Contruction",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Contruction,
    })),
  },
  {
    id: ncNanoId(),
    image:
      "https://images.pexels.com/photos/7473041/pexels-photo-7473041.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    title: "Country",
    items: __megamenu.map((i) => ({
      id: ncNanoId(),
      href: "#",
      name: i.Country,
    })),
  },
];

const demoChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Online Booking",
  },
  {
    id: ncNanoId(),
    href: "/home-2",
    name: "Real Estate",
    isNew: true,
  },
  {
    id: ncNanoId(),
    href: "/home-1-header-2",
    name: "Home - Header 2",
    isNew: true,
  },
];

const otherPageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/blog",
    name: "Blog Page",
  },
  {
    id: ncNanoId(),
    href: "/blog-single",
    name: "Blog Single",
  },
  {
    id: ncNanoId(),
    href: "/about",
    name: "About",
  },
  {
    id: ncNanoId(),
    href: "/contact",
    name: "Contact us",
  },
  {
    id: ncNanoId(),
    href: "/login",
    name: "Login",
  },
  {
    id: ncNanoId(),
    href: "/signup",
    name: "Signup",
  },
  {
    id: ncNanoId(),
    href: "/subscription",
    name: "Subscription",
  },
];

const financeChildrenMenus: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "../freight-finance",
    name: "Freight Finance",
  }
]

const dashboardChildrenMenus: NavItemType[] = [
  // {
  //   id:ncNanoId(),
  //   href: "/rate-search",
  //   name:"Rate Search",
  // },
  {
    id: ncNanoId(),
    href: "/all-bookings",
    name: "All Bookings",
  },
  {
    id: ncNanoId(),
    href: "/all-quotes",
    name: "All Quotes",
  },
  {
    id: ncNanoId(),
    href: "/help-center",
    name: "Help",
  },
  {
    id: ncNanoId(),
    href: "/recent-search",
    name: "Recent Searches"
  }
]

const templatesChildrenMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/#",
    name: "Ship Schedules",
  }
];

export const NAVIGATION_DEMO: NavItemType[] = [

  {
    id: ncNanoId(),
    href: "/",
    name: "Home",
    protected: false,
    isMobile: false,


  },
  {
    id: ncNanoId(),
    href: "/custom-clearance",
    name: "Custom Clearance",
    protected: false,
    isNew: true,
    isMobile: false,


  },
  {
    id: ncNanoId(),
    href: "/cargo-insurance",
    name: "Cargo Insurance",
    protected: false,
    isMobile: false,

  },
  {
    id: ncNanoId(),
    href: "/warehousing",
    name: "Warehousing",
    protected: false,
    isMobile: false,

  },
  // {
  //   id: ncNanoId(),
  //   href: "#",
  //   name: "Finance",
  //   type: "dropdown",
  //   protected: false,
  //   isMobile: false,
  //   children: financeChildrenMenus,

  // },
  {
    id: ncNanoId(),
    href: "/",
    name: "Dashboard",
    type: "dropdown",
    protected: true,
    children: dashboardChildrenMenus,
    isMobile: true,
  },


  {
    id: ncNanoId(),
    href: "https://calendly.com/masoodahmed-1",
    targetBlank: true,
    protected: false,
    name: "Talk to us",
    className: "text-white bg-neutral-700 h-10",
    isMobile: false,

  },


];

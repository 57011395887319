import React, { FC } from "react"
import { ForgotPasswordProps } from ".";
import { Helmet } from "react-helmet";
import SuccessIcon from "../../images/icons/success.svg"
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory } from "react-router-dom";


const Thankyou: FC<ForgotPasswordProps> = ({ className = "" }) => {
    const history = useHistory();
    return (
        <>
            <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
                <Helmet>
                    <title>Forgot Password Thankyou || Transpost</title>
                </Helmet>
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-5 flex pb-2 items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Forgot Password
                    </h2>
                    <div className="mx-auto">

                        <img src={SuccessIcon} className="mx-auto w-[10%] pb-4" alt="" />
                        <p className=" text-justify px-[50vh] pb-5">
                            We have recieved your email address
                            if it's associated with an account in our system, we'll send you a password reset link.
                        </p><p className=" text-justify px-[50vh]">
                            Please note that the password reset link will expire in <b>one hour</b> for security reasons. Make sure to check your email promptly and follow the instructions to reset your password.

                            If you don't receive the email within a few minutes, please check your spam or junk folder. If you still can't find it, feel free to contact our support team for further assistance.

                        </p>
                        <div className=" items-center flex pt-6">
                            <ButtonPrimary className="m-auto" onClick={() => {
                                history.push("/login")
                            }
                            }> Back to Login</ButtonPrimary>
                        </div>


                    </div>

                </div>
            </div>
        </>
    );
}

export default Thankyou;
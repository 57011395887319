import React, { FC, useEffect, useState } from "react"

import BookingCard from "./BookingCard"
import { Link } from "react-router-dom";
import DashboardCardSekelton from "components/SkeletonComponents/DashboardCardSekelton";

interface BookingListProps {
    bookingList?: [];
    all?: boolean;
    loading?: boolean;
}

const BookingList: FC<BookingListProps> = ({
    bookingList,
    all,
    loading
}) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [typing, setTyping] = useState({
        name: "",
        typing: false,
        typingTimeout: 0,
    });

    useEffect(() => {
        console.log(bookingList , loading, searchTerm)
        console.log(!loading && bookingList?.length! && searchTerm.length > 0 )
    } )

    return (<>

        {all ? <h2 className="font-bold " style={{ color: "#2aa996" }}>Recent Bookings {!loading && bookingList && bookingList.length > 3 && <span className="text-sm float-right underline text-black cursor-pointer"><Link to="/all-bookings">view more</Link></span>}</h2> :
            <h2 className="font-bold pb-2" style={{ color: "#2aa996" }}>All Bookings <div className="flex items-center border-b border-teal-500 float-right">
                <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                    type="text" placeholder="Search Here" aria-label="Full name" onChange={(e) => setTyping({
                        name: e.target.value,
                        typing: false,
                        typingTimeout: window.setTimeout(function () {
                            setSearchTerm(e.target.value);
                        }, 1000),
                    })} />
            </div></h2>
        }
        <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 p-5 gap-6 bg-white dark:text-neutral-300  dark:bg-neutral-800 ">

            {/* <main className="mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row"> */}

            {loading && [1, 2, 3].map(() => {
                return <DashboardCardSekelton />
            })}

            {!loading && bookingList?.length! && searchTerm.length > 0 ? bookingList?.map((booking, index) => {
                let includes = false
                Object.keys(booking).forEach(bookingKey => {
                    let compare = String(booking[bookingKey]).toLocaleLowerCase()
                    if (compare.includes(searchTerm.toLocaleLowerCase())) includes = true
                })
                if (includes) return (<div className="w-full h-full  "><BookingCard data={booking} /></div>);
            }).reverse() : bookingList!.map((booking, index) => {
                console.log("Inside booking list")
                return (<div className="w-full h-full  " key={index}><BookingCard data={booking} /></div>)
            }).reverse()}

            {!loading && bookingList?.length === 0 && "No Bookings Available"}


            {/* </main> */}
        </div>

    </>);
}

export default BookingList;
/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Mar 13 2023
 * Updated at 5:54:40 PM
 *
 */

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { IquoteList } from "../../new_component/Quotation/QuoteModal";
import { useSessionStorage } from "hooks/useSessionStorage";
import { result } from "lodash";

type QuoteListProviderProps = {
  children: ReactNode;
};

type quoteListContext = {
  addQuote: (id: string, data: any) => void;
  quoteList: IquoteList[];
  addRemarks: (id: string, data: any) => void;
  addTerms: (id: string, data: any) => void;
  addSumsell: (id: string, totalSell: number) => void;
  addCharge: (
    id: any,
    chargeTarget: string,
    chargeId: string,
    legId: string
  ) => void;

  addLegs: (id: string) => void;
  deleteLegs: (id: string, customChargeId: string) => void;
  editCharge: (
    id: string, // quoteId
    chargeTarget: string, // also legId
    chargeId: string, // additional charge ID
    basis: string,
    chargeName: string,
    buyRate: number,
    sellRate: number,
    netBuyRate: number,
    netSellRate: number,
    quantity: number,
    legId: string,
    legName: string
  ) => void;
  removeCharge: (
    id: any,
    chargeTarget: string,
    chargeId: string,
    legId: string
  ) => void;
};

// getLocalStorage
export const getSessionStorage = () => {
  let quote_list = sessionStorage.getItem("quote_list");
  if (quote_list) {
    return (quote_list = JSON.parse(
      sessionStorage.getItem("quote_list") || ""
    ));
  } else {
    return [];
  }
};

const quoteListContext = createContext({} as quoteListContext);

export const useQuoteList = () => {
  return useContext(quoteListContext);
};

export const QuoteListProvider = ({ children }: QuoteListProviderProps) => {
  const [quoteList, setQuoteList] = useState<IquoteList[]>(getSessionStorage);
  const [quotes, setQuotes] = useSessionStorage<IquoteList[]>("quote_list", []);

  useEffect(() => {
    setQuotes(quoteList);
    console.log("quoteList", quoteList);
    console.log("quotes", quotes);
  }, [quoteList]);

  const sum = (arr: number[], initialvalue: number) => {
    return arr.reduce((pv: number, cv: number) => {
      if (typeof pv == "number" && typeof cv == "number") {
        return pv + cv;
      } else {
        return pv;
      }
    }, initialvalue);
  };

  // adding new quote
  const addQuote = (id: string, data: any) => {
    setQuoteList((prevState: any) => {
      if (prevState?.find((item: any) => item.quoteId == id) == null) {
        // console.log("prevState in", prevState);

        return [
          ...prevState,
          {
            quoteId: id,
            sum_buy: data.totalPrice,
            sum_sell: data.totalPrice,
            isEditing: true,
            ...data,
          },
        ];
      } else {
        // console.log("prevState out", prevState);
        return prevState;
      }
    });
  };

  const addSumsell = (id: string, totalSell: number) => {
    setQuoteList(addSumHandler(id, totalSell));
  };

  const addSumHandler = (id: string, totalSell: number) => {
    if (quoteList.length > 0) {
      const result = quoteList.map((quote: any) => {
        if (quote?.id === id) {
          return {
            ...quote,
            sum_sell: totalSell,
          };
        } else {
          return quote;
        }
      });
      return result;
    } else {
      return [];
    }
  };

  // add remarks and inclusions
  const addRemarks = (id: string, remark: string) => {
    console.log("REMARK =>", remark);

    setQuoteList(addRemarksHandler(id, remark));
  };

  const addRemarksHandler = (id: string, remark: string) => {
    if (quoteList.length > 0) {
      const result = quoteList.map((quote: any) => {
        if (quote?.id === id) {
          return {
            ...quote,
            remarks: remark,
          };
        } else {
          return quote;
        }
      });
      console.log("Remarks reuslt", result);
      return result;
    } else {
      return [];
    }
  };

// Add terms and inclusions
  const addTerms = (id: string, terms: string) => {
    console.log("Terms =>", terms);

    setQuoteList(addTermsHandler(id, terms));
  };

  // ADD Terms handler
  const addTermsHandler = (id: string, terms: string) => {
    if (quoteList.length > 0) {
      const result = quoteList.map((quote: any) => {
        if (quote?.id === id) {
          return {
            ...quote,
            terms: terms,
          };
        } else {
          return quote;
        }
      });
      console.log("Remarks reuslt", result);
      return result;
    } else {
      return [];
    }
  };

  // function for adding charges
  const addChargeHandler = (
    id: string,
    chargeTarget: string,
    chargeId: string,
    legId: string
  ) => {
    if (quoteList.length > 0) {
      const result = quoteList.map((quote: any) => {
        if (quote?.id === id) {
          switch (chargeTarget) {
            case "ORIGIN":
              return {
                ...quote,
                originCharges: [
                  ...quote.originCharges,
                  {
                    chargeId: new Date().getTime().toString(),
                    chargeName: "",
                    chargeType: "",
                    chargeTarget: "ORIGIN",
                    totalAmountInUSD: 0,
                  },
                ],
              };
            case "DESTINATION":
              return {
                ...quote,
                destinationCharges: [
                  ...quote.destinationCharges,
                  {
                    chargeId: new Date().getTime().toString(),
                    chargeName: "",
                    chargeType: "per Equipment",
                    chargeTarget: "DESTINATION",
                    totalAmountInUSD: 0,
                  },
                ],
              };
            case "FREIGHT":
              return {
                ...quote,
                freightCharges: [
                  ...quote.freightCharges,
                  {
                    chargeId: new Date().getTime().toString(),
                    chargeName: "",
                    chargeType: "",
                    chargeTarget: "FREIGHT",
                    totalAmountInUSD: 0,
                  },
                ],
              };
            case legId:
              console.log("Inside the leg", quote.customLegs);
              return {
                ...quote,
                customLegs: quote.customLegs.map((item: any) => {
                  if (item.id === legId) {
                    return {
                      ...item,
                      customCharge: [
                        ...item.customCharge,
                        {
                          chargeId: new Date().getTime().toString(),
                          chargeName: "",
                          chargeType: "",
                          chargeTarget: legId,
                          totalAmountInUSD: 0,
                        },
                      ],
                    };
                  } else {
                    return item;
                  }
                }),
              };
          }
        } else {
          return quote;
        }
      });

      console.log("result", result);

      return result;
    } else {
      return [];
    }
  };

  // add additional charge
  const addCharge = (
    id: string,
    // charges: [],
    chargeTarget: string,
    chargeId: string,
    legId: string
  ) => {
    setQuoteList(addChargeHandler(id, chargeTarget, chargeId, legId));
  };

  // Edit Charge function
  const editCharge = (
    id: string, // quoteId
    chargeTarget: string,
    chargeId: string,
    basis: string,
    chargeName: string,
    buyRate: number,
    sellRate: number,
    netBuyRate: number,
    netSellRate: number,
    quantity: number,
    legId: string,
    legName: string
  ) => {
    setQuoteList(
      quoteList.map((quote: any) => {
        if (quote.quoteId === id) {
          switch (chargeTarget) {
            case "ORIGIN":
              return {
                ...quote,
                originCharges: quote.originCharges.map((item: any) => {
                  if (item.chargeId === chargeId) {
                    console.log("Inside origin charges");
                    return {
                      ...item,
                      quantity,
                      buyRate,
                      sellRate,
                      netBuyRate,
                      netSellRate,
                      chargeName,
                    };
                  } else {
                    return item;
                  }
                }),
              };
            case "DESTINATION":
              return {
                ...quote,
                destinationCharges: quote.destinationCharges.map(
                  (item: any) => {
                    if (item.chargeId === chargeId) {
                      return {
                        ...item,
                        quantity,

                        buyRate,
                        sellRate,
                        netBuyRate,
                        netSellRate,
                        chargeName,
                      };
                    } else {
                      return item;
                    }
                  }
                ),
              };
            case "FREIGHT":
              return {
                ...quote,
                freightCharges: quote.freightCharges.map((item: any) => {
                  if (item.chargeId === chargeId) {
                    return {
                      ...item,
                      quantity,

                      buyRate,
                      sellRate,
                      netBuyRate,
                      netSellRate,
                      chargeName,
                    };
                  } else {
                    return item;
                  }
                }),
              };
            case legId:
              return {
                ...quote,
                customLegs: quote.customLegs.map((item: any) => {
                  if (item.id === legId) {
                    return {
                      ...item,
                      legName,
                      customCharge: item.customCharge.map((val: any) => {
                        if (val.chargeId === chargeId) {
                          return {
                            ...val,
                            quantity,
                            buyRate,
                            sellRate,
                            netBuyRate,
                            netSellRate,
                            chargeName,
                          };
                        } else {
                          return val;
                        }
                      }),
                    };
                  } else {
                    return item;
                  }
                }),
              };
            default:
              return quote;
          }
        } else {
          return quote;
        }
      })
    );
  };

  const addLegs = (id: string) => {
    setQuoteList(
      quoteList?.map((quote: any) => {
        if (quote?.quoteId === id) {
          const newChargeId = new Date().getTime().toString();
          const chargeName = "customCharge" + newChargeId;
          const prevState = quote.customLegs || [];
          console.log("quote", quote);

          return {
            ...quote,
            customLegs: [
              ...prevState,
              {
                id: newChargeId,
                legName: "",
                customCharge: [
                  {
                    chargeId: newChargeId,
                    chargeName: "",
                    chargeType: "",
                    chargeTarget: newChargeId,
                    totalAmountInUSD: 0,
                  },
                ],
              },
            ],
          };
        } else {
          return quote;
        }
      })
    );
  };

  const deleteLegs = (id: string, customChargeId: string) => {
    setQuoteList(
      quoteList?.map((quote: any) => {
        if (quote?.id === id) {
          return {
            ...quote,
            customLegs: quote?.customLegs?.filter(
              (item: any) => item.id !== customChargeId
            ),
          };
        } else {
          return quote;
        }
      })
    );
  };

  // Remove Charge
  const removeCharge = (
    id: any,
    chargeTarget: string,
    chargeId: string,
    legId: string
  ) => {
    setQuoteList(
      quoteList?.map((quote: any) => {
        if (quote?.id == id) {
          switch (chargeTarget) {
            case "ORIGIN":
              return {
                ...quote,
                originCharges: quote?.originCharges?.filter(
                  (item: any) => item.chargeId !== chargeId
                ),
              };
            case "DESTINATION":
              return {
                ...quote,
                destinationCharges: quote?.destinationCharges?.filter(
                  (item: any) => item.chargeId !== chargeId
                ),
              };
            case "FREIGHT":
              console.log("Remove charge", chargeTarget);

              return {
                ...quote,
                freightCharges: quote?.freightCharges?.filter((item: any) => {
                  console.log("chargeId");

                  return item.chargeId !== chargeId;
                }),
              };
            case legId:
              console.log("Inside the leg", quote.customLegs);
              return {
                ...quote,
                customLegs: quote.customLegs.map((item: any) => {
                  if (item.id === legId) {
                    return {
                      ...item,
                      customCharge: item?.customCharge?.filter(
                        (item: any) => item.chargeId !== chargeId
                      ),
                    };
                  } else {
                    return item;
                  }
                }),
              };
          }
        } else {
          return quote;
        }
      })
    );
  };

  return (
    <quoteListContext.Provider
      value={{
        quoteList,
        addSumsell,
        addQuote,
        addLegs,
        deleteLegs,
        addCharge,
        addRemarks,
        addTerms,
        editCharge,
        removeCharge,
      }}
    >
      {children}
    </quoteListContext.Provider>
  );
};

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Fri Mar 24 2023
 * Created at 11:16:24 AM
 * File Content:
 * *================*
 * *================*
 * Your Content Here
 * *================*
 * *================*
 */


import React, { FC, useEffect, useState } from "react";
import CommonLayout from "containers/AccountPage/CommonLayout";
import Sidebar from "../components/Sidebar";
import { secureGetRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useEventContext } from "utils/contexts/eventContext";
import QuoteList from "../components/QuotesList";
import DashboardCardSekelton from "components/SkeletonComponents/DashboardCardSekelton";
import useWindowSize from "hooks/useWindowResize";
import {Helmet} from "react-helmet"

interface AllQuotesProps {
    className?: string;
}

const AllQuotes: FC<AllQuotesProps> = ({

}) => {

    const { isLogin } = useUserAuth()
    const { userState } = useUserDetails()
    const { setError } = useEventContext();

    const [allQuotes, setAllQuotes] = useState<any>([]);
    const [loading, setLoading] = useState(false)

    const { width } = useWindowSize()

    const fetchAllQuotes = async () => {
        setLoading(true);
        try {
            const allQuotes = await secureGetRequest(apiPaths.quotes.getAll, {
                customer_id: userState?.customer.ID
            })

            setAllQuotes(allQuotes.data.data)
            setLoading(false);

        }
        catch (err: any) {
            setLoading(false);
            setError({ ...err.response.data, show: true })
        }
    }

    useEffect(() => {
        if (isLogin.login) {
            fetchAllQuotes()
        }
    }, [isLogin.login])

    return (<>
        <CommonLayout>
        <Helmet>
          <title>Quotes | Transpost</title>
        </Helmet>
            <main className="mt-1 mb-24 lg:mb-20 flex flex-col lg:flex-row">
                {width > 996 && <div className="block flex-grow mb-24 lg:mb-0">
                    <div className="lg:sticky lg:top-24"><Sidebar /></div>
                </div>}
                <div className="w-auto md:w-[80%] space-y-5 lg:pl-5 flex-shrink-0">
                    


                    <QuoteList quotesList={allQuotes} loading={loading} />

                </div>
            </main>
        </CommonLayout>
    </>)
}


export default AllQuotes;
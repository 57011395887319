/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Feb 14 2023
 * Updated on Tue Feb 14 2023
 * File Content:
 * *================*
 * *================*
 * Your Content Here
 * *================*
 * *================*
 */
/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Tue Feb 14 2023
 * Updated at 5:39:14 PM
 *
 */

import React, { useState, SVGProps } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import { useRef } from "react";
import { ReactComponent as StuffingSvg } from "../../images/transpost images/Custom-clearance/stuffing-container.svg";

export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  searchList?: any;
  img?: any;
}

const NcSelect: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  searchList,
  placeHolder = "",
  desc = "",
  className = "nc-flex-1.5",
  img,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(defaultValue);
  const [showPopover, setShowPopover] = useState(autoFocus);

  // recent seach should contain all the ports

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    onChange && onChange(value);
  }, [value]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelect = (item: string) => {
    setValue(item);
    onInputDone && onInputDone(item);
    setShowPopover(false);
  };

  const renderSearchValue = () => {
    return (
      <>
        {searchList &&
          searchList.length > 0 &&
          searchList.map((item: any) => (
            <span
              onClick={() => handleSelect(item.name)}
              key={item.id}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400 ">
                {/* <StuffingSvg className="w-12 h-16 text-neutral-400 bg-gray-400" /> */}
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item.name}
              </span>
            </span>
          ))}
      </>
    );
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 w-9 h-9 dark:text-neutral-400">
          <img src={img} alt="" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
            placeholder={placeHolder}
            value={value}
            autoFocus={showPopover}
            onChange={(e) => setValue(e.currentTarget.value)}
          />
          <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
            <span className="line-clamp-1">{!!value ? placeHolder : desc}</span>
          </span>
          {value && showPopover && (
            <ClearDataButton onClick={() => setValue("")} />
          )}
        </div>
      </div>
      {showPopover && (
        <div className=" absolute left-0 z-max w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {renderSearchValue()}
          {/* {value ? renderSearchValue() : renderRecentSearches} */}
        </div>
      )}
    </div>
  );
};

export default NcSelect;

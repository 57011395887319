import React, { useState, useEffect } from "react";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { FocusedInputShape } from "react-dates";
import PropertyStatusInput from "./PropertyStatusInput";
import PropertyTypeInput from "./PropertyTypeInput";
import { useHistory } from "react-router-dom";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useEventContext } from "utils/contexts/eventContext";

const RentWarehouseSearchForm = () => {
  const [propertyStatusValue, setPropertyStatusValue] = useState("");
  const [propertyTypeInputValue, setPropertyTypeInputValue] = useState("");
  const [dropOffInputValue, setDropOffInputValue] = useState("");
  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | "startDate" | null
  >(null);
  // TODO: Fix search form with proper request
  const [keyForm, setKeyForm] = useState({
    agentId: "1290",
    cname: "",
    cmobile: "",
    cemail: "",
    ccity: "",
    gstnumber: "",
    gstcompanyname: "",
    gstphone: "",
    gstemail: "",
    gstaddress: "",
    gststate: "",
    gstcity: "",
    gstpincode: "",
  });
  const [hashForm, setHashForm] = useState({
    agentId: "1290",
    cmobile: "",
    cemail: "",
    ccity: "",
  });

  const { setIsLoading } = useEventContext();

  const { userState } = useUserDetails();
  const location = useHistory();

  // Set initial state for setting user Details
  useEffect(() => {
    if (userState) {
      const { contact_person, phone, email, name, gst_number } =
        userState.customer;
      setKeyForm({
        ...keyForm,
        cname: contact_person || "",
        cmobile: phone || "",
        cemail: email || "",
        gstnumber: gst_number || "",
        gstcompanyname: name || "",
        gstphone: phone || "",
        gstemail: email || "",
      });

      setHashForm({
        ...hashForm,
        cmobile: phone || "",
        cemail: email || "",
      });
    }
  }, []);

  useEffect(() => {
    if (dropOffInputValue.length > 0) {
      setHashForm({ ...hashForm, ccity: dropOffInputValue });
      setKeyForm({
        ...keyForm,
        ccity: dropOffInputValue,
      });
    }
  }, [dropOffInputValue]);

  const hashGenerator = async () => {
    console.log("Hash", hashForm);

    var data = hashForm;

    var config: AxiosRequestConfig = {
      method: "post",
      url: "https://storemart.ai/hashgenerator",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: JSON.stringify(data),
    };

    return axios(config)
      .then((response) => {
        return response.data.encryptpass;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const keyGenerator = async () => {
    var data = JSON.stringify(keyForm);

    var config: AxiosRequestConfig = {
      method: "post",
      url: "https://storemart.ai/transpostkeygenerator",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      data: data,
    };

    return axios(config)
      .then((response) => {
        return response.data.encryptpass;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const gotoUrl = async () => {
    setIsLoading(true);
    setTimeout(async () => {
      window.open(
        `https://www.storemart.ai/transpostcall?key=${await keyGenerator()}&hash=${await hashGenerator()}`,
        "_blank"
      );
      setIsLoading(false);

    }, 2000);
  };

  const submitHandler = async (e: any) => {
    e.preventDefault();
    if (userState) {
      await gotoUrl();
    } else {
      location.push("./login");
    }
  };

  const renderForm = () => {
    return (
      <div className="w-full z-max">
        <form className="w-full relative mt-2 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
          {/* {renderRadioBtn()} */}
          <div className=" flex flex-col md:flex-row w-full rounded-full [ nc-divide-field ] ">
            <div className="relative flex flex-col md:flex-row flex-grow [ nc-divide-field ] ">
              <PropertyStatusInput
                defaultValue={propertyStatusValue}
                onChange={(e) => {
                  setPropertyStatusValue(e);
                }}
                onInputDone={() => setFieldFocused("dropOffInput")}
                placeHolder="Property Status"
                desc=""
              />
              <PropertyTypeInput
                defaultValue={propertyTypeInputValue}
                onChange={(e) => {
                  setPropertyTypeInputValue(e);
                }}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="Property Type"
                desc=""
                autoFocus={fieldFocused === "dropOffInput"}
              />
              <LocationInput
                defaultValue={dropOffInputValue}
                onChange={(e) => {
                  setDropOffInputValue(e);
                }}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="Location"
                desc=""
                autoFocus={fieldFocused === "startDate"}
              />

              {/* BUTTON SUBMIT OF FORM */}
            </div>
            <div className="px-4 py-3 flex items-center justify-center">
              {/* <ButtonSubmit  /> */}
              <button
                onClick={submitHandler}
                type="button"
                className="h-14  md:h-16 w-full md:w-16 rounded-full bg-[#cd512f] hover:bg-[#218778] flex items-center justify-center text-neutral-50 focus:outline-none"
              >
                <span className="mr-3 md:hidden">Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  };

  return renderForm();
};

export default RentWarehouseSearchForm;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Thu Mar 02 2023
 * Created at 12:11:20 PM
 * File Content:
 * *================*
 * *================*
 * Rate Card Skeleton Component
 * *================*
 * *================*
 */

import React, { FC } from "react";

export interface RateSkeletonProps {
  className?: string;
}

const RateSkeleton: FC<RateSkeletonProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-RateCardgroup p-4 sm:p-4 relative bg-white dark:bg-neutral-900 border border-neutral-100
dark:border-neutral-800 rounded-2xl  overflow-hidden hover:shadow-lg transition-shadow space-y-6 `}
      data-nc-id="RateCard"
    >
      <div role="status" className="animate-pulse">
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 rounded-l-lg">
                  <div className="w-12 lg:w-32 flex-shrink-0">
                    <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600  mb-2.5"></div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <div className="w-12 lg:w-32 flex-shrink-0">
                    <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600  mb-2.5"></div>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3 rounded-r-lg">
                  <div className="w-12 lg:w-32 flex-shrink-0">
                    <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600  mb-2.5"></div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {[1, 2, 3, 4].map((item: any) => {
                return (
                  <tr className="bg-white dark:bg-gray-800">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    </th>
                    <td className="px-6 py-4">
                      {" "}
                      <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    </td>
                    <td className="px-6 py-4">
                      {" "}
                      <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="py-3 ml-auto flex lg:pr-[12rem] bg-white">
            <div className="flex-col whitespace-nowrap sm:text-right flex-[1]"></div>
            <div className="h-8 bg-gray-300 rounded-full dark:bg-gray-700 w-[150px]"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateSkeleton;

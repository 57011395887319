import React, { FC, ReactNode, useEffect } from "react";

import hero7 from "images/transpost images/heros/Home/hero.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm, { SearchTab } from "./HeroSearchForm";
import ImageSection from "./ImageSection";
import { ReactComponent as StoremartLogo } from "../../images/transpost images/Logos/StoreMart Logo.svg";
import logo from "../../images/transpost images/Logos/storemart.png";

import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useHistory } from "react-router-dom";
import useWindowSize from "hooks/useWindowResize";
import { useEventContext } from "utils/contexts/eventContext";
import TranspostLoader from "new_component/TranspostLoader/TranspostLoader";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage:
    | "Stays"
    | "Cargo Tracker"
    | "Cars"
    | "Ocean"
    | "Warehousing & Disitribution";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  className = "",
  listingType,
  currentPage,
  currentTab,
  rightImage = hero7,
}) => {
  const { userState } = useUserDetails();

  const location = useHistory();
  const windowSize = useWindowSize();

  const onClickHandler = () => {
    if (userState) {
      window
        .open(
          `https://www.storemart.ai/transpostcall?key=${userState.storemart_key?.encryptpass}&hash=${userState.storemart_token?.encryptpass}`,
          "_blank"
        )
        ?.focus();
    } else {
      location.push("./login");
    }
  };

  const { isLoading } = useEventContext();

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative pb-10 lg:pb-1`}
      data-nc-id="SectionHeroArchivePage"
    >
      {isLoading && <TranspostLoader />}
      <div className="flex flex-col lg:flex-row  px-[12rem] lg:pb-[5.3rem] lg:items-center">
        <div className=" hidden lg:flex  flex-grow ">
          <img className="w-full pb-10 object-fill" src={rightImage} alt="hero" />
        </div>
      </div>

      <div className="flow-root w-full">
        {/* for moving the search bar up and down */}
        <div className="z-10 lg:-mt-40 xl:-mt-18 w-full">
          <HeroSearchForm currentPage={currentPage} currentTab={currentTab} />
        </div>
        <div className="flex pt-4 mb-8 mt-[5rem] w-full">
          <span className=" text-md items-end flex font-semibold lg:text-3xl ">
            Find Properties across India
          </span>
          {windowSize.width >= 598 && (
            <div
              onClick={onClickHandler}
              className="flex items-end ml-auto cursor-pointer"
            >
              <p className="text-sm font-bold mr-4">
                {" "}
                Powered by Storemart
              </p>
              <img src={logo} className="h-12 w-15" alt="Storemart Log" />
            </div>
          )}
        </div>
        <ImageSection />
      </div>
    </div>
  );
};

export default SectionHeroArchivePage;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Wed Feb 15 2023
 * Created at 11:20:03 AM
 * File Content:
 * *================*
 * *================*
 * Ocean Search Page Rate Cards
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Mar 10 2023
 * Updated at 12:38:07 PM
 *
 */

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useUserAuth } from "utils/contexts/userContext";
import NcModal from "shared/NcModal/NcModal";
import CommodityInfoPage from "./CommodityInfoPage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import moment from "moment";
import QuoteModal from "./QuoteModal";
import { useQuoteList } from "utils/contexts/quoteListContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useRedirectContext } from "utils/contexts/redirectContext";
import Badge from "shared/Badge/Badge";
import meatsvg from "../../images/svgs/meat.svg";
import dangersvg from "../../images/svgs/danger.svg";
import nonDangerSvg from "../../images/svgs/nondanger.svg";
import { useQueryState } from "hooks/useQueryState";

const OceanRateCard = ({ data }: any) => {
  const location = useLocation();
  const history = useHistory();

  const { setRedirect } = useRedirectContext();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [cargo, setCargo] = useState<string>("");
  const [isWidget] = useQueryState("isWidget")

  const { isLogin, user } = useUserAuth();
  const { addQuote } = useQuoteList();
  const { userState } = useUserDetails();
  const onclose = () => {
    setShowModal(false);
  };

  const createQuoteHandler = () => {
    addQuote(data.id, data);
    setShowModal(true);
  };

  const bookNowHandler = () => {
    history.push({
      pathname: `/ocean/booking`,
      search: `?from=${data.from_port}&to=${data.to_port}&rate=${data.id}`,
      state: {
        data,
        cargo,
      },
    });
  };

  const renderCargoType = () => {
    switch (data.cargo_type.toUpperCase()) {
      case "REEFER":
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="blue"
            name={
              <div className="flex items-center">
                <i className="text-sm la la-snowflake-o"></i>
                <span className="ml-1">REEFER</span>
              </div>
            }
          />
        );
      case "HAZ":
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="yellow"
            name={
              <div className="flex items-center">
                <img src={dangersvg} alt="" style={{ width: "1vw" }} />
                <span className="ml-1">HAZ</span>
              </div>
            }
          />
        );

      case "FAK":
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="green"
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">FAK</span>
              </div>
            }
          />
        );

      case "MEAT ONLY":
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="red"
            name={
              <div className="flex items-center">
                <img src={meatsvg} alt="" style={{ width: "1vw" }} />

                <span className="ml-1">MEAT ONLY</span>
              </div>
            }
          />
        );

      case "NON HAZ":
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="gray"
            name={
              <div className="flex items-center">
                <img src={nonDangerSvg} alt="" style={{ width: "1.2vw" }} />
                <span className="ml-1">NON HAZ</span>
              </div>
            }
          />
        );

      default:
        return (
          <Badge
            className=" absolute -right-2 -top-6"
            color="green"
            name={
              <div className="flex items-center">
                <i className="text-sm las la-share-alt"></i>
                <span className="ml-1">FAK</span>
              </div>
            }
          />
        );
    }
  };

  useEffect(() => {
    switch (data.cargo_size) {
      case "20gp":
        setCargo("20'Standard");
        break;
      case "40gp":
        setCargo("40'Standard");
        break;
      case "40hc":
        setCargo("40'High Cube");
        break;
      case "20rf":
        setCargo("20'Refrigerated");
        break;
      case "40rf":
        setCargo("40'Refrigerated");
        break;
      case "45hc":
        setCargo("45'High Cube");
        break;
    }
    isWidget && bookNowHandler();

  }, [data]);

  const renderHeader = () => {
    return (
      <div className="hidden col-span-2 lg:grid gap-2  lg:grid-cols-6 pb-4 border-b justify-items-center items-center">
        <div className="text-[blue] ">Service</div>
        <div className="self-end justify-self-end text-[blue] ">ETD</div>
        <div className="grid self-end relative px-2 w-full ">
          <span className="w-3 h-3 absolute left-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
          <span className="border-b border-[#01a77e]  top-[50%] w-full"></span>
          <span className="w-3 h-3 absolute right-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
        </div>
        <div className="self-end justify-self-start text-[blue]"> ETA</div>
        <div className="text-[blue]"> Vessel Name</div>
        <div className="font-semibold"> Total Price</div>
        <div className="text-center self-start">
          {" "}
          {data.service_mode.length > 0 ? data.service_mode : "N/A"}
        </div>
        <div className="self-start justify-self-end">
          {" "}
          {data.departures.length > 0
            ? moment(data.departures[0].departureDateEstimated).format("Do MMM")
            : "N/A"}
        </div>
        <div className="self-start text-center">
          <span>
            {data.transit_time.length > 0 ? `${data.transit_time} days` : "N/A"}
          </span>
        </div>
        <div className="self-start justify-self-start">
          {" "}                                                                                                                                                                            
          {data.departures.length > 0
            ? moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")
            : "N/A"}
        </div>
        <div className="text-center">
          {" "}
          {data.vesselName.length > 0 ? data.vesselName : "N/A"}
        </div>
        <div className="">
          {" "}
          <span>USD </span>
          <span className="font-semibold">{data.totalPrice}</span>
        </div>
      </div>
    );
  };

  const renderMobileHeader = () => {
    return (
      <div className=" lg:hidden w-full gap-2 grid grid-cols-2 pb-4 border-b justify-items-center items-center">
        <p className="text-[blue] w-full justify-self-start"> Service:</p>
        <span>{data.service_mode.length > 0 ? data.service_mode : "N/A"}</span>
        <div className="self-center justify-self-start text-[blue] ">ETD</div>
        <div className="self-end justify-self-end text-[blue]"> ETA</div>
        <div className=" grid col-span-2 self-center relative px-2 w-full ">
          <span className="w-3 h-3 absolute left-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
          <span className="border-b border-[#01a77e]  top-[50%] w-full"></span>
          <span className="w-3 h-3 absolute right-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
        </div>
        <div className="self-start justify-self-start">
          {" "}
          {data.departures.length > 0
            ? moment(data.departures[0].departureDateEstimated).format("Do MMM")
            : "N/A"}
        </div>
        <div className="self-end justify-self-end">
          {" "}
          {data.departures.length > 0
            ? moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")
            : "N/A"}
        </div>
        <div className="justify-self-start">Transit time:</div>
        <div className="self-start justify-self-start">
          <span>
            {data.transit_time.length > 0 ? data.transit_time : "N/A"}
          </span>
        </div>
        <div className="text-[blue] justify-self-start"> Vessel Name</div>

        <div className="justify-self-start">
          {" "}
          {data.vesselName.length > 0 ? data.vesselName : "-"}
        </div>
        <div className="font-semibold justify-self-start"> Total Price:</div>
        <div className="justify-self-start">
          {" "}
          <span>USD </span>
          <span className="font-semibold justify-self-start">
            {data.totalPrice}
          </span>
        </div>
      </div>
    );
  };

  const renderSchedule = () => {
    return (
      <div
        className={`flex flex-col  h-min gap-10 border-l border-neutral-400 ${
          data.vgmCutoff.length > 0 ? "w-1/2" : "hidden"
        }`}
      >
        <div className="flex">
          <img
            className="w-10 absolute left-4 lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-doc-cut-off.83dfba82.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">DOC cut off</span>
            {moment(data.docCutoff).format("Do MMM")}{" "}
          </p>
        </div>
        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-vgm-cut-off.920fce76.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">VGM cut off</span>

            {moment(data.vgmCutoff).format("Do MMM")}
          </p>
        </div>
        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-cy-cut-off.75beb148.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">Port CY cut off</span>
            {moment(data.cyCutoff).format("Do MMM")}
          </p>
        </div>

        {data.departures.map((item: any, index: any) => {
          return (
            <div className="flex ">
              <img
                className="w-10 absolute left-4  lg:left-10"
                src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-boat.92edbb69.svg"
              />
              <p className="flex flex-col mx-8 py-2">
                {index === 0 && (
                  <span className="font-semibold">Departure</span>
                )}
                {item.departureTerminal.split("(")[0]} / {item.departureLoc}
                <span>
                  ETD:{" "}
                  {data.departures.length > 0 &&
                    moment(data.departures[0].departureDateEstimated).format(
                      "Do MMM"
                    )}
                </span>
              </p>
            </div>
          );
        })}

        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-location-nofill.c2b55ecd.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">Arrival</span>
            <span>
              {" "}
              {data.departures.length > 0
                ? data.departures[
                    data.departures.length - 1
                  ].arrivalTerminal.split("(")[0] +
                  "/" +
                  data.departures[data.departures.length - 1].arrivalLoc
                : "N/A"}
            </span>
            <span className="font-semibold text-sm">
              ETA:{" "}
              {moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")}
            </span>
            {/* <span>
              {data.departures[data.departures.length - 1].transitTimeInHrs +
                "hrs"}
            </span> */}
          </p>
        </div>
      </div>
    );
  };

  const renderDetailTop = () => {
    return (
      <div
        className={`flex flex-col space-y-3 z-15 ${
          data.vgmCutoff.length > 0 ? "" : "col-span-2"
        }`}
      >
        <div className="font-semibold text-lg uppercase">Freight Charges</div>
        {data.freightCharges.map((item: any) => {
          return (
            <div className="flex w-full justify-between px-2 py-2 my-1 ">
              <div className="capitalize">{item.chargeName.toLowerCase()}</div>
              <div className="font-semibold">USD {item.totalAmountInUSD}</div>
            </div>
          );
        })}
        {data.originCharges.length > 0 && (
          <>
            <div className="border-t border-neutral-300 pt-4  font-semibold text-lg uppercase">
              Origin Charges
            </div>
            <div className=" pb-4">
              {data.originCharges.map((item: any) => {
                return (
                  <div className="flex justify-between border-neutral-300  px-2 py-2  my-1 ">
                    <div className="capitalize">
                      {item.chargeName.toLowerCase()}
                    </div>
                    <div className="font-semibold">
                      USD {item.totalAmountInUSD}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {data.destinationCharges.length > 0 && (
          <>
            <div className="border-t pt-4 border-neutral-300 font-semibold text-lg uppercase">
              Destination Charges
            </div>
            <div className=" pb-4">
              {data.destinationCharges.map((item: any) => {
                return (
                  <div className="flex justify-between border-neutral-300  px-2 py-2  my-1 ">
                    <div className="capitalize">
                      {item.chargeName.toLowerCase()}
                    </div>
                    <div className="font-semibold">
                      USD {item.totalAmountInUSD}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };
  // TODO modify api as sheet rate true or false
  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div
        className={`p-4 md:p-8 gap-4 border border-neutral-200 dark:border-neutral-700 rounded-2xl ${
          data.vgmCutoff.length > 0
            ? "lg:grid lg:grid-cols-2 flex flex-col "
            : "flex flex-col"
        } `}
      >
        {renderHeader()}
        {/* render mobile header */}
        {renderMobileHeader()}
        {data.vgmCutoff.length > 0 && renderSchedule()}
        {renderDetailTop()}
        <div />
        <div className="flex w-full justify-self-end ml-auto">
          <div className="ml-auto flex gap-2">
            {isLogin.login && userState && (
              <ButtonPrimary
                onClick={bookNowHandler}
                className=" justify-self-center self-center  hidden lg:block "
              >
                Book Now
              </ButtonPrimary>
            )}

            <NcModal
              modalTitle="Details"
              // isOpenProp={showModal}
              renderTrigger={(openModal) => (
                <ButtonPrimary
                  onClick={() => {
                    createQuoteHandler();
                    openModal();
                  }}
                  className="ml-auto justify-self-center self-center bg-[#F7961D] hover:bg-[#F37911] text-[#000000] hidden lg:block"
                >
                  +Create Quote
                </ButtonPrimary>
              )}
              renderContent={() => <QuoteModal data={data} onclose={onclose} />}
            />
            {/* </ButtonPrimary> */}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-RateCardgroup p-4 sm:p-4 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl  hover:shadow-lg transition-shadow space-y-6 `}
      data-nc-id="RateCard"
    >
      {/* <QuoteModal data={data} onclose={handleClose} showModal={showModal} /> */}
      <div className={` sm:pr-20 relative`} data-nc-id="RateCard">
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        {/* <Link to="/signup" className="absolute inset-0" /> */}

        {isLogin.login && userState && (
          <span
            className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ease-in-ease-out duration-200 ${
              isOpen ? "rotate-180" : ""
            }`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <i className="text-xl las la-angle-down"></i>
          </span>
        )}

        {/* badge here */}
        {data && renderCargoType()}
        {data && (
          <div className="absolute -left-4 rounded-tl-2xl rounded-br-2xl text-white -top-4 bg-[#2aa996] px-4  text-xs">
            {data.id}
          </div>
        )}
        {/* TODO: Warning */}
        {data && (
          <div className="absolute right-0 -bottom-5 text-[8px] text-neutral-400">
            *Above rates are indicative and subject to availaibilty
          </div>
        )}

        <div className="flex  flex-col sm:flex-row sm:justify-between sm:items-center space-y-6 sm:space-y-0 ">
          {/* LOGO IMG */}
          <div className="w-12 mt-1 lg:mt-0 lg:w-32 flex-shrink-0">
            {/* <div >Expiry date: {data.expiry_date}</div> */}
            <img src={data.sl_logo} className="w-[80%] h-[80%]" alt="" />
          </div>
          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {data.from_port}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                {data.to_port}
              </span>
            </div>

            <div className="flex  text-sm text-neutral-500 font-normal mt-0.5">
              <span className="mx-2">
                Validity {moment(data.expiry_date).format("Do MMM YY")}
              </span>
              <span className="mx-2">Cargo Type: FCL-{cargo}</span>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  flext-[3] ">
            <div className="font-medium text-lg">
              {data.from_port}{" "}
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-down"></i>
              </span>{" "}
              {data.to_port}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.sl_name}
            </div>
          </div>

          {/* TIME */}
          <div className="hidden lg:block  whitespace-nowrap">
            <div className="font-medium text-lg"> Validity</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {moment(data.expiry_date).format("Do MMM YY")}
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block  whitespace-nowrap">
            <div className="font-medium text-lg">Cargo Type</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              FCL - {cargo}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex space-x-3 md:space-x-0">
            <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
              <span className="text-xl font-semibold text-secondary-6000">
                <div className="flex flex-col space-x-2 align-self">
                  <span className="text-sm text-normal font-normal text-black">
                    Freight Cost
                  </span>
                  <span>
                    USD {isLogin.login && userState ? data.base_rate : "****"}
                  </span>
                </div>
              </span>
            </div>

            <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
              <span className="text-xl font-semibold text-secondary-6000">
                <div className="flex flex-col space-x-2  align-self">
                  <span className="text-sm text-normal font-normal text-black">
                    Total Cost
                  </span>
                  <span>
                    USD {isLogin.login && userState ? data?.totalPrice : "****"}
                  </span>
                </div>
              </span>
              <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
                {isLogin.login && userState ? (
                  <div className="mt-5 ">
                    <ButtonPrimary
                      onClick={bookNowHandler}
                      className=" justify-self-center self-center block "
                    >
                      Book Now
                    </ButtonPrimary>
                  </div>
                ) : (
                  <div className=" mt-5 font-medium underline underline-offset-1">
                    <span
                      onClick={() => {
                        setRedirect({
                          isRedirect: true,
                          redirectUrl: location.pathname,
                          queryParams: location.search,
                        });

                        history.push("/login");
                      }}
                    >
                      Sign up to know the rates
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};
export default OceanRateCard;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Tue Feb 21 2023
 * Created at 1:13:59 PM
 * File Content:
 * *================*
 * *================*
 * API paths to be used
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:46:06 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Apr 17 2023
 * Updated at 1:10:54 PM
 *
 */

module.exports = {
  ocean: {
    rates: {
      ocean_rates: "/rates/ocean-rates",
    },
    location: {
      ajax_autocomplete: "/ajax-autocomplete-search",
    },
  },
  ports: {
    getAll: "/get-all-ports",
  },
  transport: {
    rates: {
      transport_select: "/rates/transport_select",
    },
    pickUp: {
      ajax_autocomplete: "/ajax-autocomplete-search-transport-loader-port",
    },
    dropOff: {
      ajax_autocomplete: "/ajax-autocomplete-search-transport",
    },
  },
  rates: {
    pdf: "/rates/pdf",
  },
  freight_finance: {
    store: "/freight-finance/store",
  },
  custom_clearance: {
    custom_clearance: {
      rates: "/custom-clearance/cc_select",
      store: "/custom-clearance/store",
    },
  },
  quotes: {
    getAll: "/user/quotes",
  },
  bookings: {
    recent: "/bookings/recent",
    user: "/bookings/user",
    store: "/bookings/store",
    timeline: "/bookings/timeline",
    incoterm: "/bookings/incoterm",
    transportation: {
      store: "/bookings/transportationstore",
    },
    select: "/bookings/select",
  },
  auth: {
    login: "/login",
    register: "/register",
    checkAccount: "/check-account",
    changePassword: "/change-password",
    forgotPassword: "/forgot-password",
    checkValidity: "/check-validity"
  },
  user: {
    userTracking: "/user/tracking",
    searchActivity: "/user/search-activity",
    profiledetails: "/user/profiledetails",
    file: {
      companyLogo: "/user/companylogo",
      profile: "/user/profilepicture",
      certificates: "/user/file-upload",
    },
    downloadFile: "/user/downloadFile",
    uploadFile:"/user/uploadFile"
  },
  customer: {
    updateDetails: "/customer/update-details",
    getSearchHistory: "customer/get-search-history",
  },
  dashboard: {
    analytics: "/dashboard/analytics"
  }
};

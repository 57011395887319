import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode | string;
  subHeading: string;
  btnText: string;
  clickHandler: () => void;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
  clickHandler,
}) => {
  return (
    <div
      className={`nc-SectionHero relative  ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row py-8 px-2 space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:w-1/2 space-y-5  lg:space-y-7">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-7xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {!!btnText && (
            <ButtonPrimary onClick={clickHandler}>{btnText}</ButtonPrimary>
          )}
        </div>
        <div className="">
          <img className="w-full" src={rightImg} alt="Group-pic" />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;

import useWindowSize from "hooks/useWindowResize";
import React, { FC } from "react";
import backIcon from "../../images/icons/back-button.png";
import { IBookingData } from "./bookingTimelineTypes";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import UserBookingPaymentDetails from "./UserBookingPaymentDetails";



interface UserBookingDetailsProps {
    bookingDetails?: IBookingData;
    getBookingDetails?: () => {};
    getBookingsTimeline?: () => {};
}

const UserBookingDetails: FC<UserBookingDetailsProps> = ({
    bookingDetails,
    getBookingDetails,
    getBookingsTimeline
}) => {

    const { width } = useWindowSize();



    return (
        <>
            <div className="w-full flex flex-col lg:h-full sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                {width > 596 ? (
                    <h2 className="text-3xl lg:text-4xl font-semibold flex relative">
                        <Link to="./dashboard">
                            <img src={backIcon} className="w-10" />
                        </Link>
                        <span className="pl-4"></span>Reference No:{" "}
                        {bookingDetails && bookingDetails!.data?.ReferenceNo
                            ? bookingDetails!.data?.ReferenceNo
                            : "NA"}{" "}
                        <button
                            onClick={() => {
                                getBookingDetails!();
                                getBookingsTimeline!();
                            }}
                            className=" absolute right-0 cursor-pointer"
                        >
                            <FontAwesomeIcon icon={faRefresh} />
                        </button>
                    </h2>
                ) : (
                    <h3 className="text-3xl lg:text-4xl font-semibold flex">
                        <Link to="./dashboard">
                            <img src={backIcon} className="w-10" />
                        </Link>
                        <div>
                            <span className="pl-4"></span>Reference No:{" "}
                            {bookingDetails && bookingDetails!.data?.ReferenceNo
                                ? bookingDetails!.data?.ReferenceNo
                                : "NA"}{" "}
                            <button
                                onClick={() => {
                                    getBookingDetails!();
                                    getBookingsTimeline!();
                                }}
                                className="float-right"
                            >
                                <FontAwesomeIcon icon={faRefresh} />
                            </button>
                        </div>
                    </h3>
                )}
                <div className="mt-6 border border-neutral-200 dark:border-neutral-700  rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                    <div className="flex-1 p-5 flex justify-between space-x-5">
                        <div className="flex flex-col">
                            <span className="text-sm text-neutral-400">From</span>
                            <span className="mt-1.5 text-lg font-semibold">
                                {bookingDetails && bookingDetails!.data?.pol
                                    ? bookingDetails!.data?.pol.port_code
                                    : "NA"}
                            </span>
                            <span className="text-sm text-neutral-400">
                                {bookingDetails && bookingDetails!.data?.pol
                                    ? bookingDetails!.data?.pol.port_name
                                    : ""}
                                ,{" "}
                                {bookingDetails && bookingDetails!.data?.pol
                                    ? bookingDetails!.data?.pol.country
                                    : "NA"}{" "}
                                {bookingDetails && bookingDetails!.data?.pol
                                    ? bookingDetails!.data?.pol.corridors
                                    : ""}
                            </span>
                        </div>
                        {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                    </div>
                    <div className="flex-1 p-5 flex justify-between space-x-5">
                        <div className="flex flex-col">
                            <span className="text-sm text-neutral-400">To</span>

                            <span className="mt-1.5 text-lg font-semibold">
                                {bookingDetails && bookingDetails!.data?.pod
                                    ? bookingDetails!.data?.pod.port_code
                                    : "NA"}
                            </span>
                            <span className="text-sm text-neutral-400">
                                {bookingDetails && bookingDetails!.data?.pod
                                    ? bookingDetails!.data?.pod.port_name
                                    : ""}
                                ,{" "}
                                {bookingDetails && bookingDetails!.data?.pod
                                    ? bookingDetails!.data?.pod.country
                                    : "NA"}{" "}
                                {bookingDetails && bookingDetails!.data?.pod
                                    ? bookingDetails!.data?.pod.corridors
                                    : ""}
                            </span>
                        </div>
                        {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                    </div>
                </div>

                <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                    <div className="flex-1 p-5 flex justify-between space-x-5">
                        <div className="flex flex-col space-y-1">
                            <span className="text-md text-neutral-600">
                                Booking No:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.BookingNo
                                        ? bookingDetails!.data?.BookingNo
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Booking Date:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.DateOfBooking
                                        ? bookingDetails!.data?.DateOfBooking
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Shipment: <span className="font-semibold">{"FCL"}</span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Commodity:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.commodity
                                        ? bookingDetails!.data?.commodity
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                ETD:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.ETD
                                        ? bookingDetails!.data?.ETD
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Gate In Date:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.GateInDate
                                        ? bookingDetails!.data?.GateInDate
                                        : "NA"}
                                </span>
                            </span>
                        </div>
                        {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                    </div>
                    <div className="flex-1 p-5 flex justify space-x-5">
                        <div className="flex flex-col space-y-1">
                            <span className="text-md text-neutral-600">
                                Container Count:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.ContainerCount
                                        ? bookingDetails!.data?.ContainerCount
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Load Type:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.LoadType
                                        ? bookingDetails!.data?.LoadType
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Service Type:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.ServiceMode
                                        ? bookingDetails!.data?.ServiceMode
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Shipping Line / NVOCC:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.ShippingLineName
                                        ? bookingDetails!.data?.ShippingLineName
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                ETA:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.ETA
                                        ? bookingDetails!.data?.ETA
                                        : "NA"}
                                </span>
                            </span>
                            <span className="text-md text-neutral-600">
                                Buy Rate:{" "}
                                <span className="font-semibold">
                                    {bookingDetails && bookingDetails!.data?.sellcurrency
                                        ? bookingDetails!.data?.sellcurrency
                                        : "$"}{" "}
                                    {bookingDetails && bookingDetails!.data?.SellRate
                                        ? bookingDetails!.data?.SellRate
                                        : "NA"}
                                </span>
                            </span>
                        </div>
                        {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                    </div>

                </div>


                <UserBookingPaymentDetails
                    bookingDetails={bookingDetails!}
                />
            </div>
        </>
    )
}


export default UserBookingDetails;

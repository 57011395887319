import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import React, { useEffect } from "react";
import { useRedirectContext } from "utils/contexts/redirectContext";

const SearchBarWidget = () => {
  const { setWidget } = useRedirectContext();

  return (
    <div className="flex place-content-center">
      <HeroSearchForm />
    </div>
  );
};

export default SearchBarWidget;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Fri Mar 24 2023
 * Created at 11:16:07 AM
 * File Content:
 * *================*
 * *================*
 * Your Content Here
 * *================*
 * *================*
 */

import React, { FC, useEffect, useState } from "react";
import CommonLayout from "containers/AccountPage/CommonLayout";
import emptyicon from "../../images/transpost images/dashboard/emptyPng.png";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import apiPaths from "utils/paths/apiPaths";
import { secureGetRequest } from "utils/helpers/apiVerbs";
import Sidebar from "../components/Sidebar";
import BookingList from "../components/BookingList";
import { useEventContext } from "utils/contexts/eventContext";
import useWindowSize from "hooks/useWindowResize";
import BookingAnalytics from "../Analytics/BookingAnalytics";
import { Helmet } from "react-helmet";

export interface BookingCardProps {
  data: {
    ID?: string;
    DateOfBooking?: string;
    ContainerType?: string;
    ShippingLineName?: string;
    name?: string;
    ContainerCount?: string;
    BookingNo?: string;
    POL?: string;
    POD?: string;
    pol: {
      country: string;
      port_code: string;
      port_name: string;
    };
    pod: {
      country: string;
      port_code: string;
      port_name: string;
    };
    cs_status: {
      name: string;
      template: string;
    };
  };
}

const AllBookings = () => {
  const [bookingList, setbookingList] = useState<[]>([]);

  const { user, isLogin } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const { userState } = useUserDetails();
  const { setError } = useEventContext();

  const { width } = useWindowSize();

  const fetchAllBookings = async () => {
    setLoading(true);
    try {
      const allBookings = await secureGetRequest(apiPaths.bookings.user, {
        email: userState?.customer.email,
      });
      const result = allBookings.data;

      setbookingList(result.data);

      setLoading(false);
    } catch (err: any) {
      setError({
        ...err,
        error: true,
        message: "Error While Fetching Bookings!",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin.login) {
      fetchAllBookings();
    }
  }, [isLogin.login]);

  return (
    <CommonLayout>
      <Helmet>
        <title>Bookings | Transpost</title>
      </Helmet>
      <main className="mt-1 mb-24 lg:mb-20 flex flex-col lg:flex-row">
        {width > 996 && (
          <div className="block flex-grow mb-24 lg:mb-0">
            <div className="lg:sticky lg:top-24">
              <Sidebar />
            </div>
          </div>
        )}
        <div className="w-auto md:w-[80%] space-y-5 lg:pl-5 flex-shrink-0">
          <BookingList
            bookingList={bookingList}
            all={false}
            loading={loading}
          />
        </div>
      </main>
    </CommonLayout>
  );
};

export default AllBookings;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Mon Mar 06 2023
 * Updated on Mon Mar 06 2023
 * File Content:
 * *================*
 * *================*
 * Custom Clearance Page
 * *================*
 * *================*
 */

// React Default imports
import { FC, useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

//Custom Components
import HeroSearchForm from "./HeroSearchForm";

// Libraries, Contexts & Helpers
import { Helmet } from "react-helmet";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import useQueryParams from "hooks/useQueryParams";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { regularAPI, secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";
import { getMinMax } from "utils/helpers/minMax";
import { useQueryState } from "hooks/useQueryState";
import FilterSkeleton from "components/SkeletonComponents/FilterSkeleton";
import SectionGridFilterCard from "./SectionGridFilterCard";

export interface CustomClearanceSearchPageProps {
  className?: string;
}

const CustomClearanceSearchPage: FC<CustomClearanceSearchPageProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const history = useHistory();
  const params = useQueryParams(history.location.search);
  const [rates, setRates] = useState<[]>([]);
  const [searchParams] = useState(params);

  const [transportationType, setTransportationType] =
    useQueryState("transport_type");
  const [containerType, setContainerType] = useQueryState("container_type");
  const [stuffingType, setStuffingType] = useQueryState("stuffing_type");
  const [commodity, setCommodity] = useQueryState("commodity");

  const { setError } = useEventContext();

  const [showLoading, setShowLoading] = useState(false);

  const getTransportationRates = async () => {
    setShowLoading(true);
    setRates([]);
    await secureAPI
      .get(apiPaths.custom_clearance.custom_clearance.rates, {
        params: {
          transport_type: transportationType,
          container_type: containerType,
          stuffing_type: stuffingType,
        },
      })
      .then((response) => {
        console.log(response.data);

        setRates(response.data.data);
        setShowLoading(false);
      })
      .catch((err) => {
        setError({ ...err.response.data, show: true });
        setShowLoading(false);
      });
  };

  useEffect(() => {
    console.log("TransportationType", transportationType);
  }, [transportationType]);

  useEffect(() => {
    getTransportationRates();
  }, [location]);

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Transpost | Transportation Search Rates</title>
      </Helmet>
      <BgGlassmorphism />
      <div className="container pt-10 pb-24 lg:pt-16 lg:pb-32">
        <div
          className={`nc-SectionHeroArchivePage flex flex-col relative mt-40 mb-40 pt-10 pb-10 lg:pb-1 lg:pt-16`}
          data-nc-id="SectionHeroArchivePage"
        >
          <div className="relative ">
            <div className="z-max xl:-mt-40 xl:-mt-18 w-full">
              <HeroSearchForm
                autoFillForm={{
                  assign: "CustomClearance",
                  page: "customclearancepage",
                  feilds: {
                    transport_type: transportationType,
                    container_type: containerType,
                    stuffing_type: stuffingType,
                    commodity: commodity,
                  },
                }}
              />
            </div>

            <div
              className={`nc-SectionGridFilterCard ${className}`}
              data-nc-id="SectionGridFilterCard"
            >
              <SectionGridFilterCard
                rateList={rates}
                showLoading={showLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomClearanceSearchPage;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Mon Dec 10 2022
 * Created at 11:02:45 AM
 * File Content:
 * *================*
 * *================*
 * User context to handle signup related activities
 * *================*
 * *================*
 */


/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Mon Apr 10 2023
 * Updated at 11:03:41 AM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Mar 14 2023
 * Updated at 1:04:10 PM
 *
 */



import { createContext, useContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  auth,
  createUserDocumentFromAuth,
  signInWithGooglePopup,
} from "../firebase/firebase-config";
import { useUserDetails } from "./userDetailsContext";
import { postRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "./eventContext";
import { useLocalStorage } from "hooks/useLocalStorage";

export const getLocalLoginDetails = () => {
  let isLogin = localStorage.getItem("isLogin");
  if (isLogin) {
    return (isLogin = JSON.parse(localStorage.getItem("isLogin") || ""));
  } else {
    return JSON.stringify({ login: false });
  }
};

const userAuthContext = createContext();
export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState(null);

  const { setUserState, setAuthState } = useUserDetails();
  const { setError, setIsLoading } = useEventContext();

  const [isLoginState, setIsLoginState] = useLocalStorage(
    "isLogin",
    JSON.stringify({ login: false })
  );

  const [isLogin, setIsLogin] = useState(getLocalLoginDetails());

  useEffect(() => {
    setIsLoginState(isLogin);
  }, [isLogin]);

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  async function logOut() {
    setIsLogin({ "login": false });

    setUserState(null);
    setAuthState(null);
    return signOut(auth);
  }

  async function serverSignUp(data) {
    try {

      // calling server API to register user
      const signUp = await postRequest(apiPaths.auth.register, data);

      return { error: false, data: { ...signUp.data } };
    } catch (err) {
      setError({ ...err.response.data, show: true });

      return { error: true, err: err.response.data }
    }
  }

  // * Function to login to the backend server

  async function serverLogin(email, password, googleLogin = false) {
    try {

      // calling server login API with credentials
      const userLogin = await postRequest(apiPaths.auth.login, {
        email,
        password,
        googleLogin,
      });

      // setting user state context with local storage
      setUserState(userLogin.data);

      // setting user login status with local storage
      setIsLogin({ "login": true });

      // setting auth details & token with local storage
      setAuthState(userLogin.data.authDetails);

      return { show: false, data: userLogin.data };
    } catch (err) {
      setError({ ...err.response.data, show: true });

      return { show: true, err: err.response.data }
    }
  }

  async function checkAccount(email) {

    // calling check account API on our servers
    const check = await postRequest(apiPaths.auth.checkAccount, {
      email: email,
      signUp: true,
    });

    // checking with the data that the account exists or not
    if (!check.data.error) return true;

    // error account does not exists
    return false
  }

  async function commonGoogleLogin() {

    // get user details from the google pop up
    const user = await signInWithGooglePopup();


    // assigning google fetched data
    const uid = user.user.uid;
    const email = user.user.email;
    const name = user.user.displayName;


    // checking & creating user doc @ firebase
    await createUserDocumentFromAuth(user.user);

    const checkacc = await checkAccount(email)

    // checking if account exists on our servers
    if (checkacc)
      return await serverLogin(String(email), String(uid), true);


    // if account does not exists then registering the user
    let register = await serverSignUp({
      email: email,
      username: name,
      password: uid,
      gst_certificate: " ",
      pan_card: " ",
      google: true,
    });


    // any error whikle registering the user then return the server error
    if (register.error) return register;

    // finally login after registeration of the user
    return await serverLogin(String(email), String(uid), true);
  }

  // Sign In with your google account

  async function googleLogin() {
    setIsLoading(true);
    try {
      const signIn = await signInWithGooglePopup();

      if (signIn) {
        await serverLogin(
          String(signIn.user.email),
          String(signIn.user.uid),
          true
        );
        setIsLoading(false);

        return { error: false };
      }
    } catch (err) {
      setIsLoading(false);
      return { error: true, err };
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      if (currentuser) {
        setIsLogin({ "login": true });

      }
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{
        user,
        isLogin,
        setIsLogin,
        logIn,
        signUp,
        logOut,
        serverLogin,
        googleLogin,
        serverSignUp,
        commonGoogleLogin,
        checkAccount
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}

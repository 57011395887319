/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Tue Mar 14 2023
 * Updated at 7:18:45 PM
 *
 */

import Loader from "new_component/Loader";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { useHistory } from "react-router-dom";
import { useEventContext } from "utils/contexts/eventContext";

const RateCard = ({ data }: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const { userState } = useUserDetails();
  const { setError } = useEventContext();
  const { container_type, transport_type, stuffing_type } = data;

  const history = useHistory();

  const onClickHandler = () => {
    setIsLoading(true);
    userState &&
      secureAPI
        .post(apiPaths.custom_clearance.custom_clearance.store, {
          data: { ...data },
        })
        .then((response) => {
          setIsLoading(false);
          console.log("Response ", response);
          const fetchedData = response.data;

          history.push({
            pathname: "/custom-clearance/bookings/thank-you",
            state: {
              bookedData: fetchedData?.Booking,
              data: data,
            },
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError({ ...err.response.data, show: true });
        });
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 rounded-l-lg">
              Particular
            </th>
            <th scope="col" className="px-6 py-3">
              Rate (INR)
            </th>
            <th scope="col" className="px-6 py-3 rounded-r-lg">
              Remark
            </th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((item: any) => {
            const { rate, remark, particular } = item;
            return (
              <tr className="bg-white dark:bg-gray-800">
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {particular}
                </th>
                <td className="px-6 py-4">{rate}</td>
                <td className="px-6 py-4">{remark}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="py-3 ml-auto flex lg:pr-[12rem] bg-white">
        <ButtonPrimary onClick={onClickHandler} className="ml-auto w-[150px]">
          {isLoading ? <Loader className="mr-0 h-5 text-center" /> : "Book Now"}
        </ButtonPrimary>
      </div>
    </div>
  );
};

export default RateCard;

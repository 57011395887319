import React, { FC, useEffect, useRef, useState } from "react";


import __rates from "../../data/jsons/__rates.json";
import TestRate from "new_component/Test/TestRate";

export interface SectionGridFilterCardProps {
  className?: string;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const myRef = useRef<null | HTMLDivElement>(null);

  
  // TODO fix quicksearch whith help of backend

  const Loader = () => {
    return (
      <div className=" fixed z-max flex  inset-0 bg-neutral-200 align-center justify-center  items-center bg-opacity-10  backdrop-blur-sm border rounded dark:border-neutral-800  ">
        <div className="absolute flex justify-center align-center">
          <svg
            className={`animate-spin w-8 h-8 -ml-1 mr-3  `}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="3"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
    );
  };



  return (
    <div
      ref={myRef}
      className={`nc-SectionGridFilterCard`}
      data-nc-id="SectionGridFilterCard"
    >

    </div>
  );
};


export default SectionGridFilterCard;

import apiHeaders from "utils/paths/apiHeaders"

const getApiHeader = (env = "development") => {
    switch (env) {
        case "development":
            return {
                base: apiHeaders.developmentBaseUrl,
                secure: apiHeaders.developmentSecureBaseUrl
            }
        case "production":
            return {
                base: apiHeaders.baseUrl,
                secure: apiHeaders.secureBaseUrl
            }
        case "test":
            return {
                base: apiHeaders.locaBaselUrl,
                secure: apiHeaders.secureLocalBaseUrl
            }
        default:
            return {
                base: "",
                secure: ""
            }
    }
}

export default getApiHeader;
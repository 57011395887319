import React, { FC, useEffect, useState } from "react"

import BookingCard from "./BookingCard"
import { Link } from "react-router-dom";
import QuoteCard from "./QuoteCard";
import DashboardCardSekelton from "components/SkeletonComponents/DashboardCardSekelton";
import SearchCard from "./SearchCard";

interface BookingListProps {
    searchList?: [];
    all?: boolean;
    loading?: boolean;
}

const SearchList: FC<BookingListProps> = ({
    searchList,
    all,
    loading
}) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [typing, setTyping] = useState({
        name: "",
        typing: false,
        typingTimeout: 0,
    });
    return (<>
        <h2 className="font-bold " style={{ color: "#2aa996" }}>Recent Searches </h2>
        <div className=" w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 p-5 gap-6 bg-white dark:text-neutral-300  dark:bg-neutral-800 ">

            {/* <main className="mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row"> */}

            {loading && [1, 2, 3].map(() => {
                return <DashboardCardSekelton />
            })}

            {!loading && searchList && searchTerm.length > 0 ? null : searchList!.map((search: any, index) => {

                return (<div className="w-full h-full">{search.ocean_search && <SearchCard search={search.ocean_search} />}</div>)
            }).reverse()}

            {!loading && searchList?.length === 0 && "Search History Not Available"}



            {/* </main> */}
        </div>

    </>);
}

export default SearchList;
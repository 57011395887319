/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Wed Feb 15 2023
 * Updated on Wed Feb 15 2023
 * File Content:
 * *================*
 * *================*
 * Company details form component
 * *================*
 * *================*
 */

import React from "react";
import Input from "shared/Input/Input";

const CompanyDetailsForm = ({ showCompanyDetails, register }: any) => {
  return (
    <div
      className={`overflow-hidden transition-[max-height] duration-600 ease-in-out ${
        showCompanyDetails ? "max-h-full" : "max-h-0"
      } grid grid-cols-1 col-span-3 gap-4 md:grid-cols-2 lg:gap-6 lg:grid-cols-3 `}
    >
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Company Name
        </span>
        <Input
          type="text"
          placeholder="Enter your Company Name"
          className="mt-1"
          {...register("institution_name")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">Address</span>
        <Input
          type="text"
          placeholder="Company Address"
          className="mt-1"
          {...register("address")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">City</span>
        <Input
          type="text"
          placeholder="City"
          className="mt-1"
          {...register("city")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">State</span>
        <Input
          type="text"
          placeholder="State Name"
          className="mt-1"
          {...register("state")}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">Pincode</span>
        <Input
          type="text"
          placeholder="Pincode"
          className="mt-1"
          {...register("pincode")}
        />
      </label>
    </div>
  );
};

export default CompanyDetailsForm;

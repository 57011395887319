/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Mon Mar 06 2023
 * Updated at 1:07:57 PM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:05:08 AM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 12:10:15 PM
 *
 */

import React, { useEffect, useState, useMemo, FC, useContext } from "react";

import { useHistory } from "react-router-dom";

import LocationInput,{IPorts} from "components/HeroSearchForm/LocationInput";
import { FocusedInputShape } from "react-dates";
import moment from "moment";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import ShippingDetails from "new_component/ShippingDetails";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useEventContext } from "utils/contexts/eventContext";
import { regularAPI, secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";

import websitePaths from "utils/paths/websitePaths";
import { getRequest } from "utils/helpers/apiVerbs";
import { useRedirectContext } from "utils/contexts/redirectContext";
import { OceanRateList } from "containers/OceanSearchPage/OceanRateList";
import { log } from "console";

// DEFAULT DATA FOR ARCHIVE PAGE
const defaultDate = moment();

export interface Data {
  from_port: string;
  to_port: string;
  sl_date: moment.Moment | string | null;
  cargo_type: string | null;
}
export interface postDataProps {
  from_port: string;
  to_port: string;
  sl_date: moment.Moment | string | null | undefined;
  rates:number[]
}

export interface ExperiencesSearchFormProps {
  haveDefaultValue?: boolean;
}

export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface TimeRage {
  startTime: string;
  endTime: string;
}

export interface OceanRateSearchFormProps {
  haveDefaultValue?: boolean;
  autoFill: any;
  dataOfForm:(data:any)=>void
}


const cargoSize = (containerDetail: string) => {
  switch (containerDetail) {
    case "FCL,20'Standard":
      return "20gp";
    case "FCL,40'Standard":
      return "40gp";
    case "FCL,40'High Cube":
      return "40hc";
    case "FCL,20'Refrigerated":
      return "20rf";
    case "FCL,40'Refrigerated":
      return "40rf";
    case "FCL,45'High Cube":
      return "45hc";
    default:
      return null;
  }
};

const OceanRateSearchForm: FC<OceanRateSearchFormProps> = ({
  haveDefaultValue,
  autoFill,
  dataOfForm
}) => {
  const history = useHistory();
  const [dateValue, setdateValue] = useState<moment.Moment | null>(null);

  const [contDetails, setContDetails] = useState("FCL,20'Standard");
  const [convDate, setConvDate] = useState<null | string | undefined>(null);
  const [formInfoToRateSearchPage, setFormInfoToRateSearchPage] = useState<any>({})

  const [dateFocused, setDateFocused] = useState<boolean>(false);

  const selectedType = (value: React.SetStateAction<string>) => {
    setContDetails(value);
  };

  useEffect(() => {
    if (haveDefaultValue) {
      setdateValue(defaultDate);
      setConvDate(defaultDate.format());
    }
  }, []);

  // USE STATE
  const [pickUpInputValue, setPickUpInputValue] = useState<string>("");
  const [dropOffInputValue, setDropOffInputValue] = useState<string>("");
  const [pickUpsearchList, setPickUpSearchList] = useState<IPorts[]>([]);
  const [dropOffSearchList, setDropOffSearchList] = useState<IPorts[]>([]);
  const [showSelectLoading, setShowSelectLoading] = useState(false);


  const { makeRedirect } = useRedirectContext();

  const [fieldFocused, setFieldFocused] = useState<
    FocusedInputShape | "dropOffInput" | "startDate" | null
  >(null);

  const { userState } = useUserDetails();
  const { error, setIsLoading } = useEventContext();

  const [postData, setPostData] = useState<postDataProps>({
    from_port: pickUpInputValue,
    to_port: dropOffInputValue,
    sl_date: convDate, // converted date in string format
    rates:[]
  });

  // const request = useMemo(
  //   () => fetchPickUpList(pickUpInputValue),
  //   [pickUpInputValue]
  // );

  

  // USER EFFECT
  useEffect(() => {
    setPostData({ ...postData, from_port: pickUpInputValue });
    setFormInfoToRateSearchPage({...postData,from_port:postData.from_port})
  }, [pickUpInputValue]);

  useEffect(() => {
    setPostData({ ...postData, to_port: dropOffInputValue });
    setFormInfoToRateSearchPage({...postData,to_port:postData.to_port})
  }, [dropOffInputValue]);

  useEffect(() => {
    setPostData({ ...postData, sl_date: dateValue?.format("YYYY-MM-DD") });
    setFormInfoToRateSearchPage({...postData,sl_date:postData.sl_date})
    // setFormInfoToRateSearchPage({...postData,rates:postData.rates})
  }, [dateValue]);

  const fetchPickUpList = async (InputValue: string) => {
    setShowSelectLoading(true);

    try {
      const autoCompleteResult = await getRequest(
        apiPaths.ocean.location.ajax_autocomplete,
        { q: InputValue }
      );
      setPickUpSearchList(autoCompleteResult.data);
      setShowSelectLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  //find solution for following
  const fetchDropofflist = async (InputValue: string) => {
    setShowSelectLoading(true);

    try {
      const autoCompleteResult = await getRequest(
        apiPaths.ocean.location.ajax_autocomplete,
        { q: InputValue }
      );
      setDropOffSearchList(autoCompleteResult.data);
      setShowSelectLoading(false);
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (pickUpInputValue?.length !== 0) {
      fetchPickUpList(pickUpInputValue);
    }
  }, [pickUpInputValue]);

  useEffect(() => {
    if (dropOffInputValue?.length !== 0) {
      fetchDropofflist(dropOffInputValue);
    }
  }, [dropOffInputValue]);

  const pickUpHandler = (e: string) => {
    setPickUpInputValue(e);
  };

  const dropOffHandler = (e: string) => {
    setDropOffInputValue(e);
  };
  const submitHandler= () => {
    dataOfForm(formInfoToRateSearchPage);
    // console.log(postData);
  };

  const renderForm = () => {
    return (
      <div className="w-full">
        <form className="w-full relative mt-2 rounded-3xl shadow-md shadow-lg bg-white dark:bg-neutral-800">
          <div className=" flex flex-col lg:flex-row w-full rounded-full [ nc-divide-field ] ">
            <div className="relative flex flex-col lg:flex-row flex-grow  ">
              <LocationInput
                defaultValue={pickUpInputValue}
                onChange={(e:string) => {
                  pickUpHandler(e);
                }}
                onInputDone={() => setFieldFocused("dropOffInput")}
                placeHolder="Origin"
                desc="From"
                searchList={pickUpsearchList}
                showLoading={showSelectLoading}
              />
              <LocationInput
                defaultValue={dropOffInputValue}
                onChange={(e) => {
                  dropOffHandler(e);
                }}
                searchList={dropOffSearchList}
                onInputDone={() => setFieldFocused("startDate")}
                placeHolder="Destination"
                desc="To"
                autoFocus={fieldFocused === "dropOffInput"}
                showLoading={showSelectLoading}
              />

              {/* for single date selector */}
              <ExperiencesDateSingleInput
                defaultValue={dateValue}
                onChange={(date) => {
                  setdateValue(date);
                }}
                defaultFocus={dateFocused}
                onFocusChange={(focus: boolean) => {
                  setDateFocused(focus);
                }}
              />

              {/* shipping details - new component */}
              {/* BUTTON SUBMIT OF FORM */}
              <div className="px-4 py-3 flex items-center justify-center">
                {/* <ButtonSubmit  /> */}
                <button
                  onClick={submitHandler}
                  type="button"
                  className="h-14  lg:h-16 w-full lg:w-16 rounded-full bg-[#cd512f] hover:bg-[#218778] flex items-center justify-center text-neutral-50 focus:outline-none"
                >
                  <span className="mr-3 lg:hidden">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </form>{" "}
        
      </div>
    );
  };

  return renderForm();
};
export default OceanRateSearchForm;


/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Sheikh
 * Created on Tue May 19 2022
 * Created at 2:35:55 PM
 * File Content:
 * *================*
 * *================*
 * Sign In Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:04:52 AM
 *
 */


/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 21 2023
 * Updated at 2:36:38 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:19 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Feb 24 2023
 * Updated at 10:20:35 AM
 *
 */


import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "shared/Input/Input";
import Loader from "new_component/Loader";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory, useLocation } from "react-router-dom";
import { useUserDetails } from "utils/contexts/userDetailsContext";


import { useUserAuth } from "utils/contexts/userContext";
import * as Yup from "yup";
import { useEventContext } from "utils/contexts/eventContext";
import { useQueryState } from "hooks/useQueryState";
import { useRedirectContext } from "utils/contexts/redirectContext";
import { postRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import useQueryParams from "hooks/useQueryParams";


const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
});

export interface ChangePasswordProps {
    className?: string;
}

interface QueryParamsType {
    email?: string;
    token?: string;
}
export interface IChangePasswordForm {
    password: string;
    passwordConfirmation: string;
}

const loginSocials = [
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

const ChangePassword: FC<ChangePasswordProps> = ({ className = "" }) => {
    const [isLoading, setisLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const params: any = useQueryParams(location.search);
    const [email] = useQueryState("email");

    const [pageLoading, setPageLoading] = useState(false);

    const [showErr, setShowErr] = useState({
        show: false,
        msg: ""
    })

    const { setError } = useEventContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IChangePasswordForm>({
        resolver: yupResolver(validationSchema),
    });


    const checkValidity = async () => {
        setPageLoading(true);

        const checkvalidity: any = await postRequest(apiPaths.auth.checkValidity, { token: params.token });


        console.log(checkvalidity);
        if (checkvalidity.data.error!) {
            setShowErr({
                show: true,
                msg: "Link Expired has been expired!"
            })
        }


        setPageLoading(false);
    }


    const submitHandler: SubmitHandler<IChangePasswordForm> = async (data: IChangePasswordForm) => {
        setisLoading(true)
        try {
            const changePassword: any = await postRequest(apiPaths.auth.changePassword, { ...data, email: email,token: params.token })

            setisLoading(false);

            if (!changePassword.error!) history.push("./login");

            else throw changePassword;

        } catch (error: any) {
            setisLoading(false);
            const errorMsg = error.response.data.message;
            setError({ status: "error", show: true, message: errorMsg });
        }
    };

    useEffect(() => {
        checkValidity();
    }, [])

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Change Password || Transpost</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h4 className="my-5 flex pb-8 items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Change Password
                </h4>
                {showErr.show && <div className="mx-auto ">
                    <p className=" text-red-600 text-justify mx-[32vw] pb-5">
                        {showErr.msg}
                    </p>
                    <div className="flex items-center">
                        <ButtonPrimary className="m-auto" onClick={() => history.push("/forgot-pass")}>Get Password reset link again</ButtonPrimary>
                    </div>
                </div>}
                <div className="max-w-md mx-auto space-y-6 items-center">
                    {/* FORM */}
                    {pageLoading ? <Loader className="h-6 w-8" /> : showErr.show ? null : <form
                        onSubmit={handleSubmit(submitHandler)}
                        className="grid grid-cols-1 gap-6"
                    >
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                New Password

                            </span>

                            <Input
                                required
                                placeholder="Enter your Password"
                                type="password"
                                className="mt-1"
                                {...register("password")}
                            />
                            {errors.password && (
                                <div className="text-red-600 p-2 transition-all duration-500 ease-in-out transform bg-red">
                                    <p> {errors.password.message}</p>
                                </div>
                            )}
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                Re-enter Password

                            </span>

                            <Input
                                required
                                placeholder="Confirm your Password"
                                type="password"
                                className="mt-1"
                                {...register("passwordConfirmation")}
                            />
                            {errors.passwordConfirmation && (
                                <div className="text-red-600 p-2 transition-all duration-500 ease-in-out transform bg-red">
                                    <p> {errors.passwordConfirmation.message}</p>
                                </div>
                            )}
                        </label>

                        <ButtonPrimary type="submit">
                            {isLoading ? <Loader className="h-6 w-8" /> : "Change Password"}
                        </ButtonPrimary>
                    </form>}


                </div>
            </div>
        </div>
    );
};

export default ChangePassword;

import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import meatsvg from "../../images/svgs/meat.svg";
import dangersvg from "../../images/svgs/danger.svg";
import nonDangerSvg from "../../images/svgs/nondanger.svg";

const getCommodityObj = (item) => {
  switch (item.toUpperCase()) {
    case "REEFER":
      return {
        id: "Reefer",
        name: "REEFER",
        icon: "",
        className: "text-xl text-black la la-snowflake-o",
      };
    case "FAK":
      return {
        id: "FAK",
        name: "FREIGHT ALL KIND (FAK)",
        icon: "",
        className: "text-black text-xl las la-share-alt",
      };

    case "HAZ":
      return {
        id: "Haz",
        name: "HAZARDOUS (HAZ)",
        icon: dangersvg,
      };
    case "MEAT ONLY":
      return {
        id: "Meat Only",
        name: "MEAT ONLY",
        icon: meatsvg,
      };
    case "ALL":
      return {
        id: "ALL",
        name: "ALL",
        icon: nonDangerSvg,
      };
  }
};

const CommodityFilter = ({ filterCopy, commodityList, selectCommodity }) => {
  const [currentCommodity, setCurrentCommodity] = useState("ALL");
  const [currentList, setCurrentList] = useState([]);

  useEffect(() => {
    selectCommodity(currentCommodity);
  }, [currentCommodity]);

  useEffect(() => {
    setCurrentCommodity("ALL");
  }, [filterCopy]);

  useEffect(() => {
    const newList = commodityList.map((item) => {
      return getCommodityObj(item);
    });
    setCurrentList(newList);
  }, [commodityList]);

  return (
    <div className=" w-full px-4  ">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-90"}
                group inline-flex items-center rounded-md w-full sm:w-72 relative  px-3 py-2 border-2 text-base font-medium text-black hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className="">{getCommodityObj(currentCommodity).name}</span>
              <ChevronDownIcon
                className={`${open ? "" : "text-opacity-70"}
                  ml-2 h-5 w-5 text-orange-300 transition absolute right-2 duration-150 ease-in-out group-hover:text-opacity-80`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-50 w-screen max-w-sm px-4 mt-3  transform -translate-x-1/2 left-[10.8rem] md:left-[12rem] sm:px-0 lg:max-w-1xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-4 bg-white p-7 lg:grid-cols-1">
                    {currentList.length > 0 &&
                      currentList.map((item) => (
                        <div
                          key={item.name}
                          href={item.href}
                          className="-m-3 flex items-center rounded-lg p-2 cursor-pointer transition duration-150 ease-in-out hover:bg-gray-100 mb-1 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          onClick={() => {
                            close();
                            setCurrentCommodity(item.id);
                          }}
                        >
                          <div className="flex h-5 w-5 shrink-0 items-center justify-center text-white sm:h-6 sm:w-6">
                            {item.className ? (
                              <i className={`${item.className}  `}></i>
                            ) : (
                              <img
                                className={item.className}
                                src={item.icon}
                              ></img>
                            )}
                          </div>
                          <div className="ml-4">
                            <p className="text-sm font-medium text-gray-900">
                              {item.name}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CommodityFilter;

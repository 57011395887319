/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Sheikh
 * Created on Tue May 19 2022
 * Created at 2:35:55 PM
 * File Content:
 * *================*
 * *================*
 * Sign In Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:04:52 AM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 21 2023
 * Updated at 2:36:38 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:19 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Feb 24 2023
 * Updated at 10:20:35 AM
 *
 */

import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "shared/Input/Input";
import Loader from "new_component/Loader";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory, useLocation } from "react-router-dom";
import { useUserDetails } from "utils/contexts/userDetailsContext";

import { useUserAuth } from "utils/contexts/userContext";
import * as Yup from "yup";
import { useEventContext } from "utils/contexts/eventContext";
import { useQueryState } from "hooks/useQueryState";
import { useRedirectContext } from "utils/contexts/redirectContext";
import websitePaths from "utils/paths/websitePaths";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export interface PageLoginProps {
  className?: string;
}

export interface ILoginForm {
  email: string;
  password: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const [isLoading, setisLoading] = useState(false);
  const history = useHistory();

  const { redirect, makeRedirect } = useRedirectContext();

  const [autoLogin] = useQueryState("autoLogin");

  const { serverLogin, googleLogin, commonGoogleLogin, isLogin, checkAccount } =
    useUserAuth();
  const { setError, setIsLoading } = useEventContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(validationSchema),
  });

  const handleGoogleLogin = async () => {
    setIsLoading(true);

    try {
      let signUp = await commonGoogleLogin();

      if (signUp.error) throw signUp.err;

      if (!signUp.data.KYC) {
        history.push(
          "/update?email=" +
            signUp.data.customer.email +
            "&name=" +
            signUp.data.customer.contact_person +
            "&google=true"
        );
      } else {
        if (redirect.isRedirect) {
          makeRedirect();
        } else {
          history.push("./");
        }
      }
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);

      setError({
        ...err.response,
        show: true,
        status: "error",
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (autoLogin) handleGoogleLogin();
  }, [autoLogin]);

  useEffect(() => {
    if (isLogin.login) {
      if (redirect.isRedirect) {
        makeRedirect();
      } else {
        history.push("./");
      }
    }
  }, []);

  const submitHandler: SubmitHandler<ILoginForm> = async (data: ILoginForm) => {
    setisLoading(true);
    try {
      const check = await checkAccount(data.email);

      if (!check){
        throw {
          status: "error",
          message: "Account Not Found",
        };
      }

      const login = await serverLogin(data.email, data.password);

      setisLoading(false);

      if (login.show) throw login.err;
      if (!login.data.KYC) {
        history.push(
          "/update?email=" +
            login.data.customer.email +
            "&name=" +
            login.data.customer.contact_person +
            "&google=false"
        );
      } else {
        history.push("./");
      }
      if (redirect.isRedirect) makeRedirect();
    } catch (error: any) {
      setisLoading(false);
      console.log("ERROR", error);

      setError({ status:error.status, message:error.message, show: true });
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Sign In || Transpost || Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sign In
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <div
                key={index}
                // href={item.href}
                onClick={handleGoogleLogin}
                className="nc-will-change-transform flex w-full cursor-pointer rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </div>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form
            onSubmit={handleSubmit(submitHandler)}
            className="grid grid-cols-1 gap-6"
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>

              <Input
                required
                type="text"
                placeholder="example@example.com"
                className="mt-1"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-red-600 p-2">{errors.email.message}</p>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Forgot password?
                </Link>
              </span>

              <Input
                required
                placeholder="Enter your Password"
                type="password"
                className="mt-1"
                {...register("password")}
              />
              {errors.password && (
                <div className="text-red-600 p-2 transition-all duration-500 ease-in-out transform bg-red">
                  <p> {errors.password.message}</p>
                </div>
              )}
            </label>
            <ButtonPrimary type="submit">
              {isLoading ? <Loader className="h-6 w-8" /> : "Continue"}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Sign Up</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;

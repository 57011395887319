import { createContext, ReactNode, useContext, useState } from "react";

import { useHistory } from "react-router-dom";

type redirectProviderProps = {
  children: ReactNode;
};

type redirectType = {
  isRedirect?: Boolean;
  redirectUrl?: String;
  queryParams?: String;
};


const DEFAULT_REDIRECT = {
  isRedirect: false,
  redirectUrl: "",
  queryParams: "",
};

type redirectContext = {
  redirect: redirectType;
  setRedirect: React.Dispatch<React.SetStateAction<redirectType>>;
  makeRedirect: (newRediect?: redirectType) => void;
  widget: boolean;
  setWidget: React.Dispatch<React.SetStateAction<boolean>>;
};

const redirectContext = createContext({} as redirectContext);

export const useRedirectContext = () => {
  return useContext(redirectContext);
};

export const RedirectProvider = ({ children }: redirectProviderProps) => {
  const history = useHistory();

  const [redirect, setRedirect] = useState<redirectType>(DEFAULT_REDIRECT);
  const [widget, setWidget] = useState(false);
  const makeRedirect = (newRedirect: redirectType = DEFAULT_REDIRECT) => {
    history.replace({
      pathname: String(redirect?.redirectUrl!),
      search: String(redirect?.queryParams!),
      state: {},
    });

    setRedirect(newRedirect);
  };

  return (
    <redirectContext.Provider
      value={{
        redirect,
        widget,
        setWidget,
        setRedirect,
        makeRedirect,
      }}
    >
      {children}
    </redirectContext.Provider>
  );
};

import React, { useState } from "react";

import NcImage from "shared/NcImage/NcImage";
import ImageA from "../../images/transpost images/Warehousing/ImageA.jpg";
import ImageB from "../../images/transpost images/Warehousing/ImageB.jpg";
import ImageC from "../../images/transpost images/Warehousing/ImageC.jpg";
import ImageD from "../../images/transpost images/Warehousing/ImageD.jpg";
import ImageE from "../../images/transpost images/Warehousing/ImageE.jpg";
import ImageF from "../../images/transpost images/Warehousing/ImageF.jpg";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import ImageCard from "./ImageCard";



const list = [
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Bhiwandi",
    image: ImageA,
  },
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Chennai",
    image: ImageB,
  },
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Bangalore",
    image: ImageC,
  },
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Hyderabad",
    image: ImageD,
  },
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Howrah",
    image: ImageE,
  },
  {
    href: "https://www.storemart.ai/transpostcall?agentId=1290&cname=Masood&cmobile=8976768951&cemail=masoodahmed@transpost.co&hash=UG5teXZrcnNva01aSkJ6OWd2NzJDN2tzVU16QmdkVnVvd0hCTWYzY2FsS2lpNytiSXBlOXVCdzN1cWxKQk9MVQ==",
    city: "Gurgaon",
    image: ImageF,
  },
];

const ImageSection = () => {
  return (
    <div className="grid grid-cols-1 gap-5  lg:grid-cols-3 lg:gap-4 lg:content-center w-full">
      {list.map((item: any) => {
        return <ImageCard item={item} />;
      })}
    </div>
  );
};

export default ImageSection;

import React from "react";
import { Helmet } from "react-helmet";


const TermsAndConditionPage = () => {
  return (
    <div className="py-10 text-center container px-10">
           <Helmet>
        <title>Terms and contitions | Transpost</title>
      </Helmet>
      <h1 className="text-center font-semibold py-2">
        Terms of User Agreement (shipper/partner/buyer of service/Custom
        Broker/MSME)
      </h1>
      <p className="underline decoration-solid font-semibold pb-6">
        {" "}
        TERMS AND CONDITIONS
      </p>
      <p className="text-left whitespace-normal indent-0  leading-6 text-sm">
        Terms of user agreement (shipper/partner/buyer of service/micro, small &
        medium customs handling agents). Transpost is a digital platform that
        connects buyers and sellers of freight/logistic services. Transpost acts
        as an intermediary enabling the buyers and sellers and facilitates both
        the parties to conclude a contract with each other. However, there is no
        privity between the buyers and sellers Transpost acts as a digital
        platform only and never acts as a carrier, Non vessel operating common
        carrier (NVOCC), freight forwarder, shipbroker or any other logistics
        service provider or similar concept under any law, convention or
        regulation.
      </p>
      <p className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
        The domain name __Transpost.co including any sub-domains ("Site") is
        owned by Transpost
Technologies Pvt Ltd. (formerly known as Nebiar Technologies and Solutions Pvt Ltd), a Company
        incorporated under the Companies Act, 2013 under CIN -
        U63090MH2019PTC323650,holding PAN No. AAGCN4841B) and having its
        registered office at Suite 3A, Level 7, B Wing, Times Square, Andheri
        Kurla Road, Andheri (East), Mumbai 400059, Maharashtra, India.
      </p>
      <p className="text-left whitespace-normal indent-0  leading-6 text-sm pt-2">
        The objective of the website is to provide a digital platform for
        shipping, logistics and other allied services.
      </p>
      <p className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
        By clicking on the "I agree" button below, and by your continued use of
        this Site, you agree that you understand the terms and conditions of
        this Agreement, and that you agree to be legally bound by this Agreement
        (including the policies), as they may be modified by us from time to
        time. If you do not agree to the terms and conditions contained in this
        Agreement or our policies, please do not use the Site. Your continuance
        of usage of this Site is acknowledgment of acceptance of these terms and
        conditions.
      </p>
      <p className="text-left whitespace-normal indent-0  leading-6 text-sm pt-2">
        Under this Agreement we limit our liability to you, and you indemnify us
        against certain losses we may suffer, in connection with your use of the
        Services. These terms and conditions include all modifications up to and
        including today's date.
      </p>

      <ul className=" list-inside ">
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">1. Agreement:</span>
          this Agreement describes the terms and conditions that govern your use
          of the Site. This Agreement and our policies constitute the entire
          agreement in relation to our relationship with you and your use of the
          Site. Access to this Site is subject to your acceptance of this
          Agreement.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            2. Interpretation:
          </span>
          for the purpose of this Agreement, wherever the context so requires,
          the term:
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              "Agreement" is a reference to the terms and conditions contained
              in this Agreement and our policies relating to the use of the
              Site.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              "Buyer" shall mean any person who is willing to buy Services from
              the Sellers using the Site and more particularly Customs Handling
              Agents (Custom Broker) and Micro, Small & Medium Enterprises
              (MSME).
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              "Transpost" is trademark owned by Nebiar Technologies and
              Solutions Pvt Ltd, a Company incorporated under the Companies Act,
              2013 under CIN - U63090MH2019PTC323650, and having its registered
              office at Suite 3A, Level 7, B Wing, Times Square, Andheri Kurla
              Road, Andheri (East), Mumbai 400059.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">d.</span>
              "Force Majeure Event" shall mean any event that is beyond the
              reasonable control of Transpost and shall include, without
              limitation, sabotage, fire, flood, explosion, act of god, civil
              commotion, strikes, lockouts or industrial action of any kind,
              riots, insurrection, war, acts of government, computer hacking,
              civil disturbances, unauthorised access to computer data and
              storage device, computer crashes, virus attacks, breach of
              security and encryption, and any other similar events not within
              the reasonable control of Transpost and which Transpost is not
              able to overcome.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">e.</span>
              "Members" shall mean the members of the Site, including any Buyers
              and Sellers that have been approved to use the Site in accordance
              with the Agreement.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">f.</span>
              "Information" is a reference to any information uploaded or
              otherwise provided to the Site by any Member, including without
              limitation for the purposes of registration, listing of rates or
              quotes or bidding.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">g.</span>"person" shall mean a corporation,
              a partnership, a joint venture, a trust, an unincorporated
              organization, micro, small and medium enterprises and any other
              legal entity
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">h.</span>
              "Seller" shall mean any person, including carrier, insurance
              company, financial institution, or other professional vendor, who
              is willing to provide Services to the Buyers using the Site.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">i.</span>
              “Services" shall mean the services as more specifically displayed
              on our Site from time to time by Sellers, including without
              limitation shipping and logistic services, associated services
              including cargo Insurance, freight insurance, and trade finance
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">j.</span>A reference to "we", "our" and
              "company" is a reference to Transpost. The references to "you" and
              "your" refer collectively to you, and each of your employees,
              agents, contractors, related bodies corporate or other third
              parties who access this website on your behalf and acting with
              your express or implied authority.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            3. Modifications to this Agreement:
          </span>
          we may at any time modify any of the terms and conditions of this
          Agreement and/or the terms of our policies. Unless we notify you in
          writing or otherwise, modifications to this Agreement or our policies
          are effective upon being posted on the Site. You agree to check the
          Site regularly for modifications and other notices. Your continued use
          of the Site following a modification of this Agreement or a policy
          constitutes your acceptance of all modifications posted on this Site.
          If you do not agree to a modification, you may terminate this
          Agreement by notifying us in writing and/or discontinuing your use of
          the Site. If, after any changes to this Agreement or any of our
          policies, you continue to use our Site, you will be considered to have
          accepted the varied/modified terms and conditions as part of the
          Agreement.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            4. Eligibility:
          </span>
          use of the Site is available only to persons who can enter into
          legally binding contracts under applicable laws. Transpost reserves
          the right to terminate any Member’s membership and/or refuse to
          provide such Member with access to the Site if it is brought to
          Transpost’s notice or if it is discovered that such person is not
          eligible to use the Site.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">5. Trading:</span>
          Transpost provides a platform on which Buyers and Sellers can conclude
          contracts of carriage with each other (the "Contract") as follows:
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              the Seller agrees that each automated fixed-price quote generated
              by the Site (using the rates provided by a Seller) shall
              constitute a binding offer between Seller and the Buyer for the
              relevant Services;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              where the Buyer accepts any automated fixed-price quote, the Buyer
              agrees that this constitutes acceptance of the Seller's binding
              offer;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              Transpost acts as a platform only at all times and you acknowledge
              and agree that Transpost shall not be a party to the Contract
              between the buyer and the seller. For the avoidance of doubt,
              Transpost does not act as principal or as a (direct, indirect or
              contractual) freight forwarder, customs broker, insurer or
              financial institution. The Buyers and Sellers acknowledge that
              Transpost does not validate, verify or otherwise authenticate,
              certify, confirm or endorse the Members or the Information or
              documents they provide or issue, nor does it endorse or guarantee
              the provision of the Services of Members, or make any guarantees
              as to the performance of, or payment for, the Services by a Member
              and the buyer shall use reasonable judgement and market
              intelligence to enter into the contract. Transpost will not
              provide and/or issue any means of transport, containers, bills of
              lading, waybills, consignment notes, customs declarations or any
              other documents relating to the provision of the Services of
              Members. Transpost is only a one roof IT platform for the Members
              to view the information/rates/services provided by the Members,
              without authenticating/validating the information provided. The
              Buyers acknowledge and agree that Transpost have no control over
              the adequacy and/or accuracy of the Services or the use, safety
              and legality of the cargo to be shipped. Transpost cannot ensure
              and do not guarantee that a Member will actually complete a
              Contract or act lawfully in using the Site, and each Member is
              responsible for ensuring that it complies with its obligations to
              any other Member under any Contract. Further, Transpost shall not
              be responsible for any delays or non-fulfilment of terms of the
              contract between the Members/buyers and you/sellers. If you do not
              comply with your obligations, you may become liable to that
              Member. Likewise, if another Member breaches an obligation to you,
              you are responsible for enforcing any rights that you may have
              against that Member. Transpost shall not be held responsible for
              non-fulfilment of obligations between the Member/buyers/
              you/sellers etc. Furthermore, you must ensure that you are aware
              of the relevant laws applicable to you in purchasing and/or
              selling Services and providing the cargo to be shipped. Unless we
              advise otherwise on the Site, we do not offer any assurance,
              guarantee, insurance or purchaser protection.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            6. Membership:
          </span>
          Transpost reserve the right to change the Site and our services and
          suspend, terminate or restrict your membership at any time (including
          your access to the Site), in accordance with the terms of this
          Agreement or our policies. We also reserve right to cancel or suspend
          the membership without any notice in case the user charges for usage
          of Transpost platform are not received by Transpost within the
          timelines as stipulated from time to time. If we change the Site and
          our services, or suspend or terminate your membership, you may be
          prevented from accessing the Site and our services either temporarily
          or permanently. If your membership is suspended or terminated, you are
          prohibited from applying for a new membership account with us. Your
          account (including feedback and any associated user identification)
          may not be dealt with in any way by any third parties. You must not
          allow third parties to use your account and you must not transfer or
          sell your account to a third party. You shall be responsible for
          maintaining the confidentiality and security of the password and for
          all activities that occur in and through your account. As a Member you
          are responsible for maintaining control of your account and for any
          activity that occurs on your account. To maintain the safety of the
          Site and our services, you must report any unauthorized use of your
          account and associated user identification to us as soon as possible
          after becoming aware of the unauthorized use. Any response from
          Transpost regarding this will be sent to the e-mail address provided
          on registration. You shall ensure that you exit from the account at
          the end of each session. Use of another user’s account for availing
          the Services of the Site is expressly prohibited. You hereby agree
          that if you provide any information that is untrue, inaccurate, not
          current or incomplete, (or becomes untrue, inaccurate, not current or
          incomplete) or if Transpost has reasonable grounds to suspect that
          such information is untrue, inaccurate, not current, incomplete, or
          not in accordance with this Agreement, Transpost shall have the right
          to indefinitely suspend or terminate or block access of your
          membership on the Site and refuse to provide you with access to the
          Site.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">7. External Weblinks:</span>
          Transpost on its website provides the customer with an option at to avail
additional services via weblinks/API (Application Programming Interface) which re-direct the
customer to external websites which are not a part of Transpost. The customer on his own risk and
volition shall use the services provided by such external websites. Transpost shall not be liable for
any loss or liability incurred in the services availed from such external websites.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">8. Records:</span>
          We may make a record of all of Contracts formed. We are not liable for
          any loss of data, technical or otherwise, Information or other
          particulars supplied by you, due the reasons beyond its control like
          corruption of data or delay or failure to perform as a result of a
          force majeure event.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
             Buyer terms: the following terms shall be additionally applicable
            to Buyers:
          </span>
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              Where you enter into a Contract with a Seller in accordance with
              clause 5, you agree to be bound by the Seller's standard terms and
              conditions applicable to that mode of transport (or any other
              terms as otherwise agreed to between you and the Seller).
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              Subject to any legal right to the contrary, you are obliged to
              complete any Contract.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              When requesting a quote, you must describe the requirements for
              the Services you wish to purchase, fully and accurately. Your
              listing must only include content relevant to that quote.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">d.</span>
              You must be legally able to transport the items for which you are
              requesting the quote.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">e.</span>
              You hereby confirm that Transpost neither confirms nor verifies
              the quotes given by you and/or the Seller.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">f.</span>
              You hereby acknowledge that Transpost will not be a party to any
              Contract and the Seller will be solely responsible for performing
              all obligations under the same.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">g.</span>
              If the Services are not received within the duration indicated by
              the Seller or are not in accordance with the specifications or has
              deficiency in services rendered, Transpost shall, under no
              circumstance, be held liable or responsible for the same. Any
              dispute relating to the delivery of Services shall be resolved
              directly between You and the Seller.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">h.</span>
              Transpost offers no guarantee or warranty that:
              <ul className="ml-8 list-item">
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">(a).</span>
                  There would be a satisfactory response or any response at all,
                  once you provide your requirements for any quote;
                </li>
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">(b).</span>
                  The Seller has the necessary licenses for providing the
                  Services under all applicable laws; or
                </li>
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">(c).</span>
                  The Services provided by the Seller will be provided skill
                  fully and competently in a workman like manner.
                </li>
              </ul>
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">i.</span>
              You shall be solely responsible for undertaking appropriate and
              effective due diligence and related analysis before entering into
              any Contract. You acknowledge and agree that Transpost has not
              carried out any background checks, due deligence or other
              verification relating to the Seller, and that Transpost does not
              guarantee/warranty the quality of Services being provided to you.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">j.</span>
              You are solely responsible for coordinating and communicating with
              the Seller for the performance of the relevant Services, in
              accordance with this Agreement
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">k.</span>
              Your acceptance of this Agreement constitutes consent for
              Transpost to act as only a booking agent on your behalf for the
              limited purpose indicated in this Agreement.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            9. Buyer’s financial terms:
          </span>
          You shall be required to make payments to the Seller directly for the
          Services provided by a Seller under any Contract and to us for
          commission for your use of the platform. Such payment will take place
          in accordance with the terms and payment instructions stated on the
          invoice.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            10. Transpost’s service charges:
          </span>
          You shall be required to make the payment for usage of this platform
          to Transpost directly within the timelines stipulated, failing which
          Transpost shall have right to cancel the membership without any
          liability on Transpost.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">11. Fraud: </span>
          without limiting any remedies available to us at law, in equity or
          under this Agreement, we may, in our sole discretion, suspend or
          terminate your membership if we reasonably suspect or believe, or are
          informed by a government agency or quasi-judicial authority that you
          have engaged in or may engage in fraudulent or illegal activity using,
          or otherwise in connection with your use of our Site and you will have
          no claim whatsoever against us in respect of any suspension or
          termination of your membership under this clause 13.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            12. Your Information:
          </span>
          you are solely responsible for the Information you provide and by
          using the Site, you agree that you will keep your Information up to
          date and information and/or contents posted by you are in accordance
          with applicable law. We are not responsible for the content of the
          Information you provide. We may remove your Information from the Site
          at our sole discretion. You must not provide Information that is
          potentially or actually harmful to us, the Site or any third party
          and/or in contravention of any laws, rules and regulation. In this
          clause 14 "harm" includes, but is not limited to, pure economic loss
          that we may or do suffer and you agree to indemnify us from third
          party claims and/or any loss/damage/cost in relation to your use of
          the site or any infringement or default/commission/omission in
          performance of any obligations under the contract entered into
          pursuant to usage of this site and/or any unlawful act.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            13. Cancellations:
          </span>
          in the event a shipment is cancelled by the you/buyer after a booking
          is accepted by the seller ( shipping line), all penalties and
          applicable charges applicable will be borne by the buyer without any
          liability on Transpost. For such cancellation, you shall keep
          Transpost indemnified at all times from any such charges or claims. In
          the event the booking cancelation charges are not paid up by the
          buyer, Transpost shall have a right to cancel a Member's membership
          and take necessary legal action to recover any collectible
          outstandings.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">14. Feedback:</span>
          we have a member-initiated feedback system to provide evaluations of
          the Members that the party has dealt with. Any feedback provided by
          you, or third parties in relation to your activities on the Site is
          owned by us. You may use the feedback only for the purposes of
          conducting your activities on our Site and only while you remain a
          Member. You must not use your feedback on this Site in any other venue
          or for any other purpose. You must not do or omit to do anything that
          will or is likely to undermine the integrity of the feedback system.
          We can suspend or terminate your membership if we are concerned by any
          feedback about you. Feedback must not be defamatory and must not be
          false or misleading. You further represent and warrant that while
          posting feedback on the Site you shall not use any offensive,
          libellous, derogatory, hateful or racially or ethnically objectionable
          language, or is obscene, pornographic, or constitutes an indecent
          representation of any person.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">15. Disputes:</span>
          we do not warrant that any Contract will be free of dispute. We are
          not responsible for resolving disputes between the buyer & seller and
          also other Members and we will not resolve or attempt to resolve
          disputes . You and other Members are solely responsible to each other
          for the obligations under the Contract. You agree that if you have a
          dispute with one or more Members, all disputes must be resolved
          between you and the relevant Member(s) without involving Transpost.
          Under this Agreement, you acknowledge that if we are contacted by a
          Member that claims to have a dispute with you regarding the Services,
          you agree hereby expressly that we can release your contact
          information to that Member and/or any other authorities and person at
          our sole discretion in order for the dispute between you and the
          Member to be resolved. You hereby irrevocably and unconditionally
          release us from all claims, demands and damages (actual and
          consequential) arising out of or connected with the dispute or our
          release of your contact information and subsequent contract entered
          between you and other member.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            16. Communications:
          </span>
          we will send you communications and notices regarding your account on
          the platform and any Contract concluded by you. These communications
          are not an endorsement, guarantee or a legitimization of any such
          Contract. You are responsible for completing all Contracts you are
          party to, which includes complying with all relevant legal obligations
          including without limitation your legal obligations in relation to the
          purchase of the Services, the shipping of any cargo, having all
          applicable licenses as may be required for discharge of obligations
          under the contract.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            17. Unauthorized activity:
          </span>
          you must not:
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              Modify, adapt, otherwise alter the platform or share your login
              with any third party;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              Interfere, in any way, with the listings of other Members;
            </li>

            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              Provide false, misleading, deceptive or otherwise inaccurate
              information;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">d.</span>
              Act fraudulently;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">e.</span>
              Engage in any activities that may result in the price of the
              Services being manipulated;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">f.</span>
              Copy, reproduce, create derivative works or publicly display any
              content contained in the Site (with the exception of your
              information);
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">g.</span>
              Infringe any third party’s copyright, patent, trademarks,
              confidential information, or any other proprietary/intellectual
              property rights such party may have;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">h.</span>
              Provide or facilitate another person's access to, reproduce, or
              otherwise make available, the Site or any part of the Site, to
              another person;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">i.</span>
              Create liability for us or cause us to lose (in whole or in part)
              the services of our internet service providers or other suppliers;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">j.</span>
              Take any action that will result in an unreasonable or
              disproportionately large load being imposed on our infrastructure;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">k.</span>
              Directly or indirectly link to or include descriptions of Services
              that:
              <ul className="ml-8 list-item">
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">i.</span>
                  There would be a satisfactory response or any response at all,
                  once you provide your requirements for any quote;
                </li>
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">ii.</span>
                  The Seller has the necessary licenses for providing the
                  Services under all applicable laws; or
                </li>
                <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
                  <span className="mr-3">iii.</span>
                  The Services provided by the Seller will be provided skill
                  fully and competently in a workman like manner.
                </li>
              </ul>
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">l.</span>
              List any item on the Site, or complete any Contract that was
              initiated using the Site, if payment of the listing fee or
              commission fee is likely to cause us to violate an applicable law,
              statute or regulation, this Agreement or any of our policies;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">m.</span>. Knowingly being involved in the
              shipping of counterfeit, stolen items and/or restricted and/or
              forbidden cargo;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">n.</span>
              Violate any applicable law, statute, regulation or other
              applicable law or policy (including but not limited to those
              governing export and import control, consumer protection, unfair
              competition, and criminal law);
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">o.</span>
              Refers to any website or URL that, in Transpost's sole discretion,
              contains material that is inappropriate for the Site or any other
              website, contains content that would be prohibited or violates the
              letter or spirit of this Agreement;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">p.</span>
              Use any robot, spider, other automatic device or manual process to
              monitor or copy the Site, or content contained in the Site;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">q.</span>
              Remove, disable, defeat, or otherwise circumvent any functionality
              of the Site (including a requirement to use a user name and
              password or other authentication method, or other security
              protection or preventative measures against unauthorized use);
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">r.</span>
              Damage, disrupt, disable or otherwise impair the operation of the
              Site or interfere with the use of the Site by other persons,
              including introduce a virus, trojan horse or other malware into
              the Site intended to cause damage, breach, malfunction, or other
              undesired effects to the Site, or carry out a denial of service
              attack;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">s.</span>Collude with, enter an agreement
              with or correspond with another Member or other person to avoid
              paying us commission;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">t.</span>
              Knowingly avoid our commission; and we may terminate your
              membership should you do, or we suspect you may do, any of the
              above.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">18. License: </span>
          to enable us to use the Information, you agree to grant us an
          irrevocable, non-exclusive, perpetual, royalty-free, sub-licensable,
          worldwide license to do all acts comprised in the copyright and to
          exercise the publicity and database rights you have in the
          information, in any media, with respect to the Information. We will
          only use the Information in accordance with our privacy policy.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            20. Non-identifiable user data:
          </span>
          we shall be entitled to obtain and aggregate technical and other data
          about your use of the Site and the Services that are non-personally
          identifiable with respect to you, whether as a Buyer or a Seller
          ("Aggregated Anonymous Data"). We shall further be entitled to use the
          aggregated anonymous data to improve, support and operate the Site and
          the Services, provided that you will not be identified as the source
          of any Aggregated Anonymous Data and no personal information will be
          collected as a part of Aggregate Anonymous Data.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">21. Privacy:</span>
          we will only use your personal information as set out in our privacy
          policy
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            22. Website terms of use:
          </span>
          your use of our Site is subject to and governed by our site terms of
          use
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            23. Intellectual property:
          </span>
           The Site includes material which may be protected by copyright,
          trademark, or other intellectual property laws in India or elsewhere
          and all rights in relation to such material are owned by or licensed
          to us unless otherwise stated. You hereby acknowledge that the Site
          and our services constitute original works and have been developed,
          compiled, prepared, revised, selected, and arranged by Transpost
          through the application of methods and standards of judgment developed
          and applied through the expenditure of substantial time, effort, and
          money and constitutes valuable intellectual property of Transpost and
          such others. You thereby agree to protect the proprietary rights of
          Transpost during and after the term of this Agreement. You may not
          selectively download portions of the Site without retaining the
          copyright notices. You may download material from the Site only for
          the purpose intended by this Agreement. Any infringement shall lead to
          appropriate legal proceedings against you at appropriate forum for
          seeking all available remedies under the applicable laws.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">24. Warranty:</span>
          you warrant to us that:
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              For body corporates: you have the right and authority to bind the
              body corporate to the terms and conditions of this Agreement;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              All of the information you have provided to us in respect of this
              Agreement is true, accurate and complete and we may rely on this
              information;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              Your membership details are correct, and you will update your
              membership details if they change;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">d.</span>
              In respect of any quote requested by a Buyer and posted on the
              Site, neither the Buyer or Seller will not approach the other to
              make any offer or otherwise negotiate that quote unless they do so
              via the Site;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">e.</span>
              You will not post defamatory comments on the Site;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">f.</span>
              You have made your own investigation and performed sufficient due
              diligence on the Site to fully inform yourself about the functions
              and operation of the Site and have not relied on any
              representations made by us or any other person in relation to the
              Site;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">g.</span>
              You will not infringe or permit or assist any other person to
              infringe the intellectual property rights owned by us from time to
              time and use your best efforts to protect and prevent such
              intellectual property rights from such infringement by
              unauthorized third parties; and
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">h.</span>
              You will comply with all of your obligations under all applicable
              laws.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            25. Authority:
          </span>
          if you accept this Agreement, or obtain access to the Site, on behalf
          of an employer, client or principal, you warrant that you have the
          express or implied authority to accept this Agreement on that person's
          behalf and agree that this Agreement is also binding on that person.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">21. Privacy:</span>
          we will only use your personal information as set out in our privacy
          policy
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">26. Access: </span>
          notwithstanding your acceptance of this Agreement, we are under no
          obligation to provide access to the Site or guarantee its availability
          or operation in any form and may suspend or terminate access to the
          Site or make changes to the Site at any time without notice to you.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            27. Access method:
          </span>
          we may require you to use a user name and password or other
          authentication or security method in order to access and use the Site.
          You must keep your user name and password secure at all times.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            28. No agency:
          </span>
          entry into this Agreement does not give rise to an agency,
          partnership, joint venture, employee-employer or other similar
          relationship in whatsoever nature. You do not have any other authority
          to bind us or our related entities or affiliates in any way.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            29. Liability:
          </span>
          you are responsible for all access and use of the Site that occurs
          through the use of your user name and password. Each act or omission
          in the course of accessing the Site is deemed to have been performed
          by you, regardless of whether that act or omission was undertaken by
          you.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">30. Notice:</span>
          you must immediately notify us if you become aware of any suspected or
          actual:
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              Unauthorized activity;
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              Prohibited disclosure or use of your user name and password, or
              any confidential information; or
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              Existence or exploitation of any security vulnerability, weakness,
              or threat on the Site.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">d.</span>
              You must take all steps reasonably within your power to mitigate,
              prevent or stop any such conduct and provide all assistance
              reasonably requested by us in relation to any actions or
              proceedings we may take against any person for any such conduct.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            31. Termination:
          </span>
          you may terminate your account with us by providing us with 30-days
          written notice of your decision to terminate the use of the Site. We
          will disable your login credential accordingly
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            32. Disclaimers:
          </span>
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              To the maximum extent permitted by law, we make no representation
              or warranty (either express, implied or statutory) about: the
              Site; the accuracy, veracity, timeliness or content of any
              information or data contained on the Site and any other associated
              website; continued access to or use of the Site; availability of
              the Site; backup or business continuity in respect of the Site;
              the merchantability, fitness, quality or suitability for a
              particular purpose of the Site or data located on the Site; any
              supply agreements, panel agreements or any guarantee of business
              with us; or the Site or data being error or malicious code free,
              secure, confidential or performing at any particular standard or
              having any particular function.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              We provide the service on an "as is", "with all faults" and "as
              available" basis, and do not warrant that it will be available for
              use or at all.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            33. Limitation of liability:
          </span>
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>. Transpost will not be liable for
              damage in any form (including, but not limited to, loss of data,
              indirect damages, consequential loss, pure economic loss, loss of
              opportunity, loss of revenue or profit, wrongful death, personal
              injury, property damage, penalties and fines, costs or any other
              loss) sustained by you, your servants, agents, employees, insurers
              and third parties for whom you are liable pursuant to any legal
              relationship.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              Notwithstanding clause 34(a) Transpost shall not be liable for any
              loss or damage of, and for any costs incurred by a user, carriers,
              owners and charterers of means of transport (including – but not
              limited to – vessels) or other third parties in relation to any
              Contract and/or Services performed as Transport acts as
              intermediate platform only.
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            34. Indemnity:
          </span>
          <ul className="ml-8 list-item">
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">a.</span>
              You shall immediately and fully indemnify Transpost in the event
              that another Member or a third party claims any loss or damage or
              costs in relation to your use of the Site, any breach or
              infringement of any rights or terms in this Agreement and any
              other default, unlawful act or omission on your part. This
              indemnification shall also include reasonable attorney’s fees and
              any other legal costs incurred by Transpost or its insurer,
              employee, agent or servant for defending any such claim from
              another user or a third party.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">b.</span>
              Without limiting the preceding paragraph, you indemnify us, our
              officers, employees and agents from and against any claim,
              liability, loss, damage, costs (including the cost of any
              settlement and legal costs and expenses on a solicitor and own
              client basis), and expenses incurred by virtue of any breaches of
              a third party's intellectual property rights as a direct or
              indirect consequence of your use of the Site or any software or
              other intellectual property licensed to you under this Agreement.
            </li>
            <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
              <span className="mr-3">c.</span>
              This clause survives the termination of this Agreement
            </li>
          </ul>
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            35. Applicable law:
          </span>
          These terms and conditions, the Agreement, and the legal relationship
          between Transpost and you shall exclusively be governed by laws of
          India.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            36. Arbitration:
          </span>
          All disputes arising out of or in connection with the Agreement and
          the legal relationship between Transpost and you, shall be referred to
          arbitration in Mumbai in accordance with the Indian Arbitration and
          Conciliation Act 1996, as amended from time to time. The reference
          shall be to a sole arbitrator. The language of the arbitration shall
          be English. For the sake of clarity, the Buyer and the Seller agrees
          that this is an IT platform and therefore Transpost shall not be made
          party to any disputes between Seller and Buyer or amongst the Members.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">
            37. Severance:{" "}
          </span>
          where any provision (or part) of this Agreement is held to be illegal
          or unenforceable, it may be severed, and shall in no way affect or
          prejudice the enforceability of the other terms or conditions of this
          Agreement.
        </li>
        <li className="text-left whitespace-normal indent-0 leading-6 text-sm pt-2">
          <span className="font-semibold whitespace-normal mr-2">38. Contact:</span>
          any notices, complaints or queries in relation to the Site or this
          Agreement must be directed to:
        </li>
        <ul className="list-none ml-8">
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Transpost Technologies Pvt Ltd,
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Registered office at Suite 3A,
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Level 7, B Wing, Times Square,
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Andheri Kurla Road, Andheri (East),
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Mumbai 400059, Maharashtra,
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            India.
          </li>
          <li className="text-left whitespace-normal indent-2 leading-6 text-sm pt-2">
            Email id : __________________
          </li>
        </ul>
      </ul>
    </div>
  );
};

export default TermsAndConditionPage;

import React from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHeroArchivePage from "./SectionHeroArchivePage";

const CustomClearancePage = () => {
  return (
    <div className={`nc-PageContact overflow-hidden`} data-nc-id="PageContact">
      <Helmet>
        <title>Custom Clearance | Transpost</title>
      </Helmet>
      <div className="container">
        <SectionHeroArchivePage currentPage={"Stays"} currentTab={"Stays"} />

        {/* OTHER SECTIONS */}
        <div className="container">
          <SectionSubscribe2
            className="py-24 lg:py-32"
            text="You can consult with us on the journey to deliver your product to the buyer, along with assistance in Custom Clearance."
          />
        </div>
      </div>
    </div>
  );
};

export default CustomClearancePage;

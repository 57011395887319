import React, { useEffect, useState } from "react";

import MyRouter from "routers/index";
import { QuoteListProvider } from "utils/contexts/quoteListContext";
import { UserDetailsProvider } from "utils/contexts/userDetailsContext";
import { CargoDetailsProvider } from "utils/contexts/cargoContext";
import { UserAuthContextProvider } from "utils/contexts/userContext";

import {
  EventDetailsProvider,
  useEventContext,
} from "utils/contexts/eventContext";
import {
  RedirectProvider,
  useRedirectContext,
} from "utils/contexts/redirectContext";
import SplashScreen from "containers/SplashScreen";
import { useQueryState } from "hooks/useQueryState";

// Test
import Pusher from "pusher-js";

function App() {
  const [isSplash, setIsSplash] = useState(true);
  const [wid, setWid] = useQueryState("widget");

  // TEST
  const [newData, setNewData] = useState<any>([]);

  useEffect(() => {
    setTimeout(() => {
      setIsSplash(false);
    }, 3000);
  }, []);

  // useEffect(() => {
  //   console.log("RENDER");
  //   const pusher = new Pusher("efdc5f058ba23aa111ac", {
  //     cluster: "ap2",
  //   });

  //   const channel = pusher.subscribe("booking");
  //   channel.bind("BookingStageEntered", (data: any) => {
  //     console.log("DATA", data);
  //     setNewData([...newData, data.message]);
  //   });

  //   return () => pusher.unsubscribe("bookings");
  // }, [newData]);


  return (
    <EventDetailsProvider>
      <UserDetailsProvider>
        <UserAuthContextProvider>
          <RedirectProvider>
            <QuoteListProvider>
              <CargoDetailsProvider>
                <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                  {wid ? (
                    <MyRouter />
                  ) : isSplash ? (
                    <SplashScreen />
                  ) : (
                    <MyRouter />
                  )}
                </div>
              </CargoDetailsProvider>
            </QuoteListProvider>
          </RedirectProvider>
        </UserAuthContextProvider>
      </UserDetailsProvider>
    </EventDetailsProvider>
  );
}

export default App;

import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

type EventDetailsProvider = {
  children: ReactNode;
};

type Error = {
  status: string;
  show: boolean;
  message: string;
};

type eventListContext = {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: Error;
  setError: React.Dispatch<React.SetStateAction<Error>>;
};
const eventDetailsContext = createContext({} as eventListContext);

export const useEventContext = () => {
  return useContext(eventDetailsContext);
};

export const EventDetailsProvider = ({ children }: EventDetailsProvider) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>({
    status: "",
    show: false,
    message: "",
  });

  return (
    <eventDetailsContext.Provider
      value={{
        isLoading,
        setIsLoading,
        error,
        setError,
      }}
    >
      {children}
    </eventDetailsContext.Provider>
  );
};

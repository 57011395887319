/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Thu Mar 02 2023
 * Created at 12:12:30 PM
 * File Content:
 * *================*
 * *================*
 * Filter SKeleton COmponent
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 6:00:57 PM
 *
 */



import { StarIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";

export interface BookingDetailsSkeletonProps {
    className?: string;

}

const BookingDetailsSkeleton: FC<BookingDetailsSkeletonProps> = ({
    className = "",
}) => {
    return (
        <div role="status" className="animate-pulse">
            <div className="flex flex-col xl:pl-5 xl:ml-8 mb-4 justify-center ">

                <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
                        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                            <h2 className="text-3xl lg:text-4xl font-semibold">
                                <div className="h-3.5 bg-gray-300 rounded-full dark:bg-black-300 w-36 mb-2.5" style={{ width: "12rem" }}></div>
                            </h2>
                            <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                                <div className="flex-1 p-5 flex justify-between space-x-5">
                                    <div className="flex flex-col">
                                        <span className="mt-1.5 text-lg font-semibold">
                                            <div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                                        </span>
                                        <span className="text-sm text-neutral-400"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-400 mb-2.5" style={{ width: "14rem" }}></div></span>

                                    </div>
                                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                                </div>
                                <div className="flex-1 p-5 flex justify-between space-x-5">
                                    <div className="flex flex-col">

                                        <span className="mt-1.5 text-lg font-semibold"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                                        <span className="text-sm text-neutral-400"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-400  mb-2.5" style={{ width: "14rem" }}></div></span>
                                    </div>
                                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                                </div>
                            </div>
                            <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                                <div className="flex-1 p-5 flex justify-between space-x-5">
                                    <div className="flex flex-col">
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>


                                    </div>
                                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                                </div>
                                <div className="flex-1 p-5 flex justify space-x-5">
                                    <div className="flex flex-col">
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                                        <span className="text-md text-neutral-600"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                                    </div>
                                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block flex-grow">
                        <div className="flex flex-col">

                            <span className="mt-1.5 text-lg font-semibold"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div></span>
                            <span className="text-sm text-neutral-400"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-400  mb-2.5" style={{ width: "14rem" }}></div></span>
                        </div>
                    </div>
                </main>




            </div>

        </div>

    );
};

export default BookingDetailsSkeleton;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Wed Feb 15 2023
 * Created at 11:20:03 AM
 * File Content:
 * *================*
 * *================*
 * Ocean Search Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Mon Mar 06 2023
 * Updated at 1:04:04 PM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Fri Mar 03 2023
 * Updated at 2:56:35 PM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 6:00:05 PM
 *
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:05:32 AM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:44:55 PM
 *
 */

// React Default imports
import { FC, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//Custom Components
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import { OceanRateList } from "./OceanRateList";
import {
  oceanRateType,
  freightChargesType,
  filterTypes,
  OceanRatesType,
} from "./OceanRates";

// Libraries, Contexts & Helpers
import { Helmet } from "react-helmet";
import { InlineShareButtons } from "sharethis-reactjs";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";
import { useQueryState } from "hooks/useQueryState";
import FilterSkeleton from "components/SkeletonComponents/FilterSkeleton";
import { secureGetRequest, getRequest } from "utils/helpers/apiVerbs";
import { useUserAuth } from "utils/contexts/userContext";
import girlSearch from "../../images/search-girl.svg";
import SortingComponent from "./SortingComponent";
import CommodityFilter from "./CommodityFilter";
import CargoTypeTabs from "./CargoTypeTabs";
import { FunnelIcon, ArrowsUpDownIcon } from "@heroicons/react/24/outline";

import { useTransition, animated } from "@react-spring/web";

export interface OceanSearchPageProps {
  className?: string;
}

const isObjectEmpty = (objectName: any) => {
  return objectName && Object.keys(objectName).length === 0;
};

const OceanSearchPage: FC<OceanSearchPageProps> = ({ className = "" }) => {
  const [rates, setRates] = useState<any[]>([]);
  const [filteredRates, setFilteredRates] = useState<any[]>([]);
  const [tabList, setTabList] = useState<string[] | null>(null);
  const [ratesString, setRatesString] = useState("");
  const [cargo, setCargo] = useState<string | null>(null);
  const [commodidtyList, setCommodityList] = useState<string[]>([]);
  const [selectedCommodity, setSelectedCommodity] = useState<string>("");
  const [filterCopy, setFilterCopy] = useState<any[]>([]);

  const [toPort, setToPort] = useQueryState("to_port");
  const [fromPort, setFromPort] = useQueryState("from_port");
  const [validity, setValidity] = useQueryState("Validity");

  const [apiCalled, setApiCalled] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  const { setIsLoading, setError } = useEventContext();
  const { isLogin } = useUserAuth();
  const getOceanRates = async () => {
    setShowLoading(true);
    setApiCalled(true);
    try {
      const oceanRates: any = isLogin.login
        ? await secureGetRequest(apiPaths.ocean.rates.ocean_rates, {
            to_port: toPort,
            from_port: fromPort,
            Validity: validity,
          })
        : await getRequest(apiPaths.ocean.rates.ocean_rates, {
            to_port: toPort,
            from_port: fromPort,
            Validity: validity,
          });
      setRates(oceanRates.data);
      //   setTabList(Object.keys(oceanRates.data));

      setShowLoading(false);
    } catch (error: any) {
      setError({ ...error.response.data, show: true });
      setRates([]);
      setIsLoading(false);
      setShowLoading(false);
      setTabList([]);
      setFilteredRates([]);
    }
  };

  // selecting Cargo type
  const selectCargo = (cargo_type: any) => {
    setCargo(cargo_type);
    if (cargo_type.length > 0) {
      setFilteredRates(
        rates[cargo_type].sort(
          (a: any, b: any) => a.totalPrice - b.totalPrice
        ) || []
      );
    }
    setFilterCopy(
      rates[cargo_type].sort((a: any, b: any) => a.totalPrice - b.totalPrice) ||
        []
    );
  };

  // Selecting type of commodity
  const selectCommodity = (commodity: string) => {
    setSelectedCommodity(commodity);
    if (commodity === "ALL") {
      setFilteredRates(filterCopy);
    } else {
      setFilteredRates(
        filterCopy.filter((item) => item.cargo_type === commodity)
      );
    }
  };

  const sortFunction = (type: string) => {
    if (filteredRates.length > 0) {
      switch (type) {
        case "Low to High (Price)":
          const ascending = filteredRates.sort(
            (a, b) => a.totalPrice - b.totalPrice
          );
          setFilteredRates([...ascending]);
          break;
        case "High to Low (Price)":
          const descending = filteredRates.sort(
            (a, b) => b.totalPrice - a.totalPrice
          );

          setFilteredRates([...descending]);
          break;
        default:
          const defaultValue = filteredRates.sort(
            (a, b) => a.totalPrice - b.totalPrice
          );
          setFilteredRates([...defaultValue]);
      }
    }
  };

  const transitions = useTransition(cargo, {
    from: { transform: "translateX(100%)" },
    enter: { transform: "translateX(-3%)" },
    leave: { transform: "translateX(-10%)" },
    
  });

  useEffect(() => {
    if (toPort && fromPort) {
      getOceanRates();
    }
  }, [toPort, fromPort, validity]);

  useEffect(() => {
    if (!isObjectEmpty(rates)) {
      setFilteredRates(Object.values(rates)[0]);
      setTabList(Object.keys(rates));
    }
  }, [rates]);

  useEffect(() => {
    const list = filterCopy.map((item) => item.cargo_type);
    const uniqueList = [...new Set(list)];
    
    setCommodityList(["ALL", ...uniqueList]);
  }, [filterCopy]);

  useEffect(() => {
    selectCommodity("ALL");
  }, [filterCopy]);

  function getBaseRates(): any {
    const baseRates: number[] = [];

    for (const cargoSize in rates) {
      if (rates.hasOwnProperty(cargoSize)) {
        const cargoData = rates[cargoSize];
        
        for (const item of cargoData) {
          const baseRate = item.base_rate;
          if (typeof baseRate === 'number') {
          baseRates.push(baseRate);
          }
        }
      }
    }
    
  }
  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Transpost | Ocean Search Rates</title>
      </Helmet>

      <BgGlassmorphism />
        <div className="container pt-10 pb-24 lg:pt-16 lg:pb-32">
        <div className="flex flex-col  lg:flex-row -mt-25 lg:items-center">
          <div className="flex-shrink-0 lg:w-1/2 flex flex-col items-start space-y-6 xl:pr-14 lg:mr-10 xl:mr-0">
            <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl leading-[110%]">
              <div className="font-Montserrat">Ocean Rates Search.</div>
            </h2>
            <p className={"text-[#808080]"}>
              Streamline Your Global Shipping Process in Just{" "}
              <span className="text-[#2aa996] font-bold">One Click</span>.{" "}
              <br />
              Search for Ocean Freight Rates Worldwide with Ease, Compare, Book,
              and Ship with Confidence Along with Top Shipping Lines and NVOCC's
            </p>
          </div>
          <div className=" hidden lg:flex  flex-grow w-full justify-center">
            <img className="w-3/5 object-fill" src={girlSearch} alt="hero" />
          </div>
        </div>
        <div
          className={`nc-SectionHeroArchivePage flex flex-col relative mt-40 mb-40 pt-10 pb-10 lg:pb-1 lg:pt-16`}
          data-nc-id="SectionHeroArchivePage"
          >
          <div className="relative ">
            <div className="z-max xl:-mt-40 xl:-mt-18 w-full">
              <HeroSearchForm
                currentPage="Ocean"
                currentTab="Ocean"
                autoFillForm={{
                  assign: "Ocean",
                  page: "oceansearchpage",
                  feilds: {
                    to_port: toPort,
                    from_port: fromPort,
                    Validity: validity,
                  },
                }}
              />
            </div>

            <div
              className={`nc-SectionGridFilterCard ${className}`}
              data-nc-id="SectionGridFilterCard"
            >
              <div className="mb-8 mt-8 lg:mb-8">
                {showLoading && <FilterSkeleton />}

                <div>
                  {!isObjectEmpty(rates) && tabList?.length && (
                    <CargoTypeTabs
                      rates={rates}
                      tabList={tabList}
                      selectCargo={selectCargo}
                    />
                  )}
                </div>
              </div>
            </div>
            {
              // TODO: Uncomment it
              // filteredRates.length > 0 &&
              tabList?.length ? (
                <div className="flex flex-col z-5 mb-6 gap-4 md:flex-row md:gap-8 relative ">
                  <div className="flex flex-col gap-1 md:gap-2">
                    <div className="flex ">
                      <span className="pl-4 pr-2">Filter by Commodity</span>
                      <FunnelIcon
                        stroke-width="2"
                        className="w-5 h-5 text-neutral-700 mt-1"
                      />
                    </div>
                    <CommodityFilter
                      filterCopy={filterCopy}
                      selectCommodity={selectCommodity}
                      commodityList={commodidtyList}
                    />
                  </div>
                  <div className="flex flex-col gap-1 md:gap-2">
                    <div className="flex">
                      <span className="pl-4 pr-2">Sort by</span>
                      <ArrowsUpDownIcon
                        stroke-width="2"
                        className="w-5 h-5 text-neutral-700 mt-1 "
                      />
                    </div>

                    <SortingComponent
                      filterCopy={filterCopy}
                      sortFunction={sortFunction}
                    />
                  </div>
                </div>
              ) : null
            }
            {transitions((styles, tab) =>
              tab === cargo ? (
                <animated.div style={styles}>
                  <OceanRateList
                    rateList={filteredRates!}
                    showLoading={showLoading}
                    apiCalled={apiCalled}
                  />
                </animated.div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OceanSearchPage;

import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useQuoteList } from "utils/contexts/quoteListContext";

const Remarks = ({ data, quote }: any) => {
  const termBlock = convertFromHTML(data?.terms);
  const remblocks = convertFromHTML(data?.remarks);
  const [remarkState, setRemarkState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        remblocks.contentBlocks,
        remblocks.entityMap
      )
    )
  );
  const [termsState, setTermsState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        termBlock.contentBlocks,
        termBlock.entityMap
      )
    )
  );

  const { addRemarks, addTerms } = useQuoteList();

  const onRemarksStateChange = (remarkseditorState: any) => {
    const htmlContent = stateToHTML(remarkseditorState.getCurrentContent());
    console.log("htmlContent", htmlContent);
    addRemarks(data.id, htmlContent);
    setRemarkState(remarkseditorState);
  };

  const onTermsStateChange = (termseditorState: any) => {
    const htmlContent = stateToHTML(termseditorState.getCurrentContent());
    console.log("htmlContent", htmlContent);
    addTerms(data.id, htmlContent);
    setTermsState(termseditorState);
  };

  return (
    <>
      <span className="container">Remarks</span>
      <div className="container mb-6">
        <Editor
          editorState={remarkState}
          onEditorStateChange={onRemarksStateChange}
          editorStyle={{ height: "200px" }}
          toolbarStyle={{ background: "#d1d1d1" }}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          wrapperStyle={{ border: "1px solid" }}
          toolbar={{
            options: [
              "history",
              "inline",
              "fontSize",
              "blockType",
              "list",
              "textAlign",
              "colorPicker",
            ],
            inline: {
              inDropdown: true,
            },
          }}
        />
      </div>
      <span className="container">Terms and Condition</span>
      <div className="container mb-4">
        <Editor
          editorState={termsState}
          onEditorStateChange={onTermsStateChange}
          editorStyle={{ height: "200px" }}
          toolbarStyle={{ background: "#d1d1d1" }}
          toolbarClassName="toolbar-class"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          wrapperStyle={{ border: "1px solid" }}
          toolbar={{
            options: [
              "history",
              "inline",
              "fontSize",
              "blockType",
              "list",
              "textAlign",
            ],
            inline: {
              inDropdown: true,
            },
          }}
        />
      </div>
    </>
  );
};

export default Remarks;

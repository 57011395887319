import React, { useState } from "react";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit,faEdit } from "@fortawesome/free-solid-svg-icons";

const UserDetailsCard = ({ customer, onEditChange }: any) => {
  return (
    <>
      <div className="relative  mt-5 mr-5">
        <button
          className="absolute top-0 right-0 hover:scale-110 translate-x-2 transform ease-in-out duration-200 "
          onClick={() => onEditChange("userDetails", true)}
        >
         Edit <FontAwesomeIcon icon={faEdit} />
        </button>
        <div className="grid grid-cols-1 gap-6 h-full md:grid-cols-2 my-2">
          <label className="block">
            <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
              Full Name:
            </span>
            <p>{customer?.contact_person ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Phone:
            </span>
            <p>{customer?.phone ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Email:
            </span>
            <p>{customer?.email ?? "N/A"}</p>
          </label>
        </div>
      </div>
    </>
  );
};

export default UserDetailsCard;

import React, { useState, useEffect } from "react";
import { useQuoteList } from "utils/contexts/quoteListContext";

import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/24/outline";

const AdditionalCharges = ({ item, quoteId, chargeId, legId,legName }: any) => {
  const [customChargeName, setCustomChargeName] = useState(item.chargeName);
  const [customBasis, setCustomBasis] = useState(item.chargeType);
  const [buyRate, setBuyRate] = useState(
    item.buyRate || item.totalAmountInUSD || 0
  );
  const [sellRate, setSellRate] = useState(
    item.sellRate || item.totalAmountInUSD || 0
  );
  const [quantity, setQuantity] = useState(item.quantity || 1);
  const [netBuyRate, setNetBuyRate] = useState(0);
  const [netSellRate, setNetSellRate] = useState(0);

  const { addCharge, editCharge, removeCharge } = useQuoteList();

  const onAddHandler = (
    id: any, // quoteId
    chargeTarget: string,
    chargeId: string
  ) => {
    addCharge(id, chargeTarget, chargeId, legId);
  };

  useEffect(() => {
    console.log(item.quantity);

    if (item.chargeType === "CN") {
      setCustomBasis("per Equipment");
    } else if (item.chargeType === "BL") {
      setCustomBasis("per BL");
    } else {
      setCustomBasis("per Equipment");
    }

    console.log(customBasis);
  }, []);

  const selectHandler = (e: any) => {
    setCustomBasis(e.target.value);
  };

  useEffect(() => {
    setNetBuyRate(quantity * buyRate);
  }, [buyRate,quantity]);

  useEffect(() => {
    setNetSellRate(quantity * sellRate);
  }, [sellRate,quantity]);

  useEffect(() => {
    editCharge(
      quoteId,
      item.chargeTarget,
      chargeId,
      customBasis,
      customChargeName,
      buyRate,
      sellRate,
      netBuyRate,
      netSellRate,
      quantity,
      legId,
      legName
    );
    console.log("EDITING");
  }, [customChargeName, customBasis, buyRate, sellRate,quantity,netSellRate,netBuyRate,legName]);

  return (
    <div className="grid grid-cols-10 mx-3 font-light">
      <div className="flex col-start-1 col-end-3  px-3 items-center border border-neutral-400 rounded-lg m-2 bg-white">
        <input
          type="text"
          className="w-full px-3  border-0 capitalize focus:ring-0 focus:outline-none dark:bg-transparent"
          placeholder="Charge Name"
          onChange={(e: any) => setCustomChargeName(e.target.value)}
          value={customChargeName}
        />
      </div>
      <div className="flex px-2 items-center border border-neutral-400 rounded-lg m-2 bg-white">
        {/* <select
            onChange={selectHandler}
            value={customBasis}
            className="border-0 focus:ring-0 focus:outline-none w-full dark:bg-transparent"
          >
            <option label="" value=""></option>
            <option label="per equipment" value="per equipment">
              per equipment
            </option>
            <option label="per B/L" value="per B/L">
              per B/L
            </option>
            <option label="per shipment" value="per shipment">
              per shipment
            </option>
            <option label="per shipping bill" value="per shipping bill">
              per shipping bill
            </option>
            <option label="per container" value="per container">
              per container
            </option>
            <option
              label="per shipping bill / per container"
              value="per shipping bill / per container"
            >
              per shipping bill / per container
            </option>
            <option label="per carton" value="per carton">
              per carton
            </option>
            <option label="per pallet" value="per pallet">
              per pallet
            </option>
            <option label="per vehicle" value="per vehicle">
              per vehicle
            </option>
            <option label="per shift" value="per shift">
              per shift
            </option>
            <option label="per package" value="per package">
              per package
            </option>
            <option label="per invoice" value="per invoice">
              per invoice
            </option>
            <option label="N/A" value="N/A">
              N/A
            </option>
          </select> */}
        {customBasis}
      </div>
      <div className="flex px-3 items-center border border-neutral-400 rounded-lg m-2 bg-white">
        20GP
      </div>
      <div className="flex px-3 items-center border border-neutral-400 rounded-lg m-2 bg-white">
      <input
          type="number"
          defaultValue={quantity}
          value={quantity}
          min={1}
          onChange={(e) => setQuantity(parseInt(e.target.value))}
          className="border-transparent  focus:border-transparent focus:ring-0 outline-none border-0 w-full dark:bg-transparent"
        />
      </div>
      <div className="flex px-3 items-center border border-neutral-400 rounded-lg  m-2 bg-white">
        <span>USD</span>
        <input
          type="number"
          placeholder="Buy Rate"
          value={buyRate}
          onChange={(e) => {
            setBuyRate(parseInt(e.target.value));
          }}
          className="border-b-[1px] my-1 focus:border-transparent focus:ring-0 outline-none border-0 w-full dark:bg-transparent"
        ></input>
      </div>
      <div className="flex px-3 cursor-not-allowed items-center border border-neutral-400 rounded-lg m-2 bg-white">
        USD {netBuyRate}
      </div>
      <div className="flex px-3 items-center border focus:border-neutral-600 border-neutral-400 rounded-lg m-2 bg-white">
        <span>USD</span>
        <input
          type="number"
          className="border-b-[1px] my-1 focus:border-transparent focus:ring-0 outline-none border-0 w-full dark:bg-transparent"
          onChange={(e) => setSellRate(parseInt(e.target.value))}
          value={sellRate}
        ></input>
      </div>
      <div className="flex px-3 cursor-not-allowed items-center border border-neutral-400 rounded-lg m-2 bg-white">
        USD {netSellRate}
      </div>
      <div className="flex px-3 gap-2 items-center  bg-white">
        <p
          className="h-7 w-7 cursor-pointer"
          onClick={() => onAddHandler(quoteId, item.chargeTarget, chargeId)}
        >
          <PlusCircleIcon className="text-[#2AA996]" />
        </p>
        <p
          className="w-7 h-7 cursor-pointer"
          onClick={() =>
            removeCharge(quoteId, item.chargeTarget, chargeId, legId)
          }
        >
          <MinusCircleIcon className="text-[red]" />
        </p>
      </div>
    </div>
  );
};

export default AdditionalCharges;

import React, { useState, useEffect } from "react";
import useIdleTimer from "./useIdleTimer";
import { postRequest, securePostRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { Helmet } from "react-helmet";
import { useSessionStorage } from "./useSessionStorage";

type ClickHistory = {
  x: number;
  y: number;
};


const useUserTracking = (emailID: string | null) => {
  const [currentPage, setCurrentPage] = useState<string>("");
  const [timeOnPage, setTimeOnPage] = useState<number>(0);
  const [pageHeader, setPageHeader] = useState<string>("");
  const [clicksOnPage, setClicksOnPage] = useState<number>(0);
  const [clickHistory, setClickHistory] = useState<[] | ClickHistory[]>([]);
  const [nextPageId, setNextPageId] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  const isIdle = useIdleTimer(180000);
  const [post, setPost] = useState({});



  const callTrackingApi = async (postData: any) => {
    try {
      // TODO Closed for now, need to enhance
      const response = emailID
        ? await securePostRequest(apiPaths.user.userTracking, postData)
        : await postRequest(apiPaths.user.userTracking, postData);

      if (postData.isIdle === 1) {
        setNextPageId(null);
      } else {
        setNextPageId(response.data.page_id);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    let timerId: number | typeof setInterval | any;

    const updatePageTimer = () => {
      if (window.location.href !== currentPage) {
        const array = JSON.parse(
          window.sessionStorage.getItem("activity_ids") || "[]"
        );
        setCurrentPage(window.location.href);
        const header = Helmet.peek();
        setPageHeader(header.title);

        setTimeOnPage((prevTimeOnPage) => {
          let postData = {
            email: emailID,
            header: pageHeader,
            current_url: currentPage,
            time_spend: prevTimeOnPage,
            no_of_clicks: clicksOnPage,
            click_history: clickHistory,
            next_page: nextPageId,
            isIdle,
            activity_ids: array,
          };

          setPost(postData);

          callTrackingApi(postData);
          // If the user leaves the current page, send API request with current page and time on page
          console.log(
            `Time spent on ${currentPage} page is ${prevTimeOnPage} second`
          );
          console.log("Clicks on Page", clicksOnPage, clickHistory);

          return 0;
        });
        setClicksOnPage(0);
        setClickHistory([]);
      } else {
        setTimeOnPage((prevTimeOnPage) => {
          return prevTimeOnPage + 1;
        });
      }
    };

    function onClick(e: any) {
      // e.preventDefault();
      setClickHistory((prevCordinates) => {
        return [...prevCordinates, { x: e.pageX, y: e.pageY }];
      });
      setClicksOnPage((prev) => {
        console.log("Previous clicks count", prev, e.pageX, e.pageY);

        return prev + 1;
      });
    }
    setApiCalled(false);
    window.addEventListener("click", onClick);

    timerId = setInterval(updatePageTimer, 1000);
    return () => clearFunction(timerId, onClick);
  }, [currentPage, clicksOnPage]);

  function clearFunction(timerId: any, onClick: (e: any) => void) {
    window.removeEventListener("click", onClick);
    clearInterval(timerId);
  }

  useEffect(() => {
    if (isIdle === 1 && !apiCalled) {
      const array = JSON.parse(
        window.sessionStorage.getItem("activity_ids") || "[]"
      );
      let postData = {
        email: emailID,
        current_url: currentPage,
        time_spend: timeOnPage,
        header: pageHeader,
        no_of_clicks: clicksOnPage,
        click_history: clickHistory,
        next_page: nextPageId,
        isIdle,
        activity_ids: array,
      };
      callTrackingApi(postData);
      setApiCalled(true);
      setNextPageId(null);
    }
  }, [isIdle]);

  return post;
};

export default useUserTracking;

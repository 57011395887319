import React, { useEffect, useState } from "react";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loader from "new_component/Loader";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useEventContext } from "utils/contexts/eventContext";
import { securePostRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";

const EditUser = ({ onEditChange, updateUser, profileDetails }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({
    name: "",
    phone: "",
  });

  const { userState, setUserState } = useUserDetails();
  const { setError } = useEventContext();

  const updateHandler = async (e: any) => {
    e.preventDefault();
    // Call update user API

    console.log("profileDetails", profileDetails);

    setIsLoading(true);
    try {
      const updatedUser = await securePostRequest(
        apiPaths.user.profiledetails,
        {
          ...profileDetails,
        }
      );
      console.log(updatedUser.data.data);

      userState &&
        setUserState({
          ...userState,
          customer: {
            ...userState?.customer,
            name: updatedUser?.data?.data?.name,
            contact_person: updatedUser?.data?.data?.name,
            phone: updatedUser?.data?.data?.phone,
          },
        });
      setIsLoading(false);
      onEditChange("userDetails", false);
    } catch (error: any) {
      setIsLoading(false);
      onEditChange("userDetails", false);
      setError({ ...error.response.data, show: true });
    }
  };
// updating user object on change
  useEffect(() => {
    updateUser(user);
  }, [user.name, user.phone]);

  // getting user details from userState
  useEffect(() => {
    if (userState) {
      setUser({
        name: userState?.customer.contact_person || "",
        phone: userState.customer.phone || "",
      });
    }
  }, [userState]);
  return (
    <>
      <form
        onSubmit={updateHandler}
        className="grid grid-cols-1 gap-6 h-full md:grid-cols-2 my-2"
      >
        <label className="block">
          <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
            Full Name:
          </span>
          <Input
            type="text"
            placeholder="Change your full name"
            className="mt-1"
            value={user.name}
            onChange={(e: any) => setUser({ ...user, name: e.target.value })}
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 font-semibold dark:text-neutral-200">
            Phone:
          </span>
          <Input
            type="number"
            placeholder="Change your contact number"
            className="mt-1"
            value={user.phone}
            onChange={(e: any) => setUser({ ...user, phone: e.target.value })}
          />
        </label>
        <label className="block">
          <span className="text-neutral-800 font-semibold dark:text-neutral-200">
            Email:
          </span>
          <Input
            type="text"
            placeholder="Change your email"
            className="mt-1 cursor-not-allowed"
            value={userState?.customer.email}
          />
        </label>
        <div></div>
        <div></div>
        <div className="ml-auto  gap-4 flex w-full flex-col   md:flex-row">
          <ButtonPrimary type="submit" className=" w-full">
            {isLoading ? <Loader className="w-6 h-6" /> : "Update"}
          </ButtonPrimary>
          <ButtonPrimary
            type="button"
            onClick={() => onEditChange("userDetails", false)}
            className="  w-full  bg-[#F7961D] hover:bg-[#F37911] text-[#000000] "
          >
            Cancel
          </ButtonPrimary>
        </div>
      </form>
    </>
  );
};

export default EditUser;

import React from "react";
import { Helmet } from "react-helmet";


const PrivacyPolicyPage = () => {
  return (
    <div className="py-10 font-medium text-center container px-10">
        <Helmet>
        <title>Privacy Policy | Transpost</title>
      </Helmet>
      <h1 className="text-center underline decoration-solid underline-offset-2 pr-2 py-2">
        <span className="text-2xl">PRIVACY POLICY</span>{" "}
        <span className="underline italic decoration-solid">
          as amended on 02nd May, 2023
        </span>
      </h1>
      <p className="text-left whitespace-normal indent-0 leading-6 text-sm">
        <ul className="last-inside space-y-4">
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              1. PURPOSE
            </p>
            <p>
              This website www.transpost.co and the Transpost application are
              owned, operated, controlled and managed by Transpost Technologies
              Private Limited (hereinafter referred to as “Transpost” or “we” or
              “our”), a company registered in India under the provisions of
              Companies Act, 2013. We are in the business of providing
              Information technology enabled solutions and services to
              facilitate transactions for all kind of container logistics.
              worldwide through our digital booking platform named “Transpost”.{" "}
            </p>
            <p>
              To demonstrate its commitment to the privacy of users of this
              website and application, we would like to place this Privacy
              Policy on record.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              2. USER’S CONSENT TO PRIVACY POLICY
            </p>
            <p>
              This Privacy Policy describes our policy for the collection, use,
              disclosure, retention, and protection of your personal information
              from fraud and misuse. It applies to our website and all its pages
              and to any application, service, or tool (collectively the
              “Services”) where this Privacy Policy is referenced, regardless of
              how you access or use them, including through mobile or any other
              devices such as an iPad or others.{" "}
            </p>
            <p>
              We shall therefore only use your name and other information, which
              relates to you, in the manner outlined in this Privacy Policy. We
              will only collect information where we must do so and we will only
              collect such information, which is relevant to our dealings with
              you. We will only keep your information for as long as law either
              requires us to or as is relevant for the purposes for which it was
              collected.{" "}
            </p>
            <p>
              Any person accessing, browsing, or otherwise using this website,
              either manually or via an automated device or program, shall be
              considered as ‘User’ or is addressed as ‘User’ or ‘You’ or
              ‘registrant’ or ‘customer’ in this policy. The purpose of this
              Privacy Policy is to explain the type of information that we
              collect from you, what we do with that information, and whom we
              share this information with.
            </p>
            <p>
              This Privacy Policy may be amended from time to time based on our
              requirements and the effective date of the amended policy will be
              reflected herein. In view of the above, users are requested to
              review the changes carefully every time they visit the website. If
              you disagree with the Privacy Policy, you must stop using the
              service(s). If you continue to use this website with the amended
              privacy policy, it will be deemed that you have given your consent
              to such changes.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              3. COLLECTION OF INFORMATION
            </p>
            <p>
              The data(s) such as User’s profile will be collected using a safe
              and smooth procedure when any User registers for
              events/webinars/reviews/legal-tech demo request or places an
              inquiry or order with us. Information about User’s profile and all
              those data(s) will ultimately be stored in our database which
              shall not be disclosed publicly as per Rule 6 of the Information
              Technology (Reasonable Security Practices and Procedures and
              Sensitive Personal Data or Information) Rules, 2011. We have put
              in place adequate data security measures. For more information,
              please see clause 13 of this policy.
            </p>
            <p>Information is collected directly when you: </p>
            <ul className="list-disc list-inside">
              <li>
                Register with us for any of our services, events, webinars etc.{" "}
              </li>
              <li>Correspond with us (either by call, e-mail or others)</li>
              <li>
                Create a listing, sponsor our events/webinars or avail any other
                service – free or paid
              </li>
            </ul>
            <p>
              Further, we may also collect your information via use of
              cookies/web beacons and other similar technologies{" "}
            </p>
            <p>Categories of Information we collect may include: </p>
            <ul className="list-disc list-inside">
              <li>
                Personal Information such as name, mailing address, telephone
                numbers, place of business, company info, designation, email id,
                etc.{" "}
              </li>
              <li>
                Internet Protocol Address (IP), the domain you used to access
                the Internet, your website URL, the webpage you came from, and
                webpage you may visit next.
              </li>
              <li>
                Geo-location, Advertising information such as size, type of ad,
                data, use of advertising channel (if any) & Lead generation.{" "}
              </li>
              <li> Information collected by our cookies </li>
              <li>
                Payment/Billing related information such as debit card/credit
                card/net-banking or other channels, including but not limited to
                legal entity documents such as Permanent Account Number (PAN),
                Aadhar details or business registration details such as
                Incorporation or Tax-related documents such as GST or other
                licences (if applicable).{" "}
              </li>
              <li>Profile of the legal entity or Profile URL, if applicable</li>
            </ul>
            <p>Reasons to collect said information: </p>
            <ul className="list-disc list-inside">
              <li>
                Providing a link between Legal-Tech Vendors and Legal-Tech
                Buyers
              </li>
              <li>
                Providing a link between event/webinar sponsors and delegates{" "}
              </li>
              <li> Sending promotional/service e-mails</li>
              <li>
                Inviting for current &future events, seminars and webinars{" "}
              </li>
              <li>
                Responding to your questions, requests, resolving disputes
              </li>
              <li>Consultancy services and responding to sales inquiries</li>
              <li>
                Arranging one-to-one personal contacts, meetings, webinar,
                reviews, technology outputs amongst others
              </li>
              <li>
                Preventing prohibited or illegal activities, resolving disputes
                and enforcing our policies wherever applicable.
              </li>
              <li>Transaction Processing </li>
              <li> Personalized Advertisement & Promotional activities</li>
            </ul>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              4. USER RIGHTS
            </p>
            <p>
              User may at any time request information about the data, which we
              hold about the user as well as about their origin, recipients or
              categories of recipients to which such data is forwarded and about
              the retention purpose.
            </p>
            <p>
              User may request immediate correction of any incorrect personal
              data related to them or opt for a restriction of the processing.
              The user is also entitled to request completion of incomplete
              personal data also by means of a supplementary declaration.
            </p>
            <p>
              User is entitled to receive the respective personal data provided
              to us in a structured, common, and machine-readable format and
              user is entitled to transmit such data to other data controllers
              without restriction if the processing was based on user consent or
              if the data was processed by means of automated procedures.
            </p>
            <p>
              User may request for removal of any personal data. Once such
              request is being placed by the User, we are, inter alia, obliged
              to erase such data in such case (in 30 days) when it is no longer
              required for the purpose for which it was collected or otherwise
              processed (for more information, please see clause 15 below). User
              may withdraw the given consent to the use of his/her data at any
              time.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              5. RETENTION PERIOD{" "}
            </p>
            <p>
              We retain your personal information as long as it is necessary and
              expedient for our operations. Besides, we may retain personal
              information from closed accounts to comply with national laws,
              prevent fraud, collect any payment owed, resolve disputes,
              troubleshoot problems, assist with any investigation, enforce our
              terms of use and take other actions permitted or required by
              applicable Indian laws. In the event it is no longer required for
              us to retain your personal information, we dispose-off in a secure
              manner according to our relevant policies.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              6. THIRD-PARTY DISCLOSURE{" "}
            </p>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties
              your personally identifiable information unless we provide you
              with advance notice. However, joint promotions are possible to
              share the related information to offering a wider portfolio,
              experience, and knowledge to its Users. This does not include
              website hosting partners and other parties who assist us in
              operating our website, conducting our business, or servicing you,
              provided that such parties agree to keep this information
              confidential. We may also release your information when we believe
              release is necessary to comply with the law, enforce our website
              policies, or protect ours or others’ rights, property, or safety.{" "}
            </p>
            <p>
              However, notwithstanding any of the above, we may provide, share,
              or sell personally identifiable visitor information (including
              your name and contact details) to other parties (such as the
              sponsors/delegates of our events) for marketing, advertising or
              other uses at our/their discretion. If you do not wish to receive
              such marketing communications, please see clause 15 below.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              7. THIRD-PARTY LINKS
            </p>
            <p>
              We may occasionally include links to other websites. Such websites
              are governed by their respective Privacy Policies, which are
              beyond our control. Once the User leaves our servers (the User can
              tell where he/she is by checking the URL in the location bar on
              the User’s browser), use or otherwise processing of any such
              information provided by the User is governed by the privacy policy
              of the operator of the website which the user is visiting. That
              policy may differ from our own. If the User cannot find the
              privacy policy of any of these websites via a link from the
              website’s homepage, the User may contact the site directly for
              more information.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              8. COOKIES & SIMILAR TECHNOLOGIES{" "}
            </p>
            <p>
              We may collect personal information using cookies, web beacons,
              and similar technologies. We use cookies to help us compile
              aggregate data about website traffic and site interaction, so that
              we can offer better site experiences and tools in the future. We
              use cookies to:{" "}
            </p>
            <ul className="list-disc list-inside">
              <li>
                Understand and save the User’s preferences for future visits
              </li>
              <li>
                Compile aggregate data about site traffic and site interactions
                to offer better website experiences and tools in the future. We
                may also use trusted third-party services that track this
                information on our behalf.{" "}
              </li>
              <li>
                Use Third-Party services to track this information for us.{" "}
              </li>
              <li>
                To restrict usage of the software on the defined number of
                machines to avoid unauthorized access to software.{" "}
              </li>
            </ul>
            <p>
              The Information, as mentioned hereinabove, will be collected when
              the situation requires, and more particularly (at the time of):{" "}
            </p>
            <ul className="list-disc list-inside">
              <li>User’s Enquiry</li>
              <li> Response to Surveys</li>
              <li> Filling out a Form</li>
              <li> User’s Feedback</li>
              <li> Newsletter Subscription</li>
            </ul>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              9. INTERNATIONAL TRANSFERS{" "}
            </p>
            <p>
              We may transfer the information you provide to other Vendors
              and/or Sponsors as the case may be, outside the home jurisdiction,
              situated in any other country/organizations, which have a
              different set of rules and regulations such as, but not limited
              to, Singapore, Hong Kong, Australia or other APAC Countries;
              Africa, Dubai or other Middle East Countries; to enable better
              experience and personalization to connect and arrange legal tech
              demos, meetings, calls, emails, seminars, create a link, webinar
              or other possible events whether online or offline.{" "}
            </p>
            <p>
              The list of countries where such information is disclosed inter
              alia, without any limitation includes:- Singapore, Hong Kong,
              India, Australia or other APAC Countries; Africa, Dubai or other
              Middle East Countries.
            </p>
            <p>
              We will take all reasonable steps to ensure that personal
              information is protected, and any such transfers comply with
              applicable law. We may transfer and maintain the personal
              information of individuals covered by this policy on servers or
              databases outside the home jurisdiction. Some of these countries
              may not have the equivalent level of protection under their data
              protection laws as we have in the home country.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              10. PAYMENT GATEWAY, FINANCIAL DATA & OTHERS
            </p>
            <p>
              Whenever applicable, you may pay for Transpost products or
              services using your payment card and, in such cases, we will
              collect data connected to your payment card. We use this personal
              data to process your payment and to prevent fraudulent
              transactions. We do this based on your consent to process this
              information. Information is given to respective payment gateways
              or banks and is governed by their respective policies. Data
              mentioned at the time of purchase are subjective and stored only
              and only to process the payments and in no case shall be sold to
              any other business process outsourcing entities. We save only the
              relevant information and exclude your personal and confidential
              information such as CVV amongst others.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              11. SECURITY
            </p>
            <p>
              This Website has security measures to protect against the loss,
              misuse, and alteration of the information that is submitted to
              Transpost and is under its control. We ensure end-to-end
              encryption and possess a Secured Socket Layer Certificate (SSL
              Certification) amongst others. We also have an embedded malware
              scan software. However, we make no representations or warranties
              concerning the sufficiency of these security measures. We shall
              not be responsible for any actual or consequential damages that
              result from a lapse in compliance with this Policy as a result of
              a security breach or technical malfunction. However, in the
              unlikely event of a data breach, we undertake to inform the users
              within a period of 72 hours. Certain information may be
              transmitted to you by e-mail.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              12. DISPUTE RESOLUTION AND JURISDICTION{" "}
            </p>
            <p>
              It is expressly agreed to by the Parties hereto that the
              formation, interpretation, and performance of this Policy, and any
              disputes arising from here will be resolved through a two-step
              Alternate Dispute Resolution (“ADR”) mechanism. It is further
              agreed to by the Parties that the contents of this Section shall
              survive even after the termination or expiry of the Policy and /
              or Terms.{" "}
            </p>
            <p>
              <span> a) Mediation: </span>{" "}
              <span>
                In case of any dispute between the parties, the Parties will
                attempt to resolve the same amicably amongst themselves, to the
                mutual satisfaction of both Parties. If the Parties are unable
                to reach such an amicable solution within ninety (90) days of
                one Party communicating the existence of a dispute to the other
                Party, the dispute will be resolved by arbitration, as detailed
                herein below.
              </span>
            </p>
            <p>
              <span> b) Arbitration: </span>{" "}
              <span>
                If the Parties are unable to amicably resolve a dispute by
                mediation, said dispute will be referred to Arbitration by a
                panel of three arbitrators. The parties shall appoint one
                arbitrator each and the selected arbitrators shall choose the
                third and presiding Arbitrator. In the event the parties are
                unable to decide on any of the Arbitrators within thirty days of
                a notice for Arbitration, they shall approach the courts of
                Mumbai for the relevant appointment. The award passed by the
                presiding arbitrator will be valid and binding on both Parties.
                The Parties shall equally bear their costs for the proceedings,
                although the arbitrators may, in their sole discretion, direct
                either Party to bear the entire cost of the proceedings. The
                arbitration shall be conducted in English, and the seat of
                Arbitration shall be in the city of Mumbai . The arbitral
                process shall follow the applicable laws of India.
              </span>
            </p>

            <p>
              The Parties expressly agree that the Privacy Policy, Terms of Use
              and any other agreements entered into between the Parties, such as
              Vendor Agreement are governed by the laws, rules, and regulations
              of India, and that the Courts at Mumbai shall have exclusive
              jurisdiction over any disputes arising between the Parties.{" "}
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              13. MARKETING COMMUNICATIONS{" "}
            </p>
            <p>
              We will respect your wishes not to receive marketing
              communications. You can change your marketing preferences by
              contacting us at legal@transpost.co . Please note that we will
              continue sending you service-related communications regardless of
              any opt-out request.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              14. RECORDED COMMUNICATIONS{" "}
            </p>
            <p>
              We may record calls for training, quality and research purposes.
              All recordings will be treated as confidential. If you do not want
              us to record your call, you will be given a chance to opt out. If
              your call is already in progress, you may ask us to turn off the
              recording at the start of the call.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              15. LOCATION SERVICES FOR MOBILE APPS{" "}
            </p>
            <p>
              Your (users’) current location is only determined if you choose to
              use a function on our apps to determine your current location. If
              you allow your location to be obtained using an Transpost app,
              Transpost will use the services of Google Maps API to determine
              your estimated location. Use of this Information is solely to
              distinguish your current location and not to identify you.
              Transpost does not automatically track your location when you use
              our apps. Transpost uses the services of Google Maps API for
              location services. The Google privacy policy is available at
              https://www.google.com/policies/privacy and by this reference, is
              incorporated into this Transpost Privacy Policy.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              16. ACCESS TO YOUR PERSONAL INFORMATION{" "}
            </p>
            <p>
              You have the right to submit a request for your personal
              information that we have as well as submit a request to correct or
              amend any personal information that you feel is incorrect or
              incomplete. You also have the right to request for deleting any of
              your personal information we have collected unless we are exempt
              from honouring your request under the law. We will not
              discriminate against you for exercising any rights you have. You
              may submit your requests at legal@transpost.co .
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              17. CONFIDENTIALITY AND SECURITY{" "}
            </p>
            <p>
              We restrict access to your Information to employees who we have
              determined need it in order to provide products or services to
              you. We train our employees to safeguard customer information, and
              we require them to sign confidentiality and non-disclosure
              agreements. We maintain strict physical, electronic, and
              procedural safeguards to protect your Information from
              unauthorized access by third parties.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              18. INFORMATION WE COLLECT AUTOMATICALLY
            </p>
            <p>
              When you use the Services, we (or our third party partners) may
              collect information about your device and usage of the Services.
              We may use a variety of technologies (collectively, “Tracking
              Technologies”) to collect some of this information.
            </p>
            <p>
              We may use Tracking Technologies to automatically collect the
              information described below:{" "}
            </p>

            <ul className="list-disc list-inside">
              <li>
                 <span> Device Information:</span>{" "}
                <span>
                  {" "}
                  We may collect certain information about the device you use to
                  access the Services, including but not limited to IP addresses
                  for your devices, unique device identifiers, browser types,
                  and browser language.{" "}
                </span>
              </li>
              <li>
                <span>User History</span>{" "}
                <span>
                  {" "}
                  We may log certain usage information about your use of the
                  Services, which may include a history of the pages you view.
                  If you download and use any Transpost software or application
                  on your device, the log files will automatically be collected
                  by the application and sent to our servers when your device
                  syncs up for the latest content available.
                </span>
              </li>
              <li>
                <span> Mobile/Location Information: </span>{" "}
                <span>
                  We may collect additional information from you if you access
                  the Services through a mobile device, for example we may
                  collect your unique device identifier, your device’s operating
                  system, mobile carrier, or your location when you opt in for
                  us to do so. However the degree to which your location can be
                  identified depends on the device you are using (e.g. laptop,
                  smart phone, tablet) and how you are connected to the internet
                  (e.g. via cable broadband connection, Wi-Fi, etc.). If you
                  enable location services for the Services we may collect your
                  regional location data periodically as you use or leave open
                  the Services. We may also collect your location information
                  from your IP address or zip code.
                </span>
              </li>
            </ul>
            <p>
              We may also use location data internally or in conjunction with
              our third party service providers to customize your experience and
              provide offers that may be relevant to you. Depending on the
              device you use to access the Services (e.g. Apple™ iOS, Google™
              Android, Windows, etc.) you may be able to control whether
              location data is collected from the settings on your wireless
              device.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              19. SUSPENSION DUE TO NON-COMPLIANCE WITH AGREEMENT{" "}
            </p>
            <p>
              Transpost will make all possible efforts to provide you the best
              services for your payment. However, Transpost shall not incur any
              liability and shall declare any service guarantee void in event of
              one or more of the following circumstances:
            </p>
            <p>
              If you have not provided accurate details including contact
              information (user’s email and/or phone number), username and
              Payment account information.
            </p>
            <p>
              If the transaction for payment doesn’t go through with your bank
              due to (including but not limited) insufficient funds, fraudulent
              access, failure of processing the request by our Affiliate
              merchants or your bank.
            </p>
            <p>
              If circumstances beyond the control of Transpost (such as, but not
              limited to, fire, flood, or interference from external forces)
              prevent proper execution of the transaction.
            </p>
            <p>
              If the user is in breach of any of the Terms and Conditions of
              this Agreement and/or the Terms and Conditions of the usage of
              Transpost services.
            </p>
          </li>
          <li className="space-y-2">
            <p className="font-bold text-[1.2em] text-primary-1000 bg-primary-1000  whitespace-normal mr-2">
              20. GRIEVANCE OFFICER{" "}
            </p>
            <p>
              In accordance with Rule 5(9) of the Information Technology
              (Reasonable Security Practices, Procedures and Sensitive Personnel
              Data or Information) Rules, 2011, the name and contact details of
              the Grievance Officer (or Data Controller, as applicable), who can
              be contacted for any complaints or concerns pertaining to the
              website, including those pertaining to breach of the Terms of Use
              or and other polices, or other regulatory compliances is published
              as under:{" "}
            </p>
          </li>
        </ul>
        <p className="pt-5 text-primary-700 flex flex-col">
          <span>Mr. Masood Ahmed , Data Controller/ Grievance Officer:</span>
          <span className="pt-4">Thanking You,</span>
          <span>Team: info@transpost.co</span>
        </p>
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;

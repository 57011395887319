import React, { FC, useEffect, useState } from "react"

import BookingCard from "./BookingCard"
import { Link } from "react-router-dom";
import QuoteCard from "./QuoteCard";
import DashboardCardSekelton from "components/SkeletonComponents/DashboardCardSekelton";

interface BookingListProps {
    quotesList?: [];
    all?: boolean;
    loading?: boolean;
}

const QuoteList: FC<BookingListProps> = ({
    quotesList,
    all,
    loading
}) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [typing, setTyping] = useState({
        name: "",
        typing: false,
        typingTimeout: 0,
    });
    return (<>
        <h2 className="font-bold " style={{ color: "#2aa996" }}>All Quotes <div className="flex items-center border-b border-teal-500 float-right">
            <input className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text" placeholder="Search Here" aria-label="Full name" onChange={(e) => setTyping({
                    name: e.target.value,
                    typing: false,
                    typingTimeout: window.setTimeout(function () {
                        setSearchTerm(e.target.value);
                    }, 1000),
                })} />
        </div></h2>
        <div className=" w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 p-5 gap-6 bg-white dark:text-neutral-300  dark:bg-neutral-800 ">

            {/* <main className="mt-11 mb-24 lg:mb-32 flex flex-col lg:flex-row"> */}

            {loading && [1, 2, 3].map(() => {
                return <DashboardCardSekelton />
            })}

            {!loading && quotesList && searchTerm.length > 0 ? quotesList.map((quote) => {
                let includes = false
                Object.keys(quote).forEach(quoteKey => {
                    let compare = String(quote[quoteKey]).toLocaleLowerCase()
                    if (compare.includes(searchTerm.toLocaleLowerCase())) includes = true
                })
                if (includes) return (<div className="w-full h-full  "><QuoteCard quote={quote} /></div>);
            }).reverse() : quotesList!.map((quote, index) => {
                return (<div className="w-full h-full  "><QuoteCard quote={quote} /></div>)
            }).reverse()}

            {!loading && quotesList?.length === 0 && "No Quotes Available"}



            {/* </main> */}
        </div>

    </>);
}

export default QuoteList;
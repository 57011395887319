import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Loader from "new_component/Loader";
import { securePostRequest, secureUploadRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import { useEventContext } from "utils/contexts/eventContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import Select from "shared/Select/Select";
import { secureUploadFiles } from "utils/helpers/apiGenerator";

const EditCompany = ({ onEditChange, updateCompany, profileDetails }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [companyObj, setCompanyObj] = useState({
    companyName: "",
    customer_type: "",
    enterprise_type: "",
  });

  const { userState, setUserState } = useUserDetails();
  const { setError } = useEventContext();

  const uploadCompanyLogo = async () => {
    const formData = new FormData();
    if (selectedFile) {
      formData.append("company_logo", selectedFile);

      try {
        const res = await secureUploadRequest(
          `${apiPaths.user.file.companyLogo + "/" + userState?.customer.ID}`,
          formData
        );
        console.log(res.data.customer.company_logo);
        return res.data.customer.company_logo;
      } catch (error: any) {
        setIsLoading(false);
        onEditChange("companyDetails", false);
        setError({ ...error.response.data, show: true });
      }
    }
  };

  
  const updateHandler = async (e: any) => {
    e.preventDefault();
    // Call update user API

    // Seperate API call for uploading logo

    setIsLoading(true);

    let new_company_logo = await uploadCompanyLogo();
    try {
      const updatedUser = await securePostRequest(
        apiPaths.user.profiledetails,
        {
          ...profileDetails,
        }
      );
      userState &&
        setUserState({
          ...userState,
          customer: {
            ...userState.customer,
            companyName: updatedUser?.data?.data.companyName,
            customer_type: updatedUser?.data?.data.customer_type,
            EnterpriseType: updatedUser?.data?.data.enterprise_type,
            company_logo: new_company_logo || userState.customer.company_logo,
          },
        });
      setIsLoading(false);
      onEditChange("companyDetails", false);
    } catch (error: any) {
      setIsLoading(false);
      onEditChange("companyDetails", false);
      setError({ ...error.response.data, show: true });
    }
  };
  // updating companyObj on change
  useEffect(() => {
    updateCompany(companyObj);
  }, [
    companyObj.companyName,
    companyObj.customer_type,
    companyObj.enterprise_type,
  ]);
  // should get customer type from userState
  useEffect(() => {
    if (userState) {
      setCompanyObj({
        ...companyObj,
        companyName: userState?.customer.companyName || "",
        customer_type: userState?.customer.customer_type || "",
        enterprise_type: userState?.customer.EnterpriseType || "",
      });
    }
  }, [userState]);

  return (
    <form
      onSubmit={updateHandler}
      className="grid h-full grid-cols-1 md:grid-cols-2 gap-6 my-2"
    >
      <label className="block">
        <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
          Company Name
        </span>
        <Input
          type="text"
          placeholder="Change your company name"
          className="mt-1"
          value={companyObj.companyName}
          onChange={(e: any) => {
            setCompanyObj({ ...companyObj, companyName: e.target.value });
          }}
        />
      </label>

      <label className="block">
        <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
          Business Type
        </span>
        <Select
          defaultValue={userState?.customer.customer_type}
          onChange={(e: any) => {
            setCompanyObj({ ...companyObj, customer_type: e.target.value });
          }}
          className="mt-1"
        >
          <option value="Shipper">Shipper</option>
          <option value="Freight Forwarder">Freight Forwarder</option>
          <option value="Custom Broker">Custom Broker</option>
        </Select>
      </label>
      <label className="block">
        <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
          Enterprise Type
        </span>
        <Select
          defaultValue={userState?.customer.EnterpriseType || ""}
          onChange={(e: any) => {
            setCompanyObj({ ...companyObj, enterprise_type: e.target.value });
          }}
          className="mt-1"
        >
          <option value="MSME">MSME</option>
          <option value="Non-MSME">NON-MSME</option>
        </Select>
      </label>
      <label className="block">
        <span className="text-neutral-800 font-semibold dark:text-neutral-200">
          Company Logo
        </span>
        <Input
          type="file"
          className="mt-1"
          onChange={(e: any) => {
            setIsSelected(true);
            setSelectedFile(e.target.files[0]);
          }}
        />
      </label>
      <div></div>
      <div className="ml-auto mt-6 gap-4 flex w-full  flex-col   md:flex-row">
        <ButtonPrimary type="submit" className=" w-full">
          {isLoading ? <Loader className="w-6 h-6" /> : "Update"}
        </ButtonPrimary>
        <ButtonPrimary
          type="button"
          onClick={() => onEditChange("companyDetails", false)}
          className="  w-full  bg-[#F7961D] hover:bg-[#F37911] text-[#000000] "
        >
          Cancel
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default EditCompany;

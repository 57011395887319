
const useQueryParams = (queryString: string): [string] => {

    var search = queryString.substring(1);

    return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')

}


export default useQueryParams;
import React, { FC } from 'react';

interface coordinateProps {
    x?: number;
    y?: number;
}

const Coordinate: FC<coordinateProps> = ({ x, y }) => {


    return (<><div style={{
        position: 'absolute',
        left: `${x}px`,
        top: `${y}px`,
        width: '10px',
        height: '10px',
        borderRadius: "50%",
        background: 'red',
        zIndex: "1 !important"
    }}></div></>);
};

export default Coordinate;

import React, { FC } from "react"
import { IBookingData } from "./bookingTimelineTypes";

interface UserBookingPaymentDetailsProps {
    bookingDetails: IBookingData
}

const UserBookingPaymentDetails: FC<UserBookingPaymentDetailsProps> = ({
    bookingDetails
}) => {

    return (
        <>
            <div className="border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                <div className="flex-1 p-5 flex justify-between space-x-5">
                    <div className="flex flex-col space-y-1">
                        {/* <span className="text-md text-neutral-600">
                            Invoice Status :{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.InvoiceStatus

                                    ? bookingDetails!.data?.InvoiceStatus

                                    : "NA"}
                            </span>
                        </span> */}
                       {bookingDetails && bookingDetails.data?.InvoiceSentDate && <><span className="text-md text-neutral-600">
                            Invoice Date :{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.InvoiceSentDate

                                    ? bookingDetails!.data?.InvoiceSentDate

                                    : "NA"}
                            </span>
                        </span>
                        <span className="text-md text-neutral-600">
                            Invoice :{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.FinalInvoice !== ""

                                    ? <a className=" underline text-" href={"https://tsrmain.s3.ap-south-1.amazonaws.com/" + bookingDetails!.data?.FinalInvoice} download target="_blank">
                                        {bookingDetails!.data?.FinalInvoice?.length === 0 ? "" : "Download Invoice"}
                                    </a>

                                    : "NA"}
                            </span>
                        </span></>}
                    </div>

                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                </div>
                <div className="flex-1 p-5 flex justify space-x-5">
                    <div className="flex flex-col space-y-1">
                        {bookingDetails!.data?.PaymentStatus === "2" && <span className="text-md text-neutral-600">
                            Payment Due:{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.PaymentDue === "" ? "NA" : bookingDetails!.data?.sellcurrency + bookingDetails!.data?.PaymentDue! }
                            </span>
                        </span>}
                        <span className="text-md text-neutral-600">
                            Payment Status:{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.PaymentStatus === "1" && <span className="text-red-600">Payment Pending</span>}
                                {bookingDetails && bookingDetails!.data?.PaymentStatus === "2" && <span className="text-yellow-600">Partially Paid</span>}
                                {bookingDetails && bookingDetails!.data?.PaymentStatus === "3" && <span className="text-green-600">Payment Recieved</span>}

                            </span>
                        </span>

                        <span className="text-md text-neutral-600">
                            Payment Date:{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.PaymentDate === "" ? "NA" : bookingDetails!.data?.PaymentDate}
                            </span>
                        </span>
                        <span className="text-md text-neutral-600">
                            BL Type:{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.BLstatus === "" ? "NA" : bookingDetails!.data?.BLstatus}
                            </span>
                        </span>

                        {bookingDetails && bookingDetails.data?.BLSentdate && <span className="text-md text-neutral-600">
                            BL:{" "}
                            <span className="font-semibold">
                                {bookingDetails && bookingDetails!.data?.FinalBLStatus === "0" ? "NA" : <a className=" underline text-" href={"https://tsrmain.s3.ap-south-1.amazonaws.com/" + bookingDetails!.data?.FinalBL} download target="_blank">
                                    {bookingDetails!.data?.FinalBL?.length === 0 ? "" : "Download BL"}</a>

                                }
                            </span>
                        </span>}

                    </div>
                    {/* <PencilAltIcon className="w-6 h-6 text-neutral-300 dark:text-neutral-6000" /> */}
                </div>

            </div>
        </>
    );

}

export default UserBookingPaymentDetails;
import React, {  useEffect, useState } from "react";


import {Helmet} from "react-helmet"
import CommonLayout from "containers/AccountPage/CommonLayout";
import emptyicon from "../images/transpost images/dashboard/emptyPng.png";

import BookingCard from "./BookingCard";
import Loader from "./Loader";
import CommonSidebar from "./CommonSidebar";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import apiPaths from "utils/paths/apiPaths";
import { secureGetRequest } from "utils/helpers/apiVerbs";


export interface BookingCardProps {
  data: {
      ID?: string;
      DateOfBooking?: string;
      ContainerType?: string;
      ShippingLineName?: string;
      name?: string;
      ContainerCount?: string;
      BookingNo?: string;
      POL?: string;
      POD?: string;
      pol: {
          country: string;
          port_code: string;
          port_name: string;
      };
      pod: {
          country: string;
          port_code: string;
          port_name: string;
      };
      cs_status: {
          name: string;
          template: string;
      };
  };
}


const Dashboard = () => {
  const [bookingList, setBookingList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [pendingList, setPendingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [activeTab, setActiveTab] = useState("All");
  const [status, setStatus] = useState("");

  const { user } = useUserAuth();
  const { userState } = useUserDetails();

  const fetchData = async () => {
    try {
      const bookings = await secureGetRequest(apiPaths.bookings.recent, {
        email: userState?.customer.email,
      })
      const result = bookings.data;

      setStatus(result.status);
      setBookingList(result.data);
      setAllList(result.data);

      if (result.data.length > 0) {
        setCompletedList(
          result.data.filter((v: any) => {
            const { status } = v;
            return status === "8";
          })
        );
        setPendingList(
          result.data.filter((v: any) => {
            const { status } = v;
            return status !== "8";
          })
        );
      }

    } catch (err: any) {

    }
  };

  useEffect(() => {
    if (userState) {
      fetchData();
    }
  }, [userState]);

  if (!userState) {
    return null;
  }

  // for count set all in initial rendering

  const pendingBookings = () => {
    if (bookingList.length > 0) {
      setBookingList(
        allList.filter((v) => {
          const { status } = v;
          return status !== "8";
        })
      );
      setActiveTab("Pending");
    }
  };

  const completedBookings = () => {
    if (bookingList.length > 0) {
      setBookingList(
        allList.filter((v) => {
          const { status } = v;
          return status === "8";
        })
      );
      setActiveTab("Completed");
    }
  };

  const renderSidebar = () => {
    return <CommonSidebar />;
  };

  const renderSection1 = () => {
    return (
      // <div className="listingSection__wrap">
      <div>
        <Helmet>
          <title>
            Dashboard | Transpost
          </title>
        </Helmet>
        <div className="border-b border-neutral-200 dark:border-neutral-700  dark:bg-transparent">
          <div className="container">
            <div className="flex space-x-8 md:space-x-14 overflow-x-auto hiddenScrollbar">
              <button
                onClick={() => {
                  setBookingList(allList);
                  setActiveTab("All");
                }}
                className={`block py-1 md:py-2 border-b-2 text-sm md:text-base border-transparent flex-shrink-0 ${activeTab == "All" ? "border-b-2 border-primary-500" : ""
                  }`}
              >
                All Bookings (
                <span className="font-semibold">{allList.length}</span>)
              </button>
              <button
                onClick={pendingBookings}
                className={`block py-1 md:py-2 border-b-2 border-transparent text-sm md:text-base flex-shrink-0 ${activeTab == "Pending" ? "border-b-2 border-primary-500" : ""
                  }`}
              >
                Pending (
                <span className="font-semibold">{pendingList.length}</span>)
              </button>
              <button
                onClick={completedBookings}
                className={`block py-1 md:py-2 text-sm md:text-base border-b-2 border-transparent flex-shrink-0 ${activeTab == "Completed"
                  ? "border-b-2 border-primary-500"
                  : ""
                  }`}
              >
                Completed (
                <span className="font-semibold">{completedList.length}</span>)
              </button>
            </div>
          </div>
        </div>
      </div>
      // </div>
    );
  };

  const renderSection2 = () => {
    if (bookingList.length === 0 && status.length === 0) {
      return (
        <div className="mt-12">
          <Loader className="m-[100px] w-full h-[100px]"></Loader>
        </div>
      );
    }
    return (
      <div className="listingSection__wrap w-full    bg-white dark:text-neutral-300  dark:bg-neutral-800 ">
        {/* HEADING */}
        {bookingList.length > 0 ? (
          <>
            {bookingList.map((item, i) => {
              return <BookingCard data={item} key={i} />;
            })}
          </>
        ) : (
          <div className="h-screen  flex flex-col justify-center items-center dark:bg-neutral-800 ">
            <img src={emptyicon} className="h-[4rem] w-[4rem]" alt="" />

            <div className="text-2xl text-centre font-semibold">
              You don't have any cargoes booked with you yet.
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <CommonLayout>
      <main className="container mt-1 mb-24 lg:mb-20 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-auto md:w-[70%] space-y-5 lg:pl-5 flex-shrink-0">
          {renderSection1()}
          {renderSection2()}
        </div>
      </main>
    </CommonLayout>
  );
};

export default Dashboard;

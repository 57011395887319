/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Sheikh
 * Created on Tue May 21 2022
 * Created at 11:54:24 PM
 * File Content:
 * *================*
 * *================*
 * Register / Sign Up Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 21 2023
 * Updated at 12:54:46 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:30 PM
 *
 */

import React, { FC, useState, useEffect } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link, useHistory } from "react-router-dom";
import { useEventContext } from "utils/contexts/eventContext";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "new_component/Loader";
import { useUserAuth } from "utils/contexts/userContext";
import Label from "components/Label/Label";
import { useRedirectContext } from "utils/contexts/redirectContext";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
  username: Yup.string().required("Username is Required"),
  companyName: Yup.string().required("Company name is Required"),
  phone: Yup.string().required("Phone number is Required"),
  gst: Yup.string()
    .test("len", "GST number should be of 15 digits", val => val?.length === 15 )
    .required("GST details required"),
  pan: Yup.string()
    .test("len", "Pan number should be of 10 digits", val => val?.length === 10)
    .required("PAN details required"),
  terms_condition: Yup.bool().oneOf(
    [true],
    "Please accept the Terms and conditions"
  ),
});

export interface PageSignUpProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const defaultValue = {
  username: "",
  companyName: "",
  password: "",
  gst: "",
  pan: "",
  email: "",
  phone: "",
  customer_type: "Shipper",
  terms_condition: false,
};

export interface IsignUpForm {
  username?: string;
  companyName?: string;
  password?: string;
  gst?: string;
  pan?: string;
  email?: string;
  phone?: string;
  customer_type?: string;
  terms_condition?: boolean;
}

const PageSignUp: FC<PageSignUpProps> = ({ className = "" }) => {
  const [signUpForm, setSignUpForm] = useState<IsignUpForm>(defaultValue);
  const { redirect, makeRedirect } = useRedirectContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IsignUpForm>({
    resolver: yupResolver(validationSchema),
  });
  const [isLoading, setisLoading] = useState(false);

  const { setError, setIsLoading } = useEventContext();
  const { commonGoogleLogin, serverSignUp, serverLogin, isLogin } =
    useUserAuth();
  const history = useHistory();

  // Google SignUp
  const logGoogleUser = async () => {
    setIsLoading(true);

    try {
      let signUp = await commonGoogleLogin();

      if (signUp.error) throw signUp.err;

      if (!signUp.data.KYC) {
        history.push(
          "/update?email=" +
            signUp.data.customer.email +
            "&name=" +
            signUp.data.customer.contact_person +
            "&google=true"
        );
      } else {
        if (redirect.isRedirect) makeRedirect();
        else history.push("./");
      }
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
      console.log(err.response);
      setError({
        message: "Unable to register via google",
        show: true,
        status: "error",
      });
    }

    setIsLoading(false);
  };

  // Create user with form
  const submitHandler: SubmitHandler<IsignUpForm> = async (
    data: IsignUpForm
  ) => {
    setisLoading(true);
console.log("data", data)
    try {
      const signUp = await serverSignUp({
        ...data,
        // customer_type: signUpForm.customer_type,
        google: false
      });

      
      if(signUp.error) throw signUp.err;

      const logIn = await serverLogin(data.email, data.password);
      setisLoading(false);

      if (logIn.error) throw logIn.err;

      if (redirect.isRedirect) makeRedirect();
      else history.push("./");

    } catch (error: any) {

      setisLoading(false);
      setError({
        ...error, show: true
      });
    }
  };

  useEffect(() => {
    if (isLogin.login) {
      if (redirect.isRedirect) makeRedirect();
      else history.push("./");
    }
  }, []);

  return (
    <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
      <Helmet>
        <title>Sign Up || Transpost || Register</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-5 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Sign Up
        </h2>
        <div className="max-w-md mx-auto space-y-6 ">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <div
                key={index}
                onClick={logGoogleUser}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px] cursor-pointer"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </div>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={handleSubmit(submitHandler)}
          >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Full Name <span>*</span>
              </span>
              <Input
                id="username"
                type="text"
                placeholder="Enter your Full Name"
                className="mt-1"
                {...register("username")}
              />
              {errors.username && (
                <p className="text-red-500 p-2">{errors.username.message}</p>
              )}
            </label>

            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Phone number <span>*</span>
              </span>
              <Input
                id="phone"
                type="number"
                placeholder="Enter your Phone number"
                className="mt-1"
                {...register("phone")}
              />
              {errors.phone && (
                <p className="text-red-500 p-2">{errors.phone.message}</p>
              )}
            </label>
            <label className="block" htmlFor="customer_type">
              <span className="text-neutral-800 dark:text-neutral-200">
                Business Type <span>*</span>
              </span>

              <select
                id="customer_type"
                className={`nc-Select block w-full h-11 mt-1.5 text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                {...register("customer_type", { required: true })}
              >
                <option value="-1">Business Type</option>
                <option value="Shipper">Shipper</option>
                <option value="Freight Forwarder">Freight Forwarder</option>
                <option value="Custom Broker">Custom Broker</option>
              </select>
            </label>

            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                GST Number *
              </span>
              <Input
                id="gst"
                type="text"
                placeholder="Enter your GST number"
                className="mt-1"
                {...register("gst")}
              />
              {errors.gst && (
                <p className="text-red-500 p-2">
                  {errors.gst.message}
                </p>
              )}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                PAN Number *
              </span>
              <Input
                id="pan"
                type="text"
                placeholder="Enter your PAN number"
                className="mt-1"
                {...register("pan")}
              />
              {errors.pan && (
                <p className="text-red-500 p-2">{errors.pan.message}</p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Company Name <span>*</span>
              </span>
              <Input
                id="companyName"
                type="text"
                className="mt-1"
                placeholder="Enter your Company Name"
                {...register("companyName")}
              />
              {errors.companyName && (
                <p className="text-red-500 p-2">{errors.companyName.message}</p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address <span>*</span>
              </span>
              <Input
                id="email"
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                {...register("email")}
              />
              {errors.email && (
                <p className="text-red-500 p-2">{errors.email.message}</p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Password <span>*</span>
              </span>
              <Input
                id="password"
                type="password"
                placeholder="Enter your Password"
                className="mt-1"
                {...register("password")}
              />
              {errors.password && (
                <p className="text-red-500 p-2">{errors.password.message}</p>
              )}
            </label>
            <Label className=" text-sm sm:text-base">
              <span className="flex space-x-3">
                <input
                  className="mt-0.5 cursor-pointer focus:ring-action-primary h-4 w-4 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  type="checkbox"
                  {...register("terms_condition")}
                />
                <p className="ml-3.5 ">
                  I agree to the{" "}
                  <Link to="/terms-condition" target="_blank">
                    Terms and condition{" "}
                  </Link>
                </p>
              </span>
              {errors.terms_condition && (
                <p className="text-red-500  p-2">
                  {errors.terms_condition.message}
                </p>
              )}
            </Label>

            <ButtonPrimary type="submit">
              {isLoading ? <Loader className="h-6 w-8" /> : "Continue"}
            </ButtonPrimary>
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to={"/login"}>Sign in</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageSignUp;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Wed Feb 15 2023
 * Updated on Wed Feb 15 2023
 * File Content:
 * *================*
 * *================*
 * Cargo Insuarance Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Apr 03 2023
 * Updated at 18:34:01 PM
 *
 */


/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Feb 27 2023
 * Updated at 6:14:07 PM
 *
 */

import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CompanyDetailsForm from "./CompanyDetailsForm";
import BillingAddressForm from "./BillingAddressForm";
import CargoInfoForm from "./CargoInfoForm";
import axios from "axios";
import { useEventContext } from "utils/contexts/eventContext";
import Loader from "new_component/Loader";

export interface ICargoInsuranceForm {
  email: string;
  type: string;
  institution_name: string;
  address: string;
  city: string;
  state: string;
  pincode: string;
  party_name: string;
  billing_address: string;
  billing_city: string;
  billing_state: string;
  billing_pincode: string;
  gstin: string;
  cargo_description: string;
  commodity: string;
  transit_mode: string;
  location_from: string;
  location_to: string;
  coverage_start_date: string;
  seller_details: string;
  buyer_details: string;
  reference_no: string;
  additonal_details: string;
  premium: string;
  readonly_fields: string;
}
const defaultValue = {
  type: "Export",
  institution_name: "",
  address: "",
  city: "",
  state: "",
  pincode: "",
  party_name: "",
  billing_address: "",
  billing_city: "",
  billing_state: "",
  billing_pincode: "",
  gstin: "",
  cargo_description: "",
  commodity: "",
  transit_mode: "",
  location_from: "",
  location_to: "",
  coverage_start_date: "",
  seller_details: "",
  buyer_details: "",
  reference_no: "",
  additonal_details: "",
  premium: "",
  readonly_fields: "",
};
const validationSchema = Yup.object().shape({
  type: Yup.string(),
});

const CargoInsurancePage = () => {
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [showBillingAddress, setShowBillingAddress] = useState(false);
  const [showCargoInfo, setShowCargoInfo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm<ICargoInsuranceForm>({
    defaultValues: defaultValue,
    resolver: yupResolver(validationSchema),
  });

  const { setError } = useEventContext();

  const submitHandler: SubmitHandler<ICargoInsuranceForm> = (
    cargoInsData: ICargoInsuranceForm
  ) => {
    console.log(cargoInsData);

    setIsLoading(true);
    var formData = new FormData();

    formData.append("unique_id", new Date().getTime().toString());
    formData.append("email", "masoodahmed@transpost.co");
    formData.append("response_url", "https://transpost.co/");
    formData.append("type", cargoInsData.type);
    formData.append(
      "token",
      "b8ccfac0ee8fa998:UPKuQjCbxJD4GFxmmRQ98lbmXopCtjXcHa/mRp9nMQ8="
    );
    formData.append("api_key", "ffbe46b5-48e6-4a7c-b41c-f59f5b512141");

    axios
      .post("https://uat.dgnote.com/insurance/api", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (res: any) {
        setIsLoading(false);
        console.log(JSON.stringify(res.data));
        const dgNoteDiv = document.getElementById("dg-note-form");
        if (dgNoteDiv) dgNoteDiv.innerHTML = res.data;
        var scripts = Array.prototype.slice.call(
          dgNoteDiv?.getElementsByTagName("script")
        );
        for (var i = 0; i < scripts.length; i++) {
          // eslint-disable-next-line no-eval
          eval(scripts[i].innerHTML);
        }
      }
      )
      .catch((err) => {
        setIsLoading(false);
        console.log("ERROR", err);
        setError({ message: err.message, status: "error", show: true });
      });
    console.log("CargoInsurance Form", cargoInsData);
  };

  useEffect(() => {
    reset({ ...defaultValue });
  }, []);

  return (
    <div>
      <Helmet>
        <title>Cargo Insurance | Transpost</title>
      </Helmet>
      <h2 className="my-5 flex items-center text-2xl leading-[115%] md:text-3xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        General Cargo Insurance
      </h2>
      <div className="container mb-24 lg:px-32 lg:mb-32">
        <form
          onSubmit={handleSubmit(submitHandler)}
          className=" space-y-4 py-6"
        >
          <h2 className=" bg-neutral-200 px-3 py-3 rounded-t-lg border-b border-neutral-400 font-semibold">
            General Info{" "}
          </h2>

          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Type of Shipment
            </span>
            <select
              id="type"
              className={`nc-Select block w-full mt-1.5 h-11 text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
              {...register("type")}
            >
              <option value="Container" selected>
                Empty Container
              </option>
              <option value="Import">
                Import - Import from third country to India
              </option>
              <option value="Export">
                Export - Export from India to third country
              </option>
              <option value="Inland">
                Inland - Last leg of import from India to India
              </option>
            </select>
          </label>
          <h2
            onClick={() => setShowCompanyDetails(!showCompanyDetails)}
            className="relative  bg-neutral-200 px-3 py-3 rounded-t-lg border-b border-neutral-400 font-semibold pb-3 cursor-pointer"
          >
            <span
              className={`absolute right-2 bottom-0 sm:bottom-auto top-1/2 -translate-y-1/2 w-8 h-8 bg-neutral-50 dark:bg-neutral-800 ease-in-out duration-300 rounded-full flex items-center justify-center cursor-pointer ${
                showCompanyDetails ? "transform -rotate-180" : ""
              }`}
            >
              <i className="text-xl las la-angle-down"></i>
            </span>
            Company Details
          </h2>
          {
            <CompanyDetailsForm
              showCompanyDetails={showCompanyDetails}
              register={register}
            />
          }

          <h2
            onClick={() => setShowBillingAddress(!showBillingAddress)}
            className="relative  bg-neutral-200 px-3 py-3 rounded-t-lg border-b border-neutral-400 font-semibold pb-3 cursor-pointer"
          >
            <span
              className={`absolute right-2 bottom-0 sm:bottom-auto duration-300 ease-in-out top-1/2 -translate-y-1/2 w-8 h-8 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                showBillingAddress ? "transform -rotate-180" : ""
              }`}
            >
              <i className="text-xl las la-angle-down"></i>
            </span>
            Billing Address
          </h2>
          {
            <BillingAddressForm
              showBillingAddress={showBillingAddress}
              register={register}
            />
          }
          <h2
            onClick={() => setShowCargoInfo(!showCargoInfo)}
            className="relative col-span-3 bg-neutral-200 px-3 py-3 rounded-t-lg border-b border-neutral-400 font-semibold pb-3 cursor-pointer"
          >
            <span
              className={`absolute right-2 bottom-0 duration-300 ease-in-out sm:bottom-auto top-1/2 -translate-y-1/2 w-8 h-8 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                showCargoInfo ? "transform -rotate-180" : ""
              }`}
            >
              <i className="text-xl las la-angle-down"></i>
            </span>
            Cargo Info
          </h2>
          {<CargoInfoForm showCargoInfo={showCargoInfo} register={register} />}
          <ButtonPrimary 
          className="w-1/4"
          // type="submit"
          >
            {isLoading ? <Loader className="h-6 w-6" /> : "Continue"}
          </ButtonPrimary>
        </form>
      </div>
    </div>
  );
};

export default CargoInsurancePage;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import NcImage from "shared/NcImage/NcImage";


const CompanyDetailsCard = ({ customer, onEditChange }: any) => {
  
  console.log(customer.company_logo);
  
    return (
    <>
      <div className="relative  mt-5 mr-5">
        <button
          className="absolute top-0 right-0 hover:scale-110 translate-x-2 transform ease-in-out duration-200 "
          onClick={() => onEditChange("companyDetails", true)}
        >
          Edit <FontAwesomeIcon icon={faEdit} />
        </button>
        <div className="grid h-full grid-cols-1 md:grid-cols-2 gap-6 my-2">
          <label className="block">
            <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
              Company Name
            </span>
            <p>{customer?.companyName ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Business Type
            </span>
            <p>{customer.customer_type ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Enterprise Type
            </span>
            <p>{customer.EnterpriseType ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Company Logo
            </span>
            <NcImage 
            containerClassName=""
            className="w-1/2 aspect-[12/4]"
            
            src={customer.company_logo || ""}/>
          </label>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsCard;

import React, { FC, ReactNode, useEffect } from "react";

import hero7 from "images/transpost images/heros/Home/hero.svg";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeroSearchForm, { SearchTab } from "./HeroSearchForm";
import { ReactComponent as StoremartLogo } from "../../images/transpost images/Logos/StoreMart Logo.svg";
import logo from "../../images/transpost images/Logos/storemart.png";

import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useHistory } from "react-router-dom";
import useWindowSize from "hooks/useWindowResize";
import { useEventContext } from "utils/contexts/eventContext";
import TranspostLoader from "new_component/TranspostLoader/TranspostLoader";
import SectionGridFilterCard from "./SectionGridFilterCard";

export interface SectionHeroArchivePageProps {
  className?: string;
  listingType?: ReactNode;
  currentPage:
    | "Stays"
    | "Cargo Tracker"
    | "Cars"
    | "Ocean"
    | "Warehousing & Disitribution";
  currentTab: SearchTab;
  rightImage?: string;
}

const SectionHeroArchivePage: FC<SectionHeroArchivePageProps> = ({
  rightImage = hero7,
}) => {

  const location = useHistory();
  const windowSize = useWindowSize();

  const { isLoading } = useEventContext();

  return (
    <div
      className={`nc-SectionHeroArchivePage flex flex-col relative pb-10 lg:pb-1 `}
      data-nc-id="SectionHeroArchivePage"
    >
      {isLoading && <TranspostLoader />}
      <div className="flex flex-col lg:flex-row  px-[12rem] lg:pb-[5.3rem] lg:items-center">
        <div className=" hidden lg:flex  flex-grow ">
          <img className="w-full pb-10 object-fill" src={rightImage} alt="hero" />
        </div>
      </div>
      <div className="relative ">

      <div className="flow-root w-full">
        {/* for moving the search bar up and down */}
        <div className="z-10 lg:-mt-40 xl:-mt-18 w-full">
          <HeroSearchForm />
          <SectionGridFilterCard  showLoading={false} rateList={{}}/>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SectionHeroArchivePage;

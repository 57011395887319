import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useHistory } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface QuoteCardProps {
  search?: {
    cargo_type?: string;

    created_at?: string;

    customer_id?: number;

    from_port?: string;

    id?: number;

    to_port?: string;

    updated_at?: string;

    validity?: string;
  };
}

const SearchCard: FC<QuoteCardProps> = ({ search }) => {
  const history = useHistory();

  const validity = moment(String(search?.validity));
  const generatedDate = moment(String(search?.created_at));

  return (
    <>
      <div className=" bg-[#fff] w-full h-full border p-3 rounded-lg drop-shadow-md hover:drop-shadow-xl cursor-pointer dark:border-neutral-500 dark:bg-neutral-700 ">
        <div
          className="flex font-bold text-sm p-2 content-center border-zinc-400 m-0"
          style={{ color: "#2aa996" }}
        >
          <FontAwesomeIcon className="h-5 w-5" icon={faSearch} />
          <span className="pl-3">
            {search!.from_port} to {search!.to_port}
          </span>
        </div>
        <div
          className="text-sm p-2"
          style={{
            color: "grey",
          }}
        >
          <b>Cargo Type: </b> {search!.cargo_type}
        </div>

        <div
          className="text-sm p-2"
          style={{
            color: "grey",
          }}
        >
          <b>Validity: </b>
          {validity.format("DD-MMMM-YYYY")}
        </div>

        <div
          className="text-sm p-2"
          style={{
            color: "grey",
          }}
        >
          <b>Searched On: </b>
          {generatedDate.format("DD-MMMM-YYYY")}
        </div>

        <div className="font-bold text-sm p-2 w-18 content-center border-zinc-400">
          <ButtonSecondary
            className="float-right mb-3"
            onClick={() => {
              history.push({
                pathname: "/ocean/rates-listing",
                search: `?from_port=${search?.from_port}&to_port=${search?.to_port}&cargo_type=${search?.cargo_type}&Validity=${search?.validity}`,
              });
            }}
          >
            Search Again
          </ButtonSecondary>
        </div>
      </div>
    </>
  );
};

export default SearchCard;

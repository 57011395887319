
/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Feb 20 2023
 * Updated at 7:14:53 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Mar 13 2023
 * Updated at 5:57:29 PM
 *
 */

import React, { useState, useEffect } from "react";
import Remarks from "new_component/Quotation/Remarks";
import { useQuoteList } from "utils/contexts/quoteListContext";
import RenderTable from "./RenderTable";
import { useEventContext } from "utils/contexts/eventContext";
import apiPaths from "utils/paths/apiPaths";
import { securePostRequest } from "utils/helpers/apiVerbs";
import { useTransition, animated } from "@react-spring/web";
import { useUserDetails } from "utils/contexts/userDetailsContext";

const QuoteModal = ({ data, onclose, showModal }: any) => {
  const [totalBuyRate, setTotalBuyRate] = useState(data.totalPrice);
  const [totalSellRate, setTotalSellRate] = useState(data.totalPrice);
  const [tab, setTab] = useState<"Quotation" | "Remarks" | "Recipient">(
    "Quotation"
  );
  const [editID, setEditID] = useState(-1);

  // render table component

  const { quoteList, addLegs, addSumsell } = useQuoteList();
  const { setIsLoading,setError } = useEventContext();

const {  userState  } = useUserDetails();

  const sum = (arr: number[], initialvalue: number) => {
    if (arr.length > 0) {
      return arr.reduce((pv: number, cv: number) => {
        return pv + cv;
      }, initialvalue);
    } else {
      return initialvalue;
    }
  };

  useEffect(() => {
    if (quoteList.length > 0) {
      const index = quoteList.findIndex((item: any) => {
        return item?.id === data.id;
      });
      setEditID(index);
    }
    let totalSellArray = 0;
    let totalBuyArray = 0;
    if (editID !== -1) {
      if (quoteList[editID].customLegs) {
        const arraySellList = quoteList[editID].customLegs.map((item: any) => {
          return sum(
            item.customCharge.map((val: any) => val.netSellRate),
            0
          );
        });
        totalSellArray = sum(arraySellList, 0);

        const arrayBuyList = quoteList[editID].customLegs.map((item: any) => {
          return sum(
            item.customCharge.map((val: any) => val.netBuyRate),
            0
          );
        });
        totalBuyArray = sum(arrayBuyList, 0);
      }

      setTotalSellRate(
        sum(
          quoteList[editID].freightCharges.map(
            (item: any) => item.netSellRate || item.totalAmountInUSD
          ),
          sum(
            quoteList[editID].originCharges.map(
              (item: any) => item.netSellRate || item.totalAmountInUSD
            ),
            sum(
              quoteList[editID].destinationCharges.map(
                (item: any) => item.netSellRate || item.totalAmountInUSD
              ),
              totalSellArray
            )
          )
        )
      );

      setTotalBuyRate(
        sum(
          quoteList[editID].freightCharges.map(
            (item: any) => item.netBuyRate || item.totalAmountInUSD
          ),
          sum(
            quoteList[editID].originCharges.map(
              (item: any) => item.netBuyRate || item.totalAmountInUSD
            ),
            sum(
              quoteList[editID].destinationCharges.map(
                (item: any) => item.netBuyRate || item.totalAmountInUSD
              ),
              totalBuyArray
            )
          )
        )
      );
    }
  }, [quoteList]);

  useEffect(() => {
    addSumsell(data.id, totalSellRate);
  }, [totalSellRate]);

  const renderLocalQuotes = () => {
    return (
      <div>
        {
          <RenderTable
            chargeName="Freight"
            charges={quoteList[editID].freightCharges}
            legId="0"
            quoteId={data.id}
          />
        }
        {quoteList[editID].originCharges.length > 0 && (
          <RenderTable
            chargeName="Origin"
            charges={quoteList[editID].originCharges}
            legId="1"
            quoteId={data.id}
          />
        )}
        {quoteList[editID].destinationCharges.length > 0 && (
          <RenderTable
            chargeName="Destination"
            charges={quoteList[editID].destinationCharges}
            legId="2"
            quoteId={data.id}
          />
        )}
        {quoteList[editID]?.customLegs?.map((item: any, index: number) => {
          return (
            <RenderTable
              chargeName={item.legName}
              charges={item.customCharge}
              legId={item.id}
              quoteId={data.id}
            />
          );
        })}
        <div className="py-4 pb-8 px-4" onClick={() => addLegs(data.id)}>
          <button className=" border-2 px-4 py-2 rounded-2xl cursor-pointer border-indigo-500">
            {" "}
            +Add Legs
          </button>
        </div>
      </div>
    );
  };

  const renderTop = () => {
    return (
      <div className="flex relative h-[75%] flex-col align-center bg-white    dark:bg-neutral-700">
        <div className="flex justify-center  my-4 px-3 w-full">
          <button
            onClick={() => setTab("Quotation")}
            className={`p-1 w-[200px] rounded-l-full border border-r-1 ${
              tab === "Quotation" ? "text-white bg-indigo-500" : ""
            }`}
          >
            Quotation Details
          </button>

          <button
            onClick={() => setTab("Remarks")}
            className={`p-1 w-[200px] rounded-r-full border border-r-1 ${
              tab === "Remarks" ? "text-white bg-indigo-500" : ""
            }`}
          >
            Remarks
          </button>
        </div>
        <div
          className={`top-0 left-0 w-full  transform  transition-all delay-100 transform-[transition-all] ease-in-out duration-400 ${
            tab === "Remarks" ? "visible" : "hidden"
          }`}
          data-taos-offset="400"
        >
          <Remarks data={data} quote={quoteList[editID]} />
        </div>
        <div
          className={`top-0 left-0 w-full  transform transition-all ease-in-out duration-400 ${
            tab === "Quotation" ? "visible" : "hidden"
          }`}
        >
          {quoteList[editID] && renderLocalQuotes()}
        </div>
        <div
          className={`top-0 left-0 w-full  transform transition-all ease-in-out duration-400 ${
            tab === "Recipient" ? "visible" : "hidden"
          }`}
        >
          {/* <RecipientForm quote={quoteList[editID]} /> */}
        </div>
      </div>
    );
  };

  const renderBottom = () => {
    // Create PDF
    const generateQuote = async () => {
      setIsLoading(true);

      try {
        const generatePdf = await securePostRequest(
          apiPaths.rates.pdf,
         { ...quoteList[editID],company_logo: userState?.customer.company_logo }
        );
        setIsLoading(false);
        window.open(`${generatePdf.data}`);
      } catch (err: any) {
        setIsLoading(false);
        setError({ ...err, show: true });
      }
    };

    const handleSend = () => {};

    return (
      <div className=" h-auto w-full flex border-t z-9999 inset-x-0 bottom-0 p-4 justify-between bg-white overscroll-none dark:bg-neutral-800">
        <div className="flex flex-col pl-2 ">
          <span>BUY RATE</span>
          <span>{totalBuyRate.toFixed(2)}</span>
        </div>
        <div className="flex flex-col border-l-2 pl-5">
          <span>SELL RATE</span>
          <span>{totalSellRate.toFixed(2)}</span>
        </div>
        <div className="border-l-2 pl-5">
          You Earn USD{" "}
          <span className=" font-semibold">
            {(totalSellRate - totalBuyRate).toFixed(2)}{" "}
          </span>
          <span className="text-[green] font-semibold">
            {" "}
            {(((totalSellRate - totalBuyRate) * 100) / totalBuyRate).toFixed(
              2
            )}{" "}
            %{" "}
          </span>{" "}
          on this rate
        </div>
        <div className="flex gap-3">
          <button
            onClick={generateQuote}
            className="order-last bg-neutral-500 p-2 px-6 border border-1 rounded-2xl  text-white"
          >
            Genrate Pdf
          </button>
          {/* <button
            onClick={handleSend}
            className="order-last bg-blue p-2 px-6 border border-1 rounded-2xl bg-indigo-500 text-white"
          >
            Send
          </button> */}
        </div>
      </div>
    );
  };

  return (
    <div className=" relative z-max  inset-0 bg-neutral-200 bg-opacity-10  backdrop-blur-sm  dark:border-neutral-800 ">
      <div className="flex relative py-5 h-full flex-col text-xs sm:text-base  align-center bg-white border rounded-lg  dark:border-neutral-600 dark:bg-neutral-700">
        {renderTop()}
        {renderBottom()}
      </div>
    </div>
  );
};

export default QuoteModal;

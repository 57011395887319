import Loader from "new_component/Loader";
import React, { useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useEventContext } from "utils/contexts/eventContext";
import { secureUploadRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";

const EditKyc = ({ onEditChange }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [gstSelected, setGstSelected] = useState();
  const [panSelected, setPanSelected] = useState();

  const { userState, setUserState } = useUserDetails();
  const { setError } = useEventContext();

  const uploadCertificates = async () => {
    const formData = new FormData();
    if (gstSelected || panSelected) {
      gstSelected && formData.append("gst_certificate", gstSelected);
      panSelected && formData.append("pan_card", panSelected);

      try {
        const res = await secureUploadRequest(
          `${apiPaths.user.file.certificates + "/" + userState?.customer.ID}`,
          formData
        );
        return res.data.customer;
      } catch (error: any) {
        setIsLoading(false);
        onEditChange("companyDetails", false);
        setError({ ...error.response.data, show: true });
      }
    }
  };

  const updateHandler = async (e: any) => {
    e.preventDefault();

    // Call update user API
    setIsLoading(true);
    setIsLoading(true);

    let new_cerficates = await uploadCertificates();
    try {
      userState &&
        setUserState({
          ...userState,
          customer: {
            ...userState.customer,
            gst_certificate:
              new_cerficates.gst_certificate ||
              userState.customer.gst_certificate,
            pan_card: new_cerficates.pan_card || userState.customer.pan_card,
          },
        });
      setIsLoading(false);
      onEditChange("kycDetails", false);
    } catch (error: any) {
      setIsLoading(false);
      onEditChange("kycDetails", false);
      setError({ ...error.response.data, show: true });
    }
  };

  return (
    <form
      onSubmit={updateHandler}
      className=" grid h-full grid-cols-1 md:grid-cols-2 gap-6 my-2"
    >
      <label className="block">
        <span className="text-neutral-800 font-semibold dark:text-neutral-200">
          GST Certificate
        </span>
        <Input
          type="file"
          placeholder="Change your PAN details"
          className="mt-1"
          onChange={(e: any) => {
            setGstSelected(e.target.files[0]);
          }}
        />
      </label>
      <label className="block">
        <span className="text-neutral-800 font-semibold dark:text-neutral-200">
          PAN Card
        </span>
        <Input
          type="file"
          placeholder="Change your PAN details"
          className="mt-1"
          onChange={(e: any) => {
            setPanSelected(e.target.files[0]);
          }}
        />
      </label>
      <div></div>
      <div className="ml-auto mt-3  gap-4 flex w-full flex-col   md:flex-row">
        <ButtonPrimary type="submit" className=" w-full">
          {isLoading ? <Loader className="w-6 h-6" /> : "Update"}
        </ButtonPrimary>
        <ButtonPrimary
          type="button"
          onClick={() => onEditChange("kycDetails", false)}
          className="  w-full  bg-[#F7961D] hover:bg-[#F37911] text-[#000000] "
        >
          Cancel
        </ButtonPrimary>
      </div>
    </form>
  );
};

export default EditKyc;

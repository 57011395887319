import useWindowSize from "hooks/useWindowResize";
import React, { FC, useEffect } from "react";
import { useEventContext } from "utils/contexts/eventContext";
import DangerComponent from "./DangerComponent";
import SuccessComponent from "./SuccessComponent";
import WarningComponent from "./WarningComponent";

const SVG_ARRAY = [
  {
    type: "error",
    component: <DangerComponent />,
  },
  {
    type: "success",
    component: <SuccessComponent />,
  },
  {
    type: "warninig",
    component: <WarningComponent />,
  },
];

const ErrorComponent = () => {
  const { error, setError } = useEventContext();
  const window = useWindowSize();

  useEffect(() => {
    setTimeout(() => {
      setError({ message: "", status: "", show: false });
    }, 4000);
  }, [error]);

  return (
    <div
      id="toast-danger"
      className={`flex items-center delay-200 duration-200 w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow z-max ${
        window.width > 596
          ? "absolute bottom-12 right-5 "
          : "bottom-12 left-1/2 transform -translate-x-1/2  "
      }  dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800`}
      role="alert"
      style={{ position: "fixed" }}
    >
      <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
        {SVG_ARRAY.map((item: any) => {
          if (error.status)
            if (item.type === error.status.toLocaleLowerCase())
              return item.component;
        })}
      </div>
      <div className="ml-3 text-sm items-end font-normal">{error.message}</div>
      <button
        type="button"
        className="ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 absolute top-5 right-2 h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700"
        data-dismiss-target="#toast-danger"
        aria-label="Close"
        onClick={() => setError({ message: "", status: "", show: false })}
      >
        <span className="sr-only">Close</span>
        <svg
          aria-hidden="true"
          className="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div className="absolute bottom-0 left-[-1rem] w-full h-1 bg-gray-200 rounded-full">
        <div
          className="h-full bg-[#F7961D] rounded-full animate-timer"
          style={{ animationDuration: "4s" }}
        ></div>
      </div>
    </div>
  );
};

export default ErrorComponent;

import React, { useState, useEffect } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { IsignUpForm } from "containers/PageSignUp/PageSignUp";
import { useUserAuth } from "utils/contexts/userContext";
import { useQueryState } from "hooks/useQueryState";
import { postRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import Loader from "new_component/Loader";
import { useHistory, Link } from "react-router-dom";
import { useEventContext } from "utils/contexts/eventContext";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import Label from "components/Label/Label";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { useRedirectContext } from "utils/contexts/redirectContext";

const validationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company name is Required"),
  phone: Yup.string().required("Phone number is Required"),
  gst: Yup.string()
    .test(
      "len",
      "GST number should be of 15 digits",
      (val) => val?.length === 15
    )
    .required("GST details required"),
  pan: Yup.string()
    .test(
      "len",
      "Pan number should be of 10 digits",
      (val) => val?.length === 10
    )
    .required("PAN details required"),
  terms_condition: Yup.bool().oneOf(
    [true],
    "Please accept the Terms and conditions"
  ),
});

export interface IUpdateUserForm {
  companyName?: string;
  gst?: string | null;
  pan?: string | null;
  phone?: string;
  customer_type?: string;
  terms_condition?: boolean;
}

const UpdateUserForm = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useQueryState("email");
  const [name, setName] = useQueryState("name");
  const [event, setEvent] = useQueryState("event");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateUserForm>({
    resolver: yupResolver(validationSchema),
  });

  const { setError } = useEventContext();

  const { user } = useUserAuth();
  const { userState, setUserState } = useUserDetails();

  const { redirect, makeRedirect } = useRedirectContext();

  const history = useHistory();

  const submitHandler: SubmitHandler<IUpdateUserForm> = async (
    data: IUpdateUserForm
  ) => {
    setLoading(true);
    // Call update API
    console.log("DATA", data);

    try {
      const updateUser = await postRequest(apiPaths.customer.updateDetails, {
        ...data,
        email,
      });
      if (!updateUser.data.error) {
        if (userState) {
          setUserState({
            ...userState,
            KYC: updateUser.data.data.kyc ?? null,
            customer: {
              ...userState.customer,
              gst_number: updateUser.data.data.gst_number ?? null,
              pan_number: updateUser.data.data.pan_number ?? null,
              phone: updateUser.data.data.phone ?? null,
              companyName: updateUser.data.data.companyName ?? null,
              customer_type: updateUser.data.data.customer_type ?? null,
              kyc: updateUser.data.data.kyc ?? null,
            },
          });
        }
      }
      setLoading(false);
      redirect.isRedirect ? makeRedirect() : history.push("./login");
    } catch (err: any) {
      console.log(err);

      setError({ ...err.response.data, show: true });
      setLoading(false);
    }
    // sending to home page
  };

  const userText = () => {
    switch (event) {
      case "updateKyc":
        return "Please Update your KYC details to move forward!";

      default:
        return "please help us get to know you better by completing your profile";
    }
  };

  return (
    <div className="container mb-24 lg:mb-32">
      <h2 className="my-5 flex py-5 items-center text-2xl leading-[115%] md:text-xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        Hi {userState?.customer.contact_person}
        {", "}
        {userText()}
      </h2>
      <div className="max-w-xl mx-auto space-y-6 ">
        {/* FORM */}
        <form
          className="grid grid-cols-2 gap-6"
          onSubmit={handleSubmit(submitHandler)}
        >
          <Label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Phone number <span>*</span>
            </span>
            <Input
              id="phone"
              type="number"
              placeholder="Enter your Phone number"
              className="mt-1"
              {...register("phone")}
            />
            {errors.phone && (
              <p className="text-red-500 p-2">{errors.phone.message}</p>
            )}
          </Label>
          <Label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Business Type <span>*</span>
            </span>
            <select
              id="customer_type"
              className={`nc-Select block w-full h-11 mt-1.5 text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
              {...register("customer_type", { required: true })}
            >
              <option value="-1">Business Type</option>
              <option value="Shipper">Shipper</option>
              <option value="Freight Forwarder">Freight Forwarder</option>
              <option value="Custom Broker">Custom Broker</option>
            </select>
          </Label>

          <Label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              GST Number
            </span>
            <Input
              id="gst_number"
              type="text"
              placeholder="Enter your GST number"
              className="mt-1"
              {...register("gst")}
            />
            {errors.gst && (
              <p className="text-red-500 p-2">{errors.gst.message}</p>
            )}
          </Label>
          <Label className="block">
            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
              PAN Number
            </span>
            <Input
              id="pan_number"
              type="text"
              placeholder="Enter your PAN number"
              className="mt-1"
              {...register("pan")}
            />
            {errors.pan && (
              <p className="text-red-500 p-2">{errors.pan.message}</p>
            )}
          </Label>
          <Label className="block col-span-2">
            <span className="text-neutral-800 dark:text-neutral-200">
              Company Name <span>*</span>
            </span>
            <Input
              id="companyName"
              type="text"
              className="mt-1 "
              {...register("companyName")}
            />
            {errors.companyName && (
              <p className="text-red-500 p-2">{errors.companyName.message}</p>
            )}
          </Label>

          <Label className="block  text-sm sm:text-base col-span-2">
            <span className="flex space-x-3">
              <input
                className="mt-0.5 cursor-pointer focus:ring-action-primary h-4 w-4 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                type="checkbox"
                {...register("terms_condition")}
              />
              <p className="ml-3.5 ">
                I agree to the{" "}
                <Link to="/terms-condition" target="_blank"></Link>
                <span className="underline cursor-pointer">
                  Terms and condition
                </span>
              </p>
            </span>
            {errors.terms_condition && (
              <p className="text-red-500  p-2">
                {errors.terms_condition.message}
              </p>
            )}
          </Label>

          <ButtonPrimary type="submit" className={` col-span-2 `}>
            {loading ? <Loader className="h-6 w-6" /> : "Continue"}
          </ButtonPrimary>
        </form>
      </div>
    </div>
  );
};

export default UpdateUserForm;

import useWindowSize from "hooks/useWindowResize";
import React from "react";

import Lottie from "react-lottie";

import splash from "../../lotties/globeClick.json";

const SplashScreen = () => {
  const { width } = useWindowSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: splash,
    rendererSettings: {
      // preserveAspectRatio: "xMidYMid slice"
    },
  };

  return (
    <div className="splash-screen">
      <div className="loading-animation">
        <Lottie
          options={defaultOptions}
          height={width > 596 ? 600 : 300}
          width={width > 596 ? 600 : 300}
        />
      </div>
    </div>
  );
};

export default SplashScreen;

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:05:45 AM
 *
 */

import AirSearchForm from "new_component/AirSearchForm/AirSearchForm";
import TransportationSearchForm from "./TransportationSearchForm/TransportationSearchForm";
import RentWarehouseSearchForm from "new_component/RentWarehouseSearchForm/RentWarehouseSearchForm";
import React, { FC, useState, useRef, useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom"


import "react-dates/initialize";
import CargoSearchForm from "./CargoSearchForm";
import OceanSearchForm from "./OceanSearchForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchor,
  faPlaneDeparture,
  faTruck,
  faBoxes,
} from "@fortawesome/free-solid-svg-icons";

export type SearchTab =
  | "Cargo Tracker"
  | "Ocean"
  | "Transportation"
  | "Air"
  | "Rent Warehouse";

export interface newTab {
  name: SearchTab;
  icon: React.ReactNode;
  path: string;
}

export interface HeroSearchFormProps {
  className?: string;
  currentTab?: SearchTab;
  currentPage?: "Cargo Tracker" | "Ocean" | "Transportation" | "Air";
  autoFillForm?: {
    assign?: any;
    page?: string;
    feilds: {};
  };
}

const HeroSearchForm: FC<HeroSearchFormProps> = ({
  className = "",
  currentTab = "Ocean",
  currentPage = "Ocean",
  autoFillForm = {
    assign: "",
    page: "home",
    feilds: {},
  },
}) => {
  const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

  const history = useHistory();

  const myRef = useRef<null | HTMLDivElement>(null);

  const newTab: newTab[] = [
    {
      name: "Ocean",
      path: "/ocean/rates-listing",
      icon: <FontAwesomeIcon icon={faAnchor} />,
    },
    {
      name: "Transportation",
      path: "/transportation/rates-listing",

      icon: <FontAwesomeIcon icon={faTruck} />,
    },
    {
      name: "Air",
      path: "/air/rates-listing",

      icon: <FontAwesomeIcon icon={faPlaneDeparture} />,
    }
  ];

  const onclickHandler = () => {
    if (window.screen.width > 540) {
      myRef.current?.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const renderTab = () => {
    return (
      <ul className=" sm:ml-6 flex space-x-3 text-xs sm:space-x-8 lg:space-x-11 overflow-x-auto w-fit px-4 py-2 rounded-full  hiddenScrollbar dark:bg-neutral-800">
        {newTab.map((tab) => {
          const active = tab.name === tabActive;
          return (
            <li
              onClick={() => {
                if(autoFillForm.page !== "home")
                  history.push(tab.path)
                else 
                  setTabActive(tab.name)
              }}
              className={`flex-shrink-0 flex items-center  cursor-pointer lg:text-base font-medium  ${active
                ? ""
                : "text-neutral-400 hover:text-neutral-800 dark:hover:text-neutral-300"
                } `}
              key={tab.name}
            >
              {active && (
                <span className="block  bg-neutral-800 dark:bg-[#2AA996] mr-2" />
                // <span className="block w-2.5 h-2.5 rounded-full bg-neutral-800 dark:bg-[#2AA996] mr-2" />
              )}

              <span className=" ">{tab.icon}</span>
              <span className="px-1">{tab.name}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderForm = () => {
    const isArchivePage = !!currentPage && !!currentTab;
    switch (tabActive) {
      case "Cargo Tracker":
        return <CargoSearchForm haveDefaultValue={isArchivePage} />;

      case "Ocean":
        return (
          <OceanSearchForm
            autoFill={autoFillForm}
            haveDefaultValue={isArchivePage}
          />
        );
      case "Transportation":
        return <TransportationSearchForm autoFill={autoFillForm} haveDefaultValue={isArchivePage} />;
      case "Air":
        return <AirSearchForm />;

      default:
        return null;
    }
  };

  return (
    <div
      ref={myRef}
      onClick={onclickHandler}
      className={
        autoFillForm.page === "home"
          ? "lg:shadow-2xl lg:px-10 lg:pb-10 lg:backdrop-blur-[1px]  rounded-3xl  w-full"
          : ""
      }
    >
      <div
        className={`nc-HeroSearchForm w-full ${
          autoFillForm.page === "home" ? "max-w-6xl" : "max-w-8xl"
        } py lg:py-2 ${className}`}
        data-nc-id="HeroSearchForm"
      >
        {renderTab()}
        {renderForm()}
      </div>
    </div>
  );
};

export default HeroSearchForm;

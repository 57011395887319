import React, { useState } from "react";
import moment from "moment";

import { Link, useLocation, useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

const OceanRateSearchCard2 = ({ data }: any) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const history = useHistory();

  const bookNowHandler = () => {
    history.push({
      pathname: `/ocean/booking`,
      search: `?from=${data.from_port}&to=${data.to_port}&rate=${data.id}`,
      state: {
        data,
      },
    });
  };

  const renderHeader = () => {
    return (
      <div className="hidden col-span-2 lg:grid gap-2  lg:grid-cols-6 pb-4 border-b justify-items-center items-center">
        <div className="text-[blue] ">Service</div>
        <div className="self-end justify-self-end text-[blue] ">ETD</div>
        <div className="grid self-end relative px-2 w-full ">
          <span className="w-3 h-3 absolute left-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
          <span className="border-b border-[#01a77e]  top-[50%] w-full"></span>
          <span className="w-3 h-3 absolute right-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
        </div>
        <div className="self-end justify-self-start text-[blue]"> ETA</div>
        <div className="text-[blue]"> Vessel Name</div>
        <div className="font-semibold"> Total Price</div>
        <div className="text-center self-start">
          {" "}
          {data.service_mode.length > 0 ? data.service_mode : "N/A"}
        </div>
        <div className="self-start justify-self-end">
          {" "}
          {data.departures.length > 0
            ? moment(data.departures[0].departureDateEstimated).format("Do MMM")
            : "N/A"}
        </div>
        <div className="self-start text-center">
          <span>
            {data.transit_time.length > 0 ? `${data.transit_time} days` : "N/A"}
          </span>
        </div>
        <div className="self-start justify-self-start">
          {" "}
          {data.departures.length > 0
            ? moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")
            : "N/A"}
        </div>
        <div className="text-center">
          {" "}
          {data.vesselName.length > 0 ? data.vesselName : "N/A"}
        </div>
        <div className="">
          {" "}
          <span>USD </span>
          <span className="font-semibold">{data.totalPrice}</span>
        </div>
      </div>
    );
  };

  const renderMobileHeader = () => {
    return (
      <div className=" lg:hidden w-full gap-2 grid grid-cols-2 pb-4 border-b justify-items-center items-center">
        <p className="text-[blue] w-full justify-self-start"> Service:</p>
        <span>{data.service_mode.length > 0 ? data.service_mode : "N/A"}</span>
        <div className="self-center justify-self-start text-[blue] ">ETD</div>
        <div className="self-end justify-self-end text-[blue]"> ETA</div>
        <div className=" grid col-span-2 self-center relative px-2 w-full ">
          <span className="w-3 h-3 absolute left-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
          <span className="border-b border-[#01a77e]  top-[50%] w-full"></span>
          <span className="w-3 h-3 absolute right-0 -top-[0.3rem] border-bg-[#01a77e] rounded-full bg-[#01a77e] "></span>
        </div>
        <div className="self-start justify-self-start">
          {" "}
          {data.departures.length > 0
            ? moment(data.departures[0].departureDateEstimated).format("Do MMM")
            : "N/A"}
        </div>
        <div className="self-end justify-self-end">
          {" "}
          {data.departures.length > 0
            ? moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")
            : "N/A"}
        </div>
        <div className="justify-self-start">Transit time:</div>
        <div className="self-start justify-self-start">
          <span>
            {data.transit_time.length > 0 ? data.transit_time : "N/A"}
          </span>
        </div>
        <div className="text-[blue] justify-self-start"> Vessel Name</div>

        <div className="justify-self-start">
          {" "}
          {data.vesselName.length > 0 ? data.vesselName : "-"}
        </div>
        <div className="font-semibold justify-self-start"> Total Price:</div>
        <div className="justify-self-start">
          {" "}
          <span>USD </span>
          <span className="font-semibold justify-self-start">
            {data.totalPrice}
          </span>
        </div>
      </div>
    );
  };


  const renderSchedule = () => {
    return (
      <div
        className={`flex flex-col  h-min gap-10 border-l border-neutral-400 ${
          data.vgmCutoff.length > 0 ? "w-1/2" : "hidden"
        }`}
      >
        <div className="flex">
          <img
            className="w-10 absolute left-4 lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-doc-cut-off.83dfba82.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">DOC cut off</span>
            {moment(data.docCutoff).format("Do MMM")}{" "}
          </p>
        </div>
        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-vgm-cut-off.920fce76.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">VGM cut off</span>

            {moment(data.vgmCutoff).format("Do MMM")}
          </p>
        </div>
        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-cy-cut-off.75beb148.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">Port CY cut off</span>
            {moment(data.cyCutoff).format("Do MMM")}
          </p>
        </div>

        {data.departures.map((item: any, index: any) => {
          return (
            <div className="flex ">
              <img
                className="w-10 absolute left-4  lg:left-10"
                src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-boat.92edbb69.svg"
              />
              <p className="flex flex-col mx-8 py-2">
                {index === 0 && (
                  <span className="font-semibold">Departure</span>
                )}
                {item.departureTerminal.split("(")[0]} / {item.departureLoc}
                <span>
                  ETD:{" "}
                  {data.departures.length > 0 &&
                    moment(data.departures[0].departureDateEstimated).format(
                      "Do MMM"
                    )}
                </span>
              </p>
            </div>
          );
        })}

        <div className="flex">
          <img
            className="w-10 absolute left-4  lg:left-10"
            src="https://ecomm.one-line.com/one-ecom/_next/static/media/ico-location-nofill.c2b55ecd.svg"
          />
          <p className="flex flex-col mx-8 py-2">
            <span className="font-semibold">Arrival</span>
            <span>
              {" "}
              {data.departures.length > 0
                ? data.departures[
                    data.departures.length - 1
                  ].arrivalTerminal.split("(")[0] +
                  "/" +
                  data.departures[data.departures.length - 1].arrivalLoc
                : "N/A"}
            </span>
            <span className="font-semibold text-sm">
              ETA:{" "}
              {moment(
                data.departures[data.departures.length - 1].arrivalDateEstimated
              ).format("Do MMM")}
            </span>
            {/* <span>
              {data.departures[data.departures.length - 1].transitTimeInHrs +
                "hrs"}
            </span> */}
          </p>
        </div>
      </div>
    );
  };

  const renderDetailTop = () => {
    return (
      <div
        className={`flex flex-col space-y-3 z-15 ${
          data.vgmCutoff.length > 0 ? "" : "col-span-2"
        }`}
      >
        <div className="font-semibold text-lg uppercase">Freight Charges</div>
        {data.freightCharges.map((item: any) => {
          return (
            <div className="flex w-full justify-between px-2 py-2 my-1 ">
              <div className="capitalize">{item.chargeName.toLowerCase()}</div>
              <div className="font-semibold">USD {item.totalAmountInUSD}</div>
            </div>
          );
        })}
        {data.originCharges.length > 0 && (
          <>
            <div className="border-t border-neutral-300 pt-4  font-semibold text-lg uppercase">
              Origin Charges
            </div>
            <div className=" pb-4">
              {data.originCharges.map((item: any) => {
                return (
                  <div className="flex justify-between border-neutral-300  px-2 py-2  my-1 ">
                    <div className="capitalize">
                      {item.chargeName.toLowerCase()}
                    </div>
                    <div className="font-semibold">
                      USD {item.totalAmountInUSD}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {data.destinationCharges.length > 0 && (
          <>
            <div className="border-t pt-4 border-neutral-300 font-semibold text-lg uppercase">
              Destination Charges
            </div>
            <div className=" pb-4">
              {data.destinationCharges.map((item: any) => {
                return (
                  <div className="flex justify-between border-neutral-300  px-2 py-2  my-1 ">
                    <div className="capitalize">
                      {item.chargeName.toLowerCase()}
                    </div>
                    <div className="font-semibold">
                      USD {item.totalAmountInUSD}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderDetails = () => {
    if (!isOpen) return null;
    return (
      <div
        className={`p-4 md:p-8 gap-4 border border-neutral-200 dark:border-neutral-700 rounded-2xl ${
          data.vgmCutoff.length > 0
            ? "lg:grid lg:grid-cols-2 flex flex-col "
            : "flex flex-col"
        } `}
      >
        {renderHeader()}
        {/* render mobile header */}
        {renderMobileHeader()}
        {data.vgmCutoff.length > 0 && renderSchedule()}
        {renderDetailTop()}
        <div />
        <div className="flex w-full justify-self-end ml-auto">
          <div className="ml-auto flex gap-2">
            <ButtonPrimary
              onClick={bookNowHandler}
              className=" justify-self-center self-center  hidden lg:block "
            >
              Book Now
            </ButtonPrimary>
            {/* </ButtonPrimary> */}
          </div>
        </div>
      </div>
    );
  };


  return (
    //rendering main card
    <div
      className="p-4 sm:p-4 bg-white dark:bg-neutral-900 border border-neutral-100
    dark:border-neutral-800 rounded-2xl  hover:shadow-lg transition-shadow space-y-6 mb-3"
    >
      <div className="flex flex-row space-x-10">
        <div className="flex flex-col w-40">
          {/* rendering the id and logo using left to right method to render things*/}
          <div className="rounded-tl-2xl rounded-br-2xl text-white -top-4 bg-[#2aa996] px-4  text-xs w-20">
            {data.id}
          </div>
          <div className="w-[100%] mt-6 ">
            <img src={data.sl_logo} />
          </div>
        </div>
        <div className="mr-2">
          <div className="flex font-semibold text-xl">{data.from_port}</div>
          <span className="w-12 flex justify-center">
            <i className=" text-3xl las la-long-arrow-alt-down"></i>
          </span>
          <div className="flex font-semibold text-xl">{data.to_port}</div>
          <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.sl_name}
          </div>
        </div>
        <div className="hidden lg:block  whitespace-nowrap ml-4 mr-4">
          <div className="font-medium text-lg"> Validity</div>
          <div className="text-sm text-neutral-500 font-normal mt-0.5">
            {moment(data.expiry_date).format("Do MMM YY")}
          </div>
        </div>
        <div className="hidden lg:block  whitespace-nowrap ml-3">
          <div className="font-medium text-lg">Cargo Type</div>
          <div className="text-sm text-neutral-500 font-normal mt-0.5">
            FCL - {"20' Standard"}
          </div>
        </div>
        <div className="flex flex-col ">
          <div className="flex space-x-3 md:space-x-0">
            <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
              <span className="text-xl font-semibold text-secondary-6000">
                <div className="flex flex-col space-x-2 align-self">
                  <span className="text-sm text-normal font-normal text-black">
                    Freight Cost
                  </span>
                  <span>USD {data.base_rate}</span>
                </div>
              </span>
            </div>

            <div className="flex flex-row whitespace-nowrap sm:text-right flex-[3]">
              <div>
                <div className="flex flex-col space-x-2  align-self text-xl font-semibold text-secondary-6000">
                  <span className="text-sm text-normal font-normal text-black">
                    Total Cost
                  </span>
                  <span>USD {data.totalPrice}</span>
                </div>

                <div className="flex-col whitespace-nowrap sm:text-right flex-[3]">
                  <div className="mt-5 ">
                    <ButtonPrimary
                      onClick={bookNowHandler}
                      className=" justify-self-center self-center block "
                    >
                      Book Now
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
              <div className="justify-self-center self-center block ml-5 pt-7">
                <span
                  className={`sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ease-in-ease-out duration-200 ${
                    isOpen ? "rotate-180" : ""
                  }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="flex h-3 justify-self-center self-center block">
            <span className="text-[8px] text-neutral-400 ">
              *Above rates are indicative and subject to availaibilty
            </span>
          </div>
        </div>
      </div>
      {renderDetails()}
    </div>
  );
};
export default OceanRateSearchCard2;

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import NcImage from "shared/NcImage/NcImage";

const KycDetailsCard = ({ customer, onEditChange }: any) => {
  return (
    <>
      <div className="relative  mt-5 mr-5">
        <button
          className="absolute top-0 right-0 hover:scale-110 translate-x-2 transform ease-in-out duration-200 "
          onClick={() => onEditChange("kycDetails", true)}
        >
          Edit <FontAwesomeIcon icon={faEdit} />
        </button>
        <div className="grid h-full grid-cols-1 md:grid-cols-2 gap-6 my-2">
          <label className="block">
            <span className=" text-neutral-800 font-semibold dark:text-neutral-200">
              GST Number
            </span>
            <p>{customer?.gst_number ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              PAN Number
            </span>
            <p>{customer?.pan_number ?? "N/A"}</p>
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Gst Certificate
            </span>
            <NcImage
              containerClassName=""
              className="w-1/2 aspect-[12/4]"
              src={customer.gst_certificate || ""}
            />
          </label>
          <label className="block">
            <span className="text-neutral-800 font-semibold dark:text-neutral-200">
              Pan Card
            </span>
            <NcImage
              containerClassName=""
              className="w-1/2 aspect-[12/4]"
              src={customer.pan_card || ""}
            />
          </label>
        </div>
      </div>
    </>
  );
};

export default KycDetailsCard;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Thu Mar 02 2023
 * Created at 12:12:30 PM
 * File Content:
 * *================*
 * *================*
 * Filter SKeleton COmponent
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Thu Mar 02 2023
 * Updated at 6:00:57 PM
 *
 */



import { StarIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";

export interface FilterSkeletonProps {
    className?: string;

}

const FilterSkeleton: FC<FilterSkeletonProps> = ({
    className = "",
}) => {
    return (
        <div role="status" className="animate-pulse">

            <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0 sm:space-x-10">

                <div className="hidden lg:block ">
                   <div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                </div>
                <div className="hidden lg:block ">
                    <div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                </div>
                <div className="flex-col">
                    <div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    
                </div>

            </div>

        </div>

    );
};

export default FilterSkeleton;

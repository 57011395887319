/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Tue Mar 14 2023
 * Updated at 3:14:48 PM
 *
 */

import { Page } from "./types";
import PageHome from "containers/PageHome/PageHome";

import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";

import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPass from "containers/AccountPage/AccountPass";
import AccountSavelists from "containers/AccountPage/AccountSavelists";
import AccountBilling from "containers/AccountPage/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import LoadCalculator from "new_component/LoadCalculator";
import DetailedRequest from "new_component/DetailedRequest";
import MyRequest from "new_component/MyRequest";
import BookingDetails from "new_component/Bookings-timeline/BookingDetails";
import QuotesPage from "new_component/Dashboard/QuotesPage";
import WarehousingPage from "new_component/WarehousingPage/WarehousingPage";
import PageTradeFinance from "new_component/Trade-Finance/PageTradeFinance";
import PageFreightFinance from "new_component/Freight-Finance/PageFreightFinance";
import OceanSearchPage from "containers/OceanSearchPage";
import OceanBookingsPage from "containers/OceanSearchPage/OceanBookingPage";
import Home from "containers/Home";
import PageUpdateUser from "new_component/PageUpdateUser/PageUpdateUser";
import CargoInsurancePage from "containers/CargoInsurancePage/CargoInsurancePage";
import TransportationSearchPage from "containers/TransportationSearchPage";
import TransportationBookingsPage from "containers/TransportationSearchPage/TransportationBookingsPage";
import CustomClearancePage from "containers/CustomClearanceSearchPage/CustomClearancePage";
import CustomClearanceSearchPage from "containers/CustomClearanceSearchPage";
import CustomClearanceBookingPage from "containers/CustomClearanceSearchPage/CustomClearanceBookingPage";
import TermsAndConditionPage from "containers/TermsAndConditionsPage";
import ForgotPassword from "containers/ForgotPassword";
import ChangePassword from "containers/ChangePassword";
import CommodityInfoPage from "containers/OceanSearchPage/CommodityInfoPage";
import SearchBarWidget from "containers/Widget/SearchBarWidget";
import PrivacyPolicyPage from "containers/PrivacyPolicyPage/PrivacyPolicyPage";
import Thankyou from "containers/ForgotPassword/thankyou";

const publicPageList: Page[] = [
  { path: "/", exact: true, component: Home },
  { path: "/#", exact: true, component: Home },

  // Listing pages
  { path: "/ocean/rates-listing", exact: true, component: OceanSearchPage },
  {
    path: "/transportation/rates-listing",
    exact: true,
    component: TransportationSearchPage,
  },
  {
    path: "/custom-clearance/rates-listing",
    exact: true,
    component: CustomClearanceSearchPage,
  },
  {
    path: "/listing-experiences",
    exact: true,
    component: ListingExperiencesMapPage,
  },
  { path: "/cargo-insurance", exact: true, component: CargoInsurancePage },
  //
  {
    path: "/listing-experiences",
    component: ListingExperiencesPage,
  },
  {
    path: "/listing-experiences-map",
    component: ListingExperiencesMapPage,
  },
  {
    path: "/listing-experiences-detail",
    component: ListingExperiencesDetailPage,
  },

  //
  { path: "/listing-real-estate-map", component: ListingRealEstateMapPage },
  { path: "/listing-real-estate", component: ListingRealEstatePage },
  //
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  //
  { path: "/author", component: AuthorPage },
  { path: "/account", component: AccountPage },
  { path: "/account-password", component: AccountPass },
  { path: "/account-savelists", component: AccountSavelists },
  { path: "/account-billing", component: AccountBilling },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  //
  { path: "/add-listing-1", component: PageAddListing1 },
  { path: "/add-listing-2", component: PageAddListing2 },
  { path: "/add-listing-3", component: PageAddListing3 },
  { path: "/add-listing-4", component: PageAddListing4 },
  { path: "/add-listing-5", component: PageAddListing5 },
  { path: "/add-listing-6", component: PageAddListing6 },
  { path: "/add-listing-7", component: PageAddListing7 },
  { path: "/add-listing-8", component: PageAddListing8 },
  { path: "/add-listing-9", component: PageAddListing9 },
  { path: "/add-listing-10", component: PageAddListing10 },
  //
  { path: "/contact", component: PageContact },
  { path: "/terms-condition", component: TermsAndConditionPage },
  { path: "/privacy-policy", component: PrivacyPolicyPage },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/ocean/bookings/thank-you", component: OceanBookingsPage }, // newly added
  { path: "/ocean/booking", component: CommodityInfoPage },
  {
    path: "/transportation/bookings/thank-you",
    component: TransportationBookingsPage,
  }, // newly added
  { path: "/custom-clearance", exact: true, component: CustomClearancePage }, // newly added
  {
    path: "/custom-clearance/bookings/thank-you",
    component: CustomClearanceBookingPage,
  }, // newly added
  { path: "/warehousing", component: WarehousingPage }, // CommodifyInfo
  { path: "/user/quotes", component: QuotesPage }, // newly added
  { path: "/loadcalculator", component: LoadCalculator }, // newly added
  { path: "/quotes", component: DetailedRequest }, // newly added
  { path: "/user/my-request", component: MyRequest }, // newly added
  { path: "/transpost-widget", component: SearchBarWidget }, // newly added
  //
  { path: "/booking-details", component: BookingDetails }, // newly added

  { path: "/trade-finance", component: PageTradeFinance }, // newly added
  { path: "/freight-finance", component: PageFreightFinance }, // newly added
  { path: "/update", component: PageUpdateUser },
  { path: "/forgot-pass", component: ForgotPassword },
  { path: "/change-password", component: ChangePassword },
  { path: "/forgot-password-thankyou", component: Thankyou },
];

export default publicPageList;

import useWindowSize from "hooks/useWindowResize";
import React from "react";
import { Helmet } from "react-helmet";

import CommonLayout from "containers/AccountPage/CommonLayout";
import ProfileCard from "./ProfileCard";
import ProfileDetailsCard from "./ProfileDetailsCard";

const ProfilePage = () => {
  const { width } = useWindowSize();
  return (
    <>
      <Helmet>
        <title>Profile Page | Transpost</title>
      </Helmet>
      <CommonLayout>
        <main className="mt-1 mb-24 lg:mb-24 flex flex-col lg:flex-row">
          <div className="block flex-grow  lg:mb-24">
            <div className="lg:sticky lg:top-24 pb-24">
              <ProfileCard />
            </div>
          </div>
          <div className="w-auto lg:w-3/4 space-y-5 pb-24 lg:pl-5 flex-shrink-0">
            <ProfileDetailsCard />
          </div>
        </main>
      </CommonLayout>
    </>
  );
};

export default ProfilePage;

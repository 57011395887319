import React, { useState, FC } from "react";
import NcSelect from "shared/NcSelect/NcSelect";
import { CONTAINER_TYPES, COMMODITY_LIST, STUFFING_TYPE } from "./data";
import * as Yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import websitePaths from "utils/paths/websitePaths";
import { useUserAuth } from "utils/contexts/userContext";
import { useRedirectContext } from "utils/contexts/redirectContext";

export interface ICustClearanceForm {
  containerInputValue?: string;
  stuffingTypeInputValue?: string;
  commodityInputValue?: string;
  transportType?: "Export" | "Import";
}

export interface CustomClearanceSearchFormProps {
  autoFill?: any;
}

const validationSchema = Yup.object().shape({
  containerInputValue: Yup.string().required("Container Type is required"),
  stuffingTypeInputValue: Yup.string().required("Stuffing Type is required"),

  commodityInputValue: Yup.string().required("Commodity Type is required"),
});

const CustomClearanceSearchForm: FC<CustomClearanceSearchFormProps> = ({
  autoFill,
}) => {
  const [containerInputValue, setContainerInputValue] = useState<string>(
    autoFill.page === "home" ? "" : autoFill.feilds.container_type
  );
  const [stuffingTypeInputValue, setStuffingTypeInputValue] = useState<string>(
    autoFill.page === "home" ? "" : autoFill.feilds.stuffing_type
  );
  const [commodityInputValue, setCommodityInputValue] = useState<string>(
    autoFill.page === "home" ? "" : autoFill.feilds.commodity
  );
  const [transportType, setTransportType] = useState<"Export" | "Import">(
    autoFill.page === "home" ? "Export" : autoFill.feilds.transport_type
  );
  const [fieldFocused, setFieldFocused] = useState<
    "containerTypeInput" | "commodityInput" | null
  >(null);

  const containerTypeHandler = (e: string) => {
    setContainerInputValue(e);
  };

  const stuffingTypeHandler = (e: string) => {
    setStuffingTypeInputValue(e);
  };

  const commodityInputHandler = (e: string) => {
    setCommodityInputValue(e);
  };

  const history = useHistory();
  const location = useLocation();
  const { isLogin } = useUserAuth();
  const { setRedirect } = useRedirectContext();

  const submitHandler = (e: any) => {
    e.preventDefault();

    const mainPath = websitePaths.customClearance.ratesListing;

    const searchTerm =
      "?transport_type=" +
      transportType +
      "&container_type=" +
      containerInputValue +
      "&stuffing_type=" +
      stuffingTypeInputValue +
      "&commodity=" +
      commodityInputValue;

    if (!isLogin.login) {
      setRedirect({
        isRedirect: true,
        redirectUrl: mainPath,
        queryParams: searchTerm,
      });
      history.push("/login");
    } else {
      history.push({
        pathname: mainPath,
        search: searchTerm,
      });
    }
  };

  const renderRadioBtn = () => {
    return (
      <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            transportType === "Export"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setTransportType("Export")}
        >
          Export
        </div>
        <div
          className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
            transportType === "Import"
              ? "bg-black text-white shadow-black/10 shadow-lg"
              : "border border-neutral-300 dark:border-neutral-700"
          }`}
          onClick={(e) => setTransportType("Import")}
        >
          Import
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <form className="w-full relative mt-2 rounded-3xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800">
        {renderRadioBtn()}
        <div className=" flex flex-col lg:flex-row w-full rounded-full [ nc-divide-field ] ">
          <div className="relative flex flex-col lg:flex-row flex-grow  ">
            <NcSelect
              defaultValue={containerInputValue}
              onChange={(e) => {
                containerTypeHandler(e);
              }}
              onInputDone={() => setFieldFocused("containerTypeInput")}
              placeHolder="Container type"
              desc=""
              searchList={CONTAINER_TYPES}
            />
            <NcSelect
              defaultValue={stuffingTypeInputValue}
              onChange={(e) => {
                stuffingTypeHandler(e);
              }}
              searchList={STUFFING_TYPE}
              onInputDone={() => setFieldFocused("commodityInput")}
              placeHolder="Stuffing Type"
              desc=""
              autoFocus={fieldFocused === "containerTypeInput"}
              // img={containerStuffing}
            />
            <NcSelect
              defaultValue={commodityInputValue}
              onChange={(e) => {
                commodityInputHandler(e);
              }}
              searchList={COMMODITY_LIST}
              placeHolder="Commodity"
              desc=""
              autoFocus={fieldFocused === "commodityInput"}
            />

            {/* BUTTON SUBMIT OF FORM */}
            <div className="px-4 py-3 flex items-center justify-center">
              {/* <ButtonSubmit  /> */}
              <button
                onClick={submitHandler}
                type="button"
                className="h-14  lg:h-16 w-full lg:w-16 rounded-full bg-[#cd512f] hover:bg-[#218778] flex items-center justify-center text-neutral-50 focus:outline-none"
              >
                <span className="mr-3 lg:hidden">Search</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </form>{" "}
    </div>
  );
};

export default CustomClearanceSearchForm;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Mon Feb 20 2023
 * Updated on Mon Feb 20 2023
 * File Content:
 * *================*
 * *================*
 * Commodity Info Form after book now
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Mon Mar 06 2023
 * Updated at 1:03:55 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Feb 20 2023
 * Updated at 2:39:48 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:44:45 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Mon Feb 27 2023
 * Updated at 2:25:27 PM
 *
 */

import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import Input from "shared/Input/Input";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventContext } from "utils/contexts/eventContext";
import apiPaths from "utils/paths/apiPaths";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loader from "new_component/Loader";
import NcModal from "shared/NcModal/NcModal";
import NcImage from "shared/NcImage/NcImage";
import { getRequest, securePostRequest } from "utils/helpers/apiVerbs";
import websitePaths from "utils/paths/websitePaths";
import { useQueryState } from "hooks/useQueryState";
import { Helmet } from "react-helmet";

export interface IcommodityDetails {
  commodityName?: string;
  containerCount?: string;
  weight?: number | string;
  loadingDate?: string;
  desc?: string;
  isFirstMile?: boolean;
  isLastMile?: boolean;
  incoterm_id?: string;
}

export interface IbookingData {
  data: any;
  cargo: string;
}

const validationSchema = Yup.object().shape({
  commodityName: Yup.string().required("Commodity Name is required"),
  containerCount: Yup.string().required("Number of Containers is required"),
  weight: Yup.string().required("Weight is required"),
  loadingDate: Yup.string().required("Loading date is required"),
});

const CommodityInfoPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IcommodityDetails>({
    resolver: yupResolver(validationSchema),
  });
  const location = useLocation();
  const { state } = location;
  const [from, setFrom] = useQueryState("from");
  const [to, setTo] = useQueryState("to");
  const [bookingData, setBookingData] = useState<IbookingData | any>(state);
  const [commodityInfo, setCommodityInfo] = useState({
    commodityName: "",
    containerCount: "",
    weight: "",
    loadingDate: "",
    desc: "",
    isFirstMile: "",
    isLastMile: "",
    incoterm_id: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const [incotermList, setIncotermList] = useState([
    {
      id: "",
      code: "",
      name: "Select Incoterms",
    },
    {
      id: "others",
      code: "",
      name: "Others",
    },
  ]);
  const { setError } = useEventContext();
  const { userState } = useUserDetails();

  const history = useHistory();

  const onChangeHandler = (e: any) => {
    setCommodityInfo({ ...commodityInfo, [e.target.name]: e.target.value });
  };

  const submitHandler: SubmitHandler<IcommodityDetails> = async (
    commodityInfo: IcommodityDetails
  ) => {
    const stringCommodityDetails = JSON.stringify(commodityInfo);

    if (bookingData && bookingData?.data) {
      const postData = {
        customer_id: userState?.customer?.ID,
        rateDetails: JSON.stringify(bookingData.data),
        commodityDetails: stringCommodityDetails,
      };
      setIsLoading(true);

      try {
        const bookCommodity: any = await securePostRequest(
          apiPaths.bookings.store,
          postData
        );
        setIsLoading(false);
        const fetchedData = bookCommodity.data;
        history.push({
          pathname: "/ocean/bookings/thank-you",
          state: {
            bId: fetchedData?.Booking.ID,
            bookedData: bookingData.data,
            cargoType: bookingData.cargo,
            commodityInfo,
            quotation_data: fetchedData?.Booking?.booking_quotation,
          },
        });
      } catch (error: any) {
        setIsLoading(false);
        setError({ ...error.response.data, show: true });
      }
    }
  };

  const fetchAllIncoterms = async () => {
    // Call incoterms api
    try {
      const list = await getRequest(apiPaths.bookings.incoterm);
      setIncotermList([...incotermList, ...list.data.incoterm]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    !state && setBookingData(state);
    fetchAllIncoterms();
  }, []);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <div className="flex-shrink-0 w-full sm:w-40">
            <div className=" font-semibold ">
              <div>
                <span className="flex items-center text-2xl font-bold text-black mt-0.5">
                  {from}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl text-black las la-long-arrow-alt-down"></i>
              </span>
              <span className="flex items-center text-2xl text-black font-bold mt-0.5">
                {to}
              </span>
            </div>
          </div>
          <div className="py-5 sm:px-5">
            {/* <div className=" aspect-w-2 aspect-h-4 sm:aspect-h-4 rounded-2xl overflow-hidden"> */}
            <NcImage
              src={bookingData.data.sl_logo}
              className=" w-70 h-70 ml-10"
            />
            {/* </div> */}

            {/* <div>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">LOL</span>
              <span className="text-base font-medium mt-1 block">LOL</span>
            </div>
            <span className="block  text-sm text-neutral-500 dark:text-neutral-400">LOL</span>
            <div className="w-10 border-b border-neutral-200  dark:border-neutral-700">LOL</div> */}
            {/* <StartRating /> */}
          </div>
        </div>
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          {commodityInfo.commodityName === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Commodity Name</span>
              <span>{commodityInfo.commodityName}</span>
            </div>
          )}
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Freight Charge per Container</span>
            <span>${bookingData.data.base_rate}</span>
          </div>
          {commodityInfo.containerCount === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Container Count</span>
              <span>{commodityInfo.containerCount} Containers</span>
            </div>
          )}
          {commodityInfo.weight === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Container Weight</span>
              <span>{commodityInfo.weight} MT</span>
            </div>
          )}
          {commodityInfo.loadingDate === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Loading Date</span>
              <span>{commodityInfo.loadingDate}</span>
            </div>
          )}

          {commodityInfo.incoterm_id === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Incoterms</span>
              <span>{commodityInfo.incoterm_id}</span>
            </div>
          )}

          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          {commodityInfo.containerCount === "" ? null : (
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Price Details</span>
              <span>
                {bookingData.data.base_rate} x {commodityInfo.containerCount}
              </span>
            </div>
          )}
          <div className="flex justify-between font-semibold">
            <span>Total Price</span>
            <span>
              {" "}
              {commodityInfo?.containerCount === ""
                ? "$0"
                : "$" +
                  bookingData.data.base_rate *
                    parseInt(commodityInfo?.containerCount)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h3 className=" text-2xl font-semibold">Your Booking</h3>
        <div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

          <h1 className="mt-3 font-bold text-lg py-3 ">
            Please fill up the following details before proceeding further
          </h1>
          <NcModal
            modalTitle="Your Booking Details"
            renderTrigger={(openModal) => (
              <span
                onClick={() => openModal()}
                className="block lg:hidden underline  mt-1 cursor-pointer"
              >
                View booking details
              </span>
            )}
            renderContent={renderSidebar}
          />
        </div>
        <form className=" mt-6 w-full" onSubmit={handleSubmit(submitHandler)}>
          <div className="flex-col space-y-6  md:grid md:grid-cols-2 md:space-y-0 md:gap-8 my-2">
            <label className="block ">
              <span className="text-neutral-800 dark:text-neutral-200">
                Commodity Name*
              </span>
              <Input
                type="text"
                placeholder="Enter Comodity Name"
                className="mt-1 mb-1"
                {...register("commodityName", {
                  onChange: (e) => onChangeHandler(e),
                })}
              />
              {errors.commodityName && (
                <p className="text-red-600 pt-1 px-2 text-xs">
                  {errors.commodityName.message}
                </p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Number of Containers *
              </span>
              <Input
                type="number"
                placeholder="Enter Number of Containers"
                className="mt-1 mb-2"
                {...register("containerCount", {
                  onChange: (e) => onChangeHandler(e),
                })}
              />
              {errors.containerCount && (
                <p className="text-red-600 pt-1 px-2 text-xs">
                  {errors.containerCount.message}
                </p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Weight per container (in MT) *
              </span>
              <Input
                type="float"
                placeholder="Enter the weight per container in MT"
                className="mt-1 mb-2"
                {...register("weight", {
                  onChange: (e) => onChangeHandler(e),
                })}
              />
              {errors.weight ? (
                <p className="text-red-600 pt-1 px-2 text-xs">{errors.weight.message}</p>
              ) : (
                <p className="px-2 text-[10px] text-red-600">
                  *OWS will be applicable depending on the carrier
                </p>
              )}
            </label>
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Ready to Load *
              </span>
              <Input
                type="date"
                placeholder=""
                className="mt-1 mb-2"
                {...register("loadingDate", {
                  onChange: (e) => {
                    onChangeHandler(e);
                  },
                })}
              />
              {errors.loadingDate && (
                <p className="text-red-600 pt-1 px-2 text-xs">{errors.loadingDate.message}</p>
              )}
            </label>
            <label className="block" htmlFor="customer_type">
              <span className="text-neutral-800 dark:text-neutral-200">
                Incoterms <span>*</span>
              </span>

              <select
                id="customer_type"
                className={`nc-Select block w-full h-11 mt-1.5 text-sm rounded-2xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900`}
                {...register("incoterm_id", {
                  required: true,
                  onChange: (e: any) =>
                    onChangeHandler({
                      ...e,
                      target: {
                        ...e.target,
                        value: incotermList.find((incoTerm: any) => {
                          if (
                            parseInt(e.target.value) === parseInt(incoTerm.id)
                          )
                            return incoTerm;
                        })?.name,
                        name: "incoterm_id",
                      },
                    }),
                })}
              >
                {incotermList.map((item) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </select>
            </label>
            <label className="block col-span-2 ">
              <span className="text-neutral-800 dark:text-neutral-200">
                Description (Optional).
              </span>
              <textarea
                placeholder="IMO cargo, Temperature control, OG, Overweight, Flexitank, Cargo readiness, CBM, Humidity, etc."
                className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-[1rem] "
                {...register("desc")}
              />
            </label>
            <label className="block">
              <div className="flex gap-4">
                <input
                  id="first_mile"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  {...register("isFirstMile")}
                />
                <label
                  className="text-neutral-900 dark:text-neutral-100"
                  htmlFor="first_mile"
                >
                  First mile assitance
                </label>
              </div>
            </label>
            <label className="block">
              <div className="flex gap-4">
                <input
                  id="last_mile"
                  type="checkbox"
                  className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500 bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                  {...register("isLastMile")}
                />
                <label
                  className="text-neutral-900 dark:text-neutral-100"
                  htmlFor="last_mile"
                >
                  Last mile assitance
                </label>
              </div>
            </label>

            <ButtonPrimary
              className="h-11 w-full rounded-[2.5rem]  md:w-[9rem]  bg-[#2AA996] my-6 hover:bg-[#218778]  text-neutral-50 focus:outline-none "
              type="submit"
            >
              {isLoading ? <Loader className="h-6 w-6" /> : "Proceed"}
            </ButtonPrimary>
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage `} data-nc-id="CheckOutPage">
      <Helmet>
        <title>Ocean Rates || Commodity Info</title>
      </Helmet>
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CommodityInfoPage;

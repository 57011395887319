import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { UserDetailsProps } from "../../components/SectionHeroArchivePage/SectionHeroArchivePage";
import { useLocalStorage } from "hooks/useLocalStorage";

type UserDetailsProviderProps = {
  children: ReactNode;
};

export interface IkycStatus {
  KYC: boolean;
  message: string;
  status: string;
  token?: string;
}

export interface AuthDetails {
  token?: string,
  key?: string
}

type quoteListContext = {
  userState: UserDetailsProps | null;
  setUserState: React.Dispatch<React.SetStateAction<UserDetailsProps | null>>;
  createUser: (userData: UserDetailsProps) => void;
  updateKyc: (kycStatus: IkycStatus) => void;
  authState: AuthDetails | null;
  setAuthState: React.Dispatch<React.SetStateAction<AuthDetails | null>>;
  checkKyc: () => boolean;
};

// // getLocalStorage
export const getLocalStorage = () => {
  let user_details = localStorage.getItem("user_details");
  if (user_details) {
    return (user_details = JSON.parse(
      localStorage.getItem("user_details") || ""
    ));
  } else {
    return null;
  }
};

export const getLocalAuthDetails = () => {
  let auth_details = localStorage.getItem("auth_details");
  if (auth_details)
    return (auth_details = JSON.parse(localStorage.getItem('auth_details') || "{}"));

  return null;
}



const userDetailsContext = createContext({} as quoteListContext);

export const useUserDetails = () => {
  return useContext(userDetailsContext);
};

export const UserDetailsProvider = ({ children }: UserDetailsProviderProps) => {
  const [userState, setUserState] = useState<UserDetailsProps | null>(
    getLocalStorage()
  );

  const [kyc, setKyc] = useState({
    isDone: false,
  })

  const [userDetails, setUserDetails] =
    useLocalStorage<UserDetailsProps | null>("user_details", null);

  const [authState, setAuthState] =
    useState<AuthDetails | null>(getLocalAuthDetails());


  const [authDetails, setAuthDetails] = useLocalStorage<AuthDetails | null>("auth_details", null);

  useEffect(() => {
    setUserDetails(userState);
    setKyc({isDone: userState?.customer.kyc === "0" ? false : true})
  }, [userState]);

  useEffect(() => {
    setAuthDetails(authState)
  }, [authState])

  // adding new user
  const createUser = (userData: UserDetailsProps) => {
    setUserState(userData);

    setUserDetails(userData);
  };

  const updateKyc = (kycStatus: IkycStatus) => {
    if (userState) {
      setUserState({
        ...userState,
        KYC: kycStatus.KYC,
      });
    }
  };

  const checkKyc = () => {
    if(userState) {
      if(userState.KYC) return true

      return false
    }

    return false
  }

  return (
    <userDetailsContext.Provider
      value={{
        userState,
        setUserState,
        createUser,
        updateKyc,
        setAuthState,
        authState,
        checkKyc
      }}
    >
      {children}
    </userDetailsContext.Provider>
  );
};

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Sheikh
 * Created on Tue May 19 2022
 * Created at 2:35:55 PM
 * File Content:
 * *================*
 * *================*
 * Sign In Page
 * *================*
 * *================*
 */

/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 28 2023
 * Updated at 11:04:52 AM
 *
 */


/*
 *
 * Updated By: Deepraj Chouhan
 * Updated on Tue Feb 21 2023
 * Updated at 2:36:38 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Thu Feb 23 2023
 * Updated at 7:45:19 PM
 *
 */

/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Feb 24 2023
 * Updated at 10:20:35 AM
 *
 */


import React, { FC, useEffect, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "shared/Input/Input";
import Loader from "new_component/Loader";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useHistory, useLocation } from "react-router-dom";
import { useUserDetails } from "utils/contexts/userDetailsContext";


import { useUserAuth } from "utils/contexts/userContext";
import * as Yup from "yup";
import { useEventContext } from "utils/contexts/eventContext";
import { useQueryState } from "hooks/useQueryState";
import { useRedirectContext } from "utils/contexts/redirectContext";
import { postRequest } from "utils/helpers/apiVerbs";
import apiPaths from "utils/paths/apiPaths";
import KeyIcon from "../../images/icons/key.svg"


const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
});

export interface ForgotPasswordProps {
    className?: string;
}

export interface ICheckEmailForm {
    email: string;
}

const loginSocials = [
    {
        name: "Continue with Google",
        href: "#",
        icon: googleSvg,
    },
];

const ForgotPassword: FC<ForgotPasswordProps> = ({ className = "" }) => {
    const [isLoading, setisLoading] = useState(false);
    const history = useHistory();

    const { redirect, makeRedirect, setRedirect } = useRedirectContext()

    const [autoLogin, setAutoLogin] = useQueryState("autoLogin")
    const [showErr, setShowErr] = useState({
        show: false,
        msg: ""
    })
    const { serverLogin, googleLogin } = useUserAuth();
    const { userState } = useUserDetails();
    const { setError } = useEventContext();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ICheckEmailForm>({
        resolver: yupResolver(validationSchema),
    });


    const submitHandler: SubmitHandler<ICheckEmailForm> = async (data: ICheckEmailForm) => {
        setisLoading(true)
        try {
            const checkAccount: any = await postRequest(apiPaths.auth.forgotPassword, { email: data.email })

            setisLoading(false);

            if (!checkAccount.error!) history.push("./forgot-password-thankyou?email=" + data.email);

            else throw checkAccount

        } catch (error: any) {
            setisLoading(false);
            if (error.response.data.message === 'Google Login') {
                setShowErr({
                    show: true,
                    msg: "Your Account is associated with Google please try loging in using Google."
                });
            }
            const errorMsg = error.response.data.message;
            setError({ status: "error", show: true, message: errorMsg });
        }
    };

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Forgot Password || Transpost</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-5 flex pb-2 items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Forgot Password
                </h2>
                
                <div className="max-w-lg mx-auto space-y-6">
                    <img src={KeyIcon} className=" mx-auto w-[25%]" alt="" />
                    {/* FORM */}
                    <form
                        onSubmit={handleSubmit(submitHandler)}
                        className="grid grid-cols-1 gap-6"
                    >
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">
                            Enter your email address to receive the password reset link:
                            </span>

                            <Input
                                required
                                type="text"
                                placeholder="example@example.com"
                                className="mt-1"
                                {...register("email")}
                            />
                            {errors.email && (
                                <p className="text-red-600 p-2">{errors.email.message}</p>
                            )}
                        </label>

                        <ButtonPrimary type="submit">
                            {isLoading ? <Loader className="h-6 w-8" /> : "Check Account"}
                        </ButtonPrimary>
                        {showErr.show && <p className="text-red-600 p-2">{showErr.msg}</p>}
                    </form>


                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;

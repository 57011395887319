import useUserTracking from "hooks/useUserTracking";
import React, { useEffect } from "react";
import { useUserDetails } from "utils/contexts/userDetailsContext";

const UserTracking = () => {
  const { userState } = useUserDetails();

  useUserTracking(userState?.customer.email ?? null);
  return <></>;
};

export default UserTracking;

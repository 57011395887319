/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Deepraj Chouhan
 * Created on Thu Mar 02 2023
 * Created at 12:10:25 PM
 * File Content:
 * *================*
 * *================*
 * Skeleton Component for Search result
 * *================*
 * *================*
 */


import { StarIcon } from "@heroicons/react/20/solid";
import React, { FC } from "react";

export interface SearchResultSkeletonProps {
    className?: string;

}

const SearchResultSkeleton: FC<SearchResultSkeletonProps> = ({
    className = "",
}) => {
    return (
        <div role="status" className="animate-pulse">
            <span
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 sm:py-5 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer overflow-y-auto"
            >
                <span className="block text-neutral-400">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 sm:h-6 sm:w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                    </svg>
                </span>
                <span className="block font-medium text-neutral-700 dark:text-neutral-200">

                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <span className="text-[red]"><div className="h-3.5 bg-gray-300 rounded-full dark:bg-gray-600 w-36 mb-2.5"></div></span>
                </span>
            </span>
        </div>

    );
};

export default SearchResultSkeleton;

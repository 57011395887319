import React, { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeftAlt } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { getRedableDate } from "utils/helpers/dates";
import ButtonSecondary from "shared/Button/ButtonSecondary";

interface QuoteCardProps {
  quote?: {
    buy_rate?: string;
    cargo_size?: string;
    customer_id?: number;
    freight_cost?: string;
    from_port?: string;
    id?: number;
    pdf_dump: string;
    pdf_name?: string;
    sell_rate?: string;
    sl_name?: string;
    to_port?: string;
    total_cost?: string;
    validity?: string;
    created_at?: string;
  };
}

const QuoteCard: FC<QuoteCardProps> = ({ quote }) => {
  const generatedDate = moment(String(quote?.created_at));
  
  return (
    <>
      <div className=" bg-[#fff] w-full h-full border p-3 rounded-lg drop-shadow-md hover:drop-shadow-xl cursor-pointer dark:border-neutral-500 dark:bg-neutral-700 ">
        <div
          className="flex font-bold text-sm p-2 content-center border-zinc-400 m-0"
          style={{ color: "#2aa996" }}
        >
          <FontAwesomeIcon className="h-5 w-5" icon={faQuoteLeftAlt} />
          <span className="pl-3">
            {quote!.from_port} to {quote!.to_port}
          </span>
        </div>
        <div
          className="text-sm p-2"
          style={{
            color: "grey",
          }}
        >
          <b>Generated On: </b>
          {generatedDate.date()}
          {"-"}
          {generatedDate.subtract(1, "month").startOf("month").format("MMMM")}
          {"-"}
          {generatedDate.year()}
        </div>
        <div
          className="text-sm p-2 pt-0"
          style={{
            color: "grey",
          }}
        >
          <b>Shipping Line: </b> {quote?.sl_name}
        </div>
        <div
          className="text-sm p-2 pt-0 flex"
          style={{
            color: "grey",
          }}
        >
          <span>
            <b>Buy Rate: </b> {quote?.buy_rate}
          </span>
          <span className="pl-2">
            <b>Sell Rate: </b> {quote?.sell_rate}
          </span>
        </div>
        <div className="font-bold text-sm p-2 w-18 content-center border-zinc-400">
          <a href={quote?.pdf_name} download target={"_blank"} rel="noreferrer">
            <ButtonSecondary className="float-right mb-3">
              Download
            </ButtonSecondary>
          </a>
        </div>
      </div>
    </>
  );
};

export default QuoteCard;

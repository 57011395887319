import React, { FC, useEffect, useState } from "react";
import CommonLayout from "containers/AccountPage/CommonLayout";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import emptyicon from "../../images/transpost images/dashboard/emptyPng.png";

import BookingCard from "./components/BookingCard";
import Loader from "../Loader";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import { secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { secureGetRequest, securePostRequest } from "utils/helpers/apiVerbs";
import Sidebar from "./components/Sidebar";
import BookingList from "./components/BookingList";
import { useEventContext } from "utils/contexts/eventContext";
import useWindowSize from "hooks/useWindowResize";
import BookingAnalytics from "./Analytics/BookingAnalytics";
import { Helmet } from "react-helmet";

export interface BookingCardProps {
  data: {
    ID?: string;
    DateOfBooking?: string;
    ContainerType?: string;
    ShippingLineName?: string;
    name?: string;
    ContainerCount?: string;
    BookingNo?: string;
    POL?: string;
    POD?: string;
    pol: {
      country: string;
      port_code: string;
      port_name: string;
    };
    pod: {
      country: string;
      port_code: string;
      port_name: string;
    };
    cs_status: {
      name: string;
      template: string;
    };
  };
}

const UserDashboard = () => {
  const [recentBookingList, setRecentBookingList] = useState<[]>([]);
  const [pendingList, setPendingList] = useState([]);
  const [completedList, setCompletedList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [analytics, setAnalytics] = useState({});

  const { user, isLogin } = useUserAuth();
  const { userState } = useUserDetails();
  const { setError } = useEventContext();

  const { width } = useWindowSize();

  const fetchRecentBookings = async () => {
    setLoading(true);
    try {
      const recentBookings = await secureGetRequest(apiPaths.bookings.recent, {
        email: userState?.customer.email,
      });
      const result = recentBookings.data;

      setRecentBookingList(result.data);

      setLoading(false);
    } catch (err: any) {
      setError({
        ...err,
        error: true,
        message: "Error While Fetching Recent Bookings!",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isLogin.login) {
      fetchRecentBookings();
    }
  }, [isLogin.login]);

  return (
    <CommonLayout>
      <Helmet>
        <title>Dashboard | Transpost</title>
      </Helmet>
      <main className="mt-1 mb-24 lg:mb-20 flex flex-col lg:flex-row">
        {width > 996 && (
          <div className="block flex-grow mb-24 lg:mb-0">
            <div className="lg:sticky lg:top-24">
              <Sidebar />
            </div>
          </div>
        )}
        <div className="w-auto md:w-[80%] space-y-5 lg:pl-5 flex-shrink-0">
          <BookingAnalytics />
          <BookingList
            bookingList={recentBookingList}
            all={true}
            loading={loading}
          />
        </div>
      </main>
    </CommonLayout>
  );
};

export default UserDashboard;

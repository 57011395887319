import { useEffect } from "react";

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import { TaxonomyType } from "data/types";
import React, { FC } from "react";
import SectionGridFilterCard from "containers/ListingFlightsPage/SectionGridFilterCard";
import { Helmet } from "react-helmet";
import VideoContainer from "new_component/VideoContainer/VideoContainer";
import SectionSolutions from "new_component/SectionSolutions/SectionSolutions";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";

export interface HomeProps {
  className?: string;
}

const Home: FC<HomeProps> = ({
  className = "",
}) => {

  const { logOut, isLogin } = useUserAuth()
  const { userState } = useUserDetails();

  useEffect(() => {
    if (isLogin.login) {
      if (userState === null) logOut()
    }
  }, []);

  return (
    <div
      className={`nc-home relative overflow-hidden ${className}`}
      data-nc-id="home"
    >
      <Helmet>
        <title>Transpost | One Globe. One Click.</title>
      </Helmet>
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Ocean"
          currentTab="Ocean"
          listingType={
            <>{/* <span className="ml-2.5">1599 flights</span> */}</>
          }
          className="pt-10 pb-24 lg:pb-32 lg:pt-32 "
        />

        {/* SECTION */}
        <SectionGridFilterCard
          className="pb-24 lg:pb-32"
        />
        {/* Video Section */}
        {/* <VideoContainer /> */}

        {/* Soltuions Sections */}

        <SectionSolutions />


      </div>
    </div>
  );
};

export default Home;

/*
 *
 * Proprietory Licensed Under
 * Transpost Technologies Pvt Ltd.
 *
 *  Transpost Portal
 * Author: Rashid Shaikh
 * Created on Tue Feb 28 2023
 * Updated on Tue Feb 28 2023
 * File Content:
 * *================*
 * *================*
 * Address Form after transportation book now
 * *================*
 * *================*
 */


/*
 *
 * Updated By: Rashid Shaikh
 * Updated on Fri Mar 03 2023
 * Updated at 7:49:05 PM
 *
 */


import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Input from "shared/Input/Input";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEventContext } from "utils/contexts/eventContext";
import { secureAPI } from "utils/helpers/apiGenerator";
import apiPaths from "utils/paths/apiPaths";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Loader from "new_component/Loader";

export interface IAddressInfo {
  commodity?:string;
  container_count?:string;
  locality?: string;
  pin_code?: string;
  city?: string;
  loading_date?: string;
  desc?: string;
}

const validationSchema = Yup.object().shape({
  commodity: Yup.string().required("Commodity Name is required"),
  container_count: Yup.string().required("Number of Containers is required"),
  locality: Yup.string().required("Locality is required"),
  pin_code: Yup.string().required("Pin code is required"),
  city: Yup.string().required("City is Required"),
  loading_date: Yup.string().required("Loading date is required"),
});

const CommodityAddressPage = ({ data, cargo }: any) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddressInfo>({
    resolver: yupResolver(validationSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const { setError } = useEventContext();
  const { userState } = useUserDetails();

  const history = useHistory();

  const submitHandler: SubmitHandler<IAddressInfo> = (
    AddressInfo: IAddressInfo
  ) => {
    const stringAddressInfo = JSON.stringify(AddressInfo);

    const {
      id,
      transportation_type,
      loading_port,
      factory_city,
      type,
      cargo_weight,
      rates,
      commodity,
      container_count
    } = data;

    const postData = {
      id,
      customer_id: userState?.customer?.ID,
      full_address: stringAddressInfo,
    };


    setIsLoading(true);
    secureAPI
      .post(apiPaths.bookings.transportation.store, postData)
      .then((response) => {
        setIsLoading(false);
        const fetchedData = response.data;
        console.log("fetchedData", fetchedData);
        history.push({
          pathname: "/transportation/bookings/thank-you",
          state: {
            bId: fetchedData?.Booking.bookings_no,
            bookedData: data,
            AddressInfo
          },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        setError({ ...error.response.data, show: true });
      });
  };

  return (
    <div className=" flex container md:items-center relative h-full flex-col align-center  dark:border-neutral-600 dark:bg-neutral-700  transition-linear ease-in-out delay-150 ">
      <form className=" md:w-[80%]" onSubmit={handleSubmit(submitHandler)}>
        <div className="flex-col  md:grid grid-cols-2 gap-6 my-2">
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Commodity*
            </span>
            <Input
              type="text"
              placeholder="Enter the Commodity"
              className="mt-1 mb-1"
              {...register("commodity")}
            />
            {errors.commodity && (
              <p className="text-red-600 p-2">{errors.commodity.message}</p>
            )}
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Container Count*
            </span>
            <Input
              type="text"
              placeholder="Enter the number of containers"
              className="mt-1 mb-1"
              {...register("container_count")}
            />
            {errors.container_count && (
              <p className="text-red-600 p-2">{errors.container_count.message}</p>
            )}
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Locality*
            </span>
            <Input
              type="text"
              placeholder="Enter the Locality"
              className="mt-1 mb-1"
              {...register("locality")}
            />
            {errors.locality && (
              <p className="text-red-600 p-2">{errors.locality.message}</p>
            )}
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Pin Code *
            </span>
            <Input
              type="text"
              placeholder="Enter the pin code"
              className="mt-1 mb-2"
              {...register("pin_code")}
            />
            {errors.pin_code && (
              <p className="text-red-600 p-2">{errors.pin_code.message}</p>
            )}
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              City *
            </span>
            <Input
              type="text"
              placeholder="Enter the city name"
              className="mt-1 mb-2"
              {...register("city")}
            />
            {errors.city && (
              <p className="text-red-600 p-2">{errors.city.message}</p>
            )}
          </label>
          <label className="block">
            <span className="text-neutral-800 dark:text-neutral-200">
              Ready to Load *
            </span>
            <Input
              type="date"
              placeholder=""
              className="w-full mt-1 mb-2"
              {...register("loading_date")}
            />
            {errors.loading_date && (
              <p className="text-red-600 p-2">{errors.loading_date.message}</p>
            )}
          </label>
          <label className="block col-span-2 ">
            <span className="text-neutral-800 dark:text-neutral-200">
              Description (Optional).
            </span>
            <textarea
              placeholder="IMO cargo, Temperature control, OG, Overweight, Flexitank, Cargo readiness, CBM, Humidity, etc."
              className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 rounded-[1rem] "
              {...register("desc")}
            />
          </label>

          <ButtonPrimary
            className="h-11 w-full rounded-[2.5rem]  md:w-[9rem]  bg-[#2AA996] my-6 hover:bg-[#218778]  text-neutral-50 focus:outline-none "
            type="submit"
          >
            {isLoading ? <Loader className="h-6 w-6" /> : "Proceed"}
          </ButtonPrimary>
        </div>
      </form>
    </div>
  );
};

export default CommodityAddressPage;

import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import Page404 from "containers/Page404/Page404";
import SiteHeader from "containers/SiteHeader";
import PrivateRoute from "./PrivateRoute";
import { useEventContext } from "utils/contexts/eventContext";
import TranspostLoader from "new_component/TranspostLoader/TranspostLoader";
import ErrorComponent from "shared/Error/ErrorComponent";

import publicPageList from "./publicPageList";
import privatePageList from "./privatePagesList";
import useWindowSize from "hooks/useWindowResize";
import SplashScreen from "containers/SplashScreen";
import { useRedirectContext } from "utils/contexts/redirectContext";
import { useUserAuth } from "utils/contexts/userContext";
import { useUserDetails } from "utils/contexts/userDetailsContext";
import UserTracking from "new_component/UserTracking";
import { useQueryState } from "hooks/useQueryState";
import Coordinate from "components/Coordinates";

const MyRouter = () => {
  const { isLoading, error } = useEventContext();
  const windowSize = useWindowSize();

  const [splash, setSplash] = useState(false);
  const { widget } = useRedirectContext();
  const { setRedirect } = useRedirectContext();

  const [coordinates] = useQueryState("coords");

  const [websiteCoordinates , setWebsiteCoordinates] = useState([]);

  const { checkKyc, userState } = useUserDetails();

  const { isLogin } = useUserAuth();

  useEffect(() => {
    if (isLogin.login && !checkKyc()) {
      setRedirect({
        isRedirect: true,
        redirectUrl: "/update",
        queryParams: `?email=${userState?.customer.email}&name=${userState?.customer.name}&event=updateKyc`,
      });
    }
  }, [userState?.KYC]);

  useEffect(() => {
    console.log(coordinates)
    setWebsiteCoordinates(JSON.parse(coordinates ? coordinates as string : "{}"));
  } , [coordinates])

  return (
    <>
      <UserTracking />
      <ScrollToTop />
      {websiteCoordinates.length > 0 && websiteCoordinates.map((coordinate: any) => {
        return <>
          <Coordinate x={coordinate.x} y={coordinate.y}/>
        </>
      })}
      {!widget && <SiteHeader />}
      {isLoading && <TranspostLoader />}
      {error.show && <ErrorComponent />}

      {/* <SplashScreen /> */}

      <Switch>
        {/* Public Routes */}
        {publicPageList.map(({ component, path, exact, splash }, index) => {
          return splash ? (
            <SplashScreen />
          ) : (
            <Route
              key={index}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}

        {/* Private Routes */}
        {privatePageList.map(({ component, path, exact }, index) => {
          return (
            <PrivateRoute
              key={index}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      {windowSize.width > 596 && !widget ? <Footer /> : <></>}
    </>
  );
};

export default MyRouter;
